import { BrowserRouter, Routes, Route } from "react-router-dom";
import { allRoutes } from "./routes/routes";
import { hasCredential, redirectionUrl, removeLastUrl, setLastUrl, getUser } from "./auth/auth";
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import React from "react";

const PrivateRoute = ({ children, role, path, Component }) => {
  //const auth = useAuth();
  let user = getUser();
  let defaultUrl = "/login";
  const auth = hasCredential(role);
  const location = window.location.href;
  if (!auth) {
    setLastUrl(location);
  } else {
    removeLastUrl();
    defaultUrl = redirectionUrl(user.role);
  }
  return auth ? < Component /> : <Navigate to={{ pathname: defaultUrl }} />;
};


function App() {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          {
            allRoutes.map(({  path, exact, component, role, secured, namespace }, key) => {
              return secured ? (
                <Route
                  exact
                  path={path}
                  element={
                    <PrivateRoute role={role} path={path} Component = {component}>
                      
                    </PrivateRoute>
                  }
                  key={key}
                />
              ) : (
                <Route exact path={path} Component={component} key={key} />
              );
            })


          }
        </Routes>
      </BrowserRouter>
      {/* START FOOTER 3 w/ COMPANY DESCRIPTION WITH LINKS & SOCIAL ICONS & COPYRIGHT  */}
    </>
  );
}

export default App;
