import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light d-lg-none">
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="#">
        <img 
            src="/images-removebg-preview.png" 
            style={{minHeight: "100px", minWidth: "100px"}}
            className="navbar-brand-img h-100" 
            alt="main_logo" 
        />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className={({ isActive }) =>
                  isActive ? "nav-link active text-danger" : "nav-link"
                } to="/rooms">
                Chambre
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={({ isActive }) =>
                    isActive ? "nav-link active text-danger" : "nav-link"
                  }
                aria-current="page"
                to="/customer"
              >
                Clients
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={({ isActive }) =>
                    isActive ? "nav-link active text-danger" : "nav-link"
                  }
                aria-current="page"
                to="/workers"
              >
                Employés
              </NavLink>
            </li>
            
            <li className="nav-item">
              <NavLink className={({ isActive }) =>
                  isActive ? "nav-link active text-danger" : "nav-link"
                } to="/create-a-booking">
                Créer une reservation
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) =>
                  isActive ? "nav-link active text-danger" : "nav-link"
                } to="/bookings">
                Réservations
              </NavLink>
            </li>
          </ul>
        
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
