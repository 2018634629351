import Footer from "../components/Footer";
import Header from "../components/Header";
import { Formik } from 'formik';
import * as Yup from "yup";
import userService from "../services/userService";
import { signIn, getRedirectionUrl } from "../auth/auth";
import { useNavigate } from "react-router";
import { useState } from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function Login() {
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);
  const [fieldErrorMessage, setFieldError]= useState("")
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
};

  const handleSubmit = async (values) => {
    try {
      setLoading(true)
      const response = await userService.login(values)
      if (response.errorResponse) {
        //setFieldError(response.message)
        toast.error(response.message)
        setLoading(false)
      } 
      if (!response.errorResponse) {
        let user=signIn(response)
        window.location.href=getRedirectionUrl(user)  
        setLoading(false)
      }
    } catch (error) {
      setFieldError("Veuillez démarrer le l'autre serveur")
    }
   
  }
  return (
    <>
      {/* <Header /> */}
      <main class="main-content  mt-0">
        <section>
          <div class="page-header min-vh-75">
            <div class="container">
              
              <div class="row">
                
                <div class="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
                  {loading &&  (
                      <div class="d-flex justify-content-center align-item-center">
                        <div class="spinner-border" role="status">
                        <   span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                  )}
                  {fieldErrorMessage && (
                    <div class="alert alert-success alert-dismissible fade show" role="alert">
                      {/* <strong>Holy guacamole!</strong>  */}
                      {fieldErrorMessage}
                      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-bs-label="Close"></button>
                    </div>
                  )}
                  <div class="card card-plain mt-8">
                    <div class="card-header pb-0 text-left bg-transparent">
                      <h3 class="font-weight-bolder text-info text-gradient text-center">Application de l'Hôtel Mbanya</h3>
                      <p class="mb-0">Entrez votre email et Mot de passe pour vous Connecter</p>
                    </div>
                    <div class="card-body">
                    <Formik
                      initialValues={{ 
                        username: "",
                        password: ""
                      }}
                      onSubmit={handleSubmit}
                      validationSchema={Yup.object().shape({
                        username: Yup.string()
                          .required("Le champ nom d'utilisateur est obligatoire"),
                        password: Yup.string()
                          .required("Le mot de passe est obligatoire pour la connexion")
                      })}
                    >
                      {props => {
                        const {
                          values,
                          touched,
                          errors,
                          dirty,
                          isSubmitting,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          handleReset
                        } = props;
                        return (
                          <form  onSubmit={handleSubmit}>
                            <label>Nom d'utilisateur</label>
                            <div class="mb-3">
                              <input 
                                type="text"
                                id="username" 
                                name="username"
                                value={values.username}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                class="form-control" 
                                placeholder="Nom d'utilisateur" 
                                // aria-label="Email" 
                                // aria-describedby="email-addon"
                              />
                            </div>
                            {errors.username && touched.username && (
                              <div className="input-feedback">{errors.username}</div>
                            )}
                            <label>Mot de passe</label>
                            <div class="mb-3 position-relative">
                              <input 
                                type={showPassword ? 'text' : 'password'} 
                                id="password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                class="form-control" 
                                placeholder="Mot de passe" 
                                // aria-label="Password" 
                                // aria-describedby="password-addon"
                              />
                              <div className="position-absolute" style={{right: "3px", top: "calc(100% - 32px)"}}>
                                <FontAwesomeIcon size="lg" icon={showPassword ? faEyeSlash : faEye} onClick={togglePasswordVisibility} />
                              </div>
                            </div>
                            {errors.password && touched.password && (
                              <div className="input-feedback">{errors.password}</div>
                            )}
                            {/* <div class="form-check form-switch">
                              <input class="form-check-input" type="checkbox" id="rememberMe" checked=""/>
                              <label class="form-check-label" for="rememberMe">Remember me</label>
                            </div> */}
                            <div class="text-center">
                              <button 
                                type="submit" 
                                class="btn bg-gradient-info w-100 mt-4 mb-0"
                              >
                                Se connecter
                              </button>
                            </div>
                          </form>
                          );
                        }}
                      </Formik>
                    </div>
                    {/* <div class="card-footer text-center pt-0 px-lg-2 px-1">
                      <p class="mb-4 text-sm mx-auto">
                        Don't have an account?
                        <a href="/#" class="text-info text-gradient font-weight-bold">Sign up</a>
                      </p>
                    </div> */}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="oblique position-absolute top-0 h-100 d-md-block d-none me-n8">
                    <div class="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style={{backgroundImage:"url(" + '../assets/img/curved-images/curved6.jpg' +")"}}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
   {/* START FOOTER 3 w/ COMPANY DESCRIPTION WITH LINKS & SOCIAL ICONS & COPYRIGHT  */}
    </>
  );
}

export default Login;
