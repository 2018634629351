import {POST, sGET,  sPOST, sPUT,  sDELETE, sPATCH} from '../http/http';
import backendUrls from '../urls/url';

const UserService = {
  login: async function (data) {
    const url = backendUrls.Authentication.login;
    const result = await POST(url, data).then((r) =>
    r.json())
    console.log('my login ==> ',result)
    return result
    //return { status: success, message: message }
  },
  createclient: async function (data) {
    const url = backendUrls.USER.createClient;
    //console.log('url =>> ', url)
    const result = await sPOST(url, data)
    .then(r => r.json());
    return result;
  }, 
  deleteCustomer: async function(data) {
    const url = backendUrls.USER.deleteACustomer+data
    console.log(`url pour supprimer un client ${url}`)
    const result = await sDELETE(url)
    //.then(r => r.json())
    return result
  },
  getAllCustomers: async function(data) {
    console.log("Voir numberPerPage ==> ",data.numberPerPage)
    console.log("Voir search ==> ",data)
    const url = backendUrls.USER.getAllCustomers;
    const result = await sPOST(url, data).then((r) => 
    r.json())
    return result;
  },
  getCustomersCurrentPage: async function(data) {
    console.log(`verifier la page si elle est JSON ===>> ${data}`)
    const url = backendUrls.USER.getCustomersPagination;
    const result = await sPOST(url, data).then((r) => 
    r.json())
    return result;
  },
  createAccount: async function (data) {
    const url = backendUrls.USER.createUserWithRole;
    console.log('url =>> ', url)
    const result = await sPOST(url, data).then((r) =>
    r.json());
    return result
  },
  userInformationsDetail: async function(id) {
    const url = backendUrls.USER.userInformationsDetail+id;
    console.log(`url detail du user ===>> ${url}`)
    const result = await sGET(url).then((r) => 
    r.json())
    return result;
  },
  updateClientInformation: async function (id, data) {
    const url = backendUrls.USER.updateCustomerInformations+id;
    console.log(`url pour utilisateur à modifier == ${url}`)
    const result = await sPATCH(url, data)
    return result
  },
  workerOrAdminInformationDetail: async function(id) {
    const url = backendUrls.USER.workerOrAdminInformationDetail+id
    const result = await sGET(url).then((r) => 
    r.json())
    return result
  },
  updateWorkerOrAdminInformations: async function (id, data) {
    const url = backendUrls.USER.updateWorkerOrAdminInformations+id;
    const result = await sPATCH(url, data).then((r) => 
    r.json())
    return result

  }, 
  logout: function (cbs, cbe) {
    const url = backendUrls.Authentication.logout;
    //alert(getBearerAccessToken());
    sGET(url, cbs, cbe);
  },
  logoutAsync: async function () {
    const url = backendUrls.Authentication.logout;
    const { success, message } = await sGET(url, []).then((r) =>
      r.json()
    );
    return { success: success, message: message };
  },
  updatePassword: async function (data) {
    const url = backendUrls.USER.updatePassword;
    const result = await sPOST(url, data)
    .then((r) => 
      r.json()
    );
    return result;
  },

  getAllUsers: async function (data) {
    const url = backendUrls.USER.getAllUsers;
    const result = await sPOST(url, data).then((r) =>
    r.json());
    return result;
  },
  deleteAnUser: async function(id) {
    const url = backendUrls.USER.deleteAnUser+id;
    const result = await sDELETE(url)
    return result
  },

  getProfilUser: function (id, cb){
    const url=backendUrls.USER.getProfilUser+"/"+`${id}`;
    console.log('Voici l url '+url);
    sGET(url, cb);
},


updateProfilUser: function (data, cb) {
  const url=backendUrls.USER.updateProfilUser;
  sPUT(url, data, cb)
},



};
export default UserService;