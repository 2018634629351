const authenticationBaseUrl = process.env.REACT_APP_BACKEND;
const bookingBaseUrl = process.env.REACT_APP_BACKEND;
// const wordpressUrl = process.env.REACT_APP_WORDPRESS;
//const authenticationBaseUrl = 'http://dev.utrains.org:8080';
// const authenticationBaseUrlSecure = process.env.REACT_APP_AUTHENTICATION_SERVICE + '/api/secure/';
// const accountingBaseUrl = process.env.REACT_APP_ACCOUNTING_SERVICE + '/api';
// const accountingBaseRootUrl = process.env.REACT_APP_ACCOUNTING_SERVICE;
//export const accountingBaseUrlSecure = process.env.REACT_APP_ACCOUNTING_SERVICE + '/api/v1/secure/';
//export const MEDIA_URL = authenticationBaseUrl + "/uploads/";
//export const MEDIA_URL_ACCOUNTING = accountingBaseRootUrl + "/media";
//export const MEDIA_URL_ACCOUNTING_AUTH_SETTINGS = accountingBaseRootUrl + "/media/auth-page-settings/";


const backendUrls = {
    Authentication: {
        register: authenticationBaseUrl + "/api/user/create",
        login: authenticationBaseUrl + "/login",
        logout: authenticationBaseUrl + "user/logout",
        tryResetPassword: authenticationBaseUrl + "/api/account/password-reset",
        resetPassword: authenticationBaseUrl + "/api/account/password-reset/change",
        CONFIRM_ADMIN_IDENTITY: authenticationBaseUrl + "/api/check-otp",
    },
    USER: {
        createClient: authenticationBaseUrl + "/customer/create",
        getAllCustomers: authenticationBaseUrl + "/customer/list",
        getCustomersPagination: authenticationBaseUrl + "/customer/list",
        updateCustomerInformations: authenticationBaseUrl + "/customer/edit/",
        deleteACustomer: authenticationBaseUrl + "/customer/delete/",
        userInformationsDetail: authenticationBaseUrl + "/customer/detail/",

        createUserWithRole: authenticationBaseUrl + "/user/create",
        getAllUsers: authenticationBaseUrl + "/user/list",
        deleteAnUser: authenticationBaseUrl + "/user/delete/",
        workerOrAdminInformationDetail: authenticationBaseUrl + "/user/detail/",
        updateWorkerOrAdminInformations: authenticationBaseUrl + "/user/edit/",
        updatePassword: authenticationBaseUrl + "/user/update-password"

    },
    BOOKING: {
        createBooking: authenticationBaseUrl + "/roombooking/create",
        listRoomBooking: authenticationBaseUrl + "/roombooking/list",
        deleteABooking: authenticationBaseUrl + "/roombooking/delete/",
        bookingInformationDetail: authenticationBaseUrl + "/roombooking/detail/",
        updateBookingInformations: authenticationBaseUrl + "/roombooking/edit/",
        paymentInstallment: authenticationBaseUrl + "/roombooking/installment/add/",
        terminateABooking: authenticationBaseUrl + "/roombooking/terminate/",
    },
    Room: {
        createARoom: authenticationBaseUrl + "/room/create",
        getAllRooms: authenticationBaseUrl + "/room/list",
        getListAllRooms: authenticationBaseUrl + "/room/list-all",
        deleteRoom: authenticationBaseUrl + "/room/delete/",
        getRoomsPagination: authenticationBaseUrl + "/room/list",
        updateRoomInformations: authenticationBaseUrl + "/room/edit/",
        roomInformationsDetail: authenticationBaseUrl + "/room/detail/",

    },
    Statistics: {
        DailyPaymentSummary: authenticationBaseUrl + "/statistics/daily-summary",
        globalPaymentSummary: authenticationBaseUrl + "/statistics/global-summary"
    }
}
export default backendUrls;