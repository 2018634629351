import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, PDFDownloadLink, pdf} from '@react-pdf/renderer';
import moment from 'moment';
import 'moment/locale/fr';

//Styles for the PDF document
const styles = StyleSheet.create({
    
    logo: {
        width: 100,
        height: 90,
        alignSelf: 'center',
        marginBottom: 10,
    },
    info: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 2,

    },
    page: {
        flexDirection: 'column',
        padding: 30
    },
    rightAlign: {
        alignSelf: 'flex-end',
        marginBottom: 10,
        fontSize: 12,
    },
    field: {
        fontSize: 14,
        marginVertical: 5,
        marginTop: 5
    },
    titleFooter: {
        fontSize: 17,
        textAlign: 'center',
        marginBottom: 10
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
      },
      tableRow: {
        margin: 'auto',
        flexDirection: 'row'
      },
      tableColHeader: {
        width: '25%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: '#f0f0f0'
      },
      tableCol: {
        width: '25%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
      },
      tableCellHeader: {
        margin: 'auto',
        margin: 5,
        fontSize: 12,
        fontWeight: 500
      },
      tableCell: {
        margin: 'auto',
        margin: 5,
        fontSize: 10
      }
})

//moment.locale('fr');

// const formatDate = (dateString) => {
//     return moment(dateString, 'DD-MM-YYYY HH').format('D MMMM YYYY [à] HH[h]');
// };

const formatDate = (dateString) => {
    moment.locale('fr');
    return moment(dateString).format("D MMMM YYYY à HH[h]");
  };

  const formatDateWihoutHour = (dateString) => {
    moment.locale('fr');
    return moment(dateString).format("D MMMM YYYY");
  };

//console.log('Voir la date formaté ==>', formatDate("2024-07-28T17:00:00.000Z"))
export const DailyPayment = ({ dailyPayment }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            {/* <Text style={styles.title}>{process.env.REACT_APP_HOTEL_NAME}</Text> */}
            <View style={{marginBottom: "18px"}}>
                <Image style={styles.logo} src="/images.png"/>
                <Text style={styles.info}>Tel: (+237) {process.env.REACT_APP_PHONE}</Text>
                <Text style={styles.info}>Email: {process.env.REACT_APP_EMAIL}</Text>
                <Text style={styles.info}>{process.env.REACT_APP_CITY}</Text>
            </View>
            
            <View>
                <Text style={{textAlign: "center", marginRight: 6, fontSize: 17, marginBottom: 4}}>Bilan journalier</Text>
            </View>
            <View>
                <Text style={styles.rightAlign}>Date:   {formatDate(dailyPayment?.createdAt)}</Text>
            </View>
            <View style={{marginBottom: "18px"}}>
                <Text style={styles.field} >Nom:    {dailyPayment?.userFullName}  </Text>
                <Text style={styles.field}>Nom d'utilisateur:  {dailyPayment?.username}  </Text>
                <Text style={styles.field}>Montant total:   {dailyPayment?.totalPaidAmount}  FCFA</Text>
                <Text style={styles.field}>Imprimé le :  {formatDateWihoutHour(dailyPayment?.printDate)} </Text>
                <Text style={styles.field}>Nombre de paiements:   {dailyPayment.payments.length} </Text>
            </View>

            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Numéro</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Nom</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Date</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Montant</Text>
                </View>
              </View>
              {dailyPayment.payments.map((payment, index) => (
                <View style={styles.tableRow} key={index}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{index + 1}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{payment.customerFullName}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{formatDate(payment.placedAt)}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{payment.amount} FCFA</Text>
                  </View>
                </View>
              ))}
            </View>
        </Page>
    </Document>
)