import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Switch, Select } from 'antd';
import UserService from "../services/userService";
import RoomService from "../services/RoomService";
import { Formik, Field } from 'formik';
import * as Yup from "yup";
import BookingService from "../services/bookingService";
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from "react-toastify";
import { PoliceFiche } from "../components/PoliceFiche";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from 'file-saver';
import moment from 'moment';
import 'moment/locale/fr';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import Invoice from "../components/Invoice";
import { getRole } from "../auth/auth";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";


registerLocale('fr', fr);
const { Option } = Select
const CreateABooking = () => {
    const [bookings, setBookings] = useState([]);

    const [reloadCustomer, setReloadCustomer] = useState(false);

    const [isNewClient, setIsNewClient] = useState(false);

    
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState("")
    const [customers, setCustomers] = useState([])
    const [selectedId, setSelectedId] = useState(null);
    const [customerToBooking, setCustomerToBooking] = useState(null)
    const [mySearch, setMySearch] = useState({"search": ""})
    const [rooms, setRooms] = useState([])
    const [nights, setNights] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [finalAmount, setFinalAmount] = useState(0);
    const [messageToBooking, setMessageToBooking] = useState("")
    const [isFormDisabled, setIsFormDisabled] = useState(false)
    const [isBooking, setIsBooking] = useState(false)
    const navigate = useNavigate();
    const handleSwitchChangeNewCustomer = (value) => {
        //console.log('event ===>>> ', value)
        if(value === "newCustomer"){
            setIsNewClient(true);
            setIsFormDisabled(false)
            setCustomerToBooking(null)
        } else {
            setIsNewClient(false)
            setIsFormDisabled(true)
        }
    };

    const listAllCustomers = async (search) => {
        try {
          //setLoading(true);
          const data = {}
          data.search = search
          console.log('regarde le contenu ==>>> ', data);
          setLoading(true)
          const response = await UserService.getAllCustomers(data)

          setLoading(false)
          setCustomers(response.result);
        } catch (error) {
          //setLoading(false);
          console.error('Erreur lors de la récupération des clients:', error);
        }
    };

    //console.log(customers)
    useEffect(() => {
        listAllCustomers(search);
    }, [reloadCustomer]);

    const listAllRooms = async () => {
        try {
            setLoading(true)
            //const response = await RoomService.getListAllRooms(mySearch);
            const response = await RoomService.getListAllRooms();
            setLoading(false)
            //console.log("voir la taille => ", response)
            //setRooms(response.result)
            setRooms(response)
            //console.log(`loader rooms ${loading}`)
            //console.log(`list all rooms => ${JSON.stringify(response)}`);
        } catch (error) {
            console.error('Erreur lors de la récupération des clients:', error);
        }
    };
    
    useEffect(() => {
        listAllRooms()
    }, [])



    // Transform the client objects into options for the Select component
    const transformedOptions = customers.map(customer => ({
        value: customer.id,
        label: `${customer.firstName} ${customer.lastName}`,
        ...customer
    }));

    useEffect(() => {
        fullFillBookingForm(selectedId);
    }, [selectedId])
    
    const handleChangeToSelectACustomer = async (value) => {
        setSelectedId(value);
        setCustomerToBooking(null)
    };

    const fullFillBookingForm = async (selectedId) => {
        setCustomerToBooking(null)
        setLoading(true)
        const response =  await UserService.userInformationsDetail(selectedId)
        setLoading(false)
        setCustomerToBooking(response)
        setIsFormDisabled(true)
        console.log(`The customer ==> ${response}`)   
    }

    //console.log("client qui veut reserver ==> ",customerToBooking)
    const handleSubmitToCreateABooking = async (values, { resetForm }) => {
        //alert(JSON.stringify(values, null, 4));
        setLoading(true);
    
        const clientData = {
            firstName: values.firstName,
            lastName: values.lastName,
            gender: values.gender,
            maidenName: values?.maidenName,
            profession: values.profession,
            country: values.country,
            birthDate: values?.birthDate,
            birthDatePlace: values?.birthDatePlace,
            countryIdentityType: values.countryIdentityType,
            countryIdentityNumber: values.countryIdentityNumber,
            countryIdentityDeliveryDate: values.countryIdentityDeliveryDate,
            countryIdentityDeliveryPlace: values.countryIdentityDeliveryPlace,
            city: values.city,
            region: values.region,
            street: values.street,
            address: values.address,
            phoneNumber: values.phoneNumber,
        };
    
        const reservationData = {
            roomId: Number(values.roomId),
            startAt: values.startAt,
            endAt: values.endAt,
            type: values.type,
            price: values.price,
            discount: values.discount,
            earlyPayment: values.earlyPayment,
            customerComingFrom: values.customerComingFrom,
            customerGoingTo: values.customerGoingTo,
            force: values.force,
        };
    
        try {
            if (!customerToBooking) {
                const responseCreateCustomer = await UserService.createclient(clientData);
                const customerId = responseCreateCustomer.id;
                reservationData.customerId = customerId;
            } else {
                const customerId = customerToBooking.id;
                reservationData.customerId = customerId;
            }
    
            const responseToBooking = await BookingService.createBooking(reservationData);

            //console.log("Le contenu de la réponse ", responseToBooking)
            if(responseToBooking.errorResponse) {
                setIsBooking(true)
                toast.error(responseToBooking.message)
                //setMessageToBooking(responseToBooking.message)
            }else {
                setIsBooking(true)
                toast.success("Votre Reservation a été éffectuée avec succès", 
                    { 
                        //autoClose: false,
                        className: 'bg-red' 
                    })
                //setMessageToBooking("Votre Reservation a été éffectué avec succès")
                //Generate PDF and trigger print

                const docPoliceFiche = <PoliceFiche reservation={responseToBooking} />
                pdf(docPoliceFiche).toBlob().then((blob ) => {
                    // const url = URL.createObjectURL(blob);
                    // const iframe = document.createElement('iframe');
                    // iframe.style.display = 'none';
                    // iframe.src = url;
                    // document.body.appendChild(iframe);
                    // iframe.onload = () => {
                    //     iframe.contentWindow.print()
                    // }
                    saveAs(blob, `FichePolice_de_${responseToBooking.customer.lastName}_${responseToBooking.createdAt}.pdf`);
                });
                const docFacture = <Invoice reservation={responseToBooking} />
                pdf(docFacture).toBlob().then((blob) => saveAs(blob, `Facture_de_${responseToBooking.customer.lastName}_${responseToBooking.createdAt}.pdf`))
                navigate("/bookings")
                //console.log('police fiche', pdf(doc).toBlob())
            }
        } catch (error) {
            console.error("Erreur lors de la création de la réservation :", error);
        } finally {
            setLoading(false);
        }
    };    

    const searchCustomerToBooking = async(value) => {
        const data = {}
        data.search = value
        const response = await UserService.getAllCustomers(data)
        setCustomers(response.result)
    }

    const calculateNights = (startAt, endAt) => {
        const startDate = new Date(startAt);
        const endDate = new Date(endAt);
        const differenceInTime = endDate - startDate;
        const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays;
    };
    
    const handleDateChange = (values) => {
        const { startAt, endAt, price, discount } = values;
        if (startAt && endAt) {
          const calculatedNights = calculateNights(startAt, endAt);
          setNights(calculatedNights);
          const amount = calculatedNights * (price || 0);
          setTotalAmount(amount);
          setFinalAmount(amount - (discount || 0));
        }
    };

    const handlePriceChange = (values) => {
        const { price, discount } = values;
        const amount = nights * (price || 0);
        setTotalAmount(amount);
        setFinalAmount(amount - (discount || 0));
    };
    
    const handleDiscountChange = (values) => {
        const { discount } = values;
        setFinalAmount(totalAmount - (discount || 0));
    };

    // const formatDate = (date) => {
    //     if (!date) return "";
    //     return date.toLocaleDateString('fr-FR', {
    //       day: 'numeric',
    //       month: 'long',
    //       year: 'numeric',
    //     });
    // };

    //console.log('cust to book ==> ', customerToBooking)
    return (
        <>
            <Navbar />
            <div className="g-sidenav-show  bg-gray-100">
                <Sidebar />
                {/* <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 " id="sidenav-main">
                    <div className="sidenav-header">
                        <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
                        <a className="navbar-brand m-0" href=" https://demos.creative-tim.com/soft-ui-dashboard/pages/dashboard.html " >
                            <img src="../assets/img/logo-ct-dark.png" className="navbar-brand-img h-100" alt="main_logo" />
                            <span className="ms-1 font-weight-bold">Soft UI Dashboard</span>
                        </a>
                    </div>
                    <hr className="horizontal dark mt-0"/>
                    <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link className="nav-link  active" to="/rooms">
                                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <i class="fa-solid fa-bed"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">Chambres</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link  active" to="/customer">
                                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <i class="fa fa-user" aria-hidden="true"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">Clients</span>
                                </Link>
                            </li>
                            {getRole() == "ROLE_ADMIN" && (
                                <li className="nav-item">
                                    <Link className="nav-link  active" to="/workers">
                                        <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                            <i class="fa fa-user" aria-hidden="true"></i>
                                        </div>
                                        <span className="nav-link-text ms-1">Employé</span>
                                    </Link>
                                </li>
                            )}
                            <li className="nav-item">
                                <Link className="nav-link  " to="/bookings">
                                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <i class="fa fa-ticket" aria-hidden="true"></i>
                                    </div>
                                    <span className="nav-link-text ms-1"> Reservation</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link  " to="/create-a-booking">
                                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <i class="fa fa-ticket" aria-hidden="true"></i>
                                    </div>
                                    <span className="nav-link-text ms-1"> Créer une reservation</span>
                                </Link>
                            </li>
                            <li className="nav-item mt-3">
                                <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Account pages</h6>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link  " href="../pages/sign-in.html">
                                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <svg width="12px" height="12px" viewBox="0 0 40 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                        <title>document</title>
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-1870.000000, -591.000000)" fill="#FFFFFF" fillRule="nonzero">
                                            <g transform="translate(1716.000000, 291.000000)">
                                            <g transform="translate(154.000000, 300.000000)">
                                                <path className="color-background opacity-6" d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"></path>
                                                <path className="color-background" d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"></path>
                                            </g>
                                            </g>
                                        </g>
                                        </g>
                                    </svg>
                                    </div>
                                    <span className="nav-link-text ms-1">Déconnecter</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link  " href="../pages/sign-up.html">
                                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <svg width="12px" height="20px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                        <title>spaceship</title>
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-1720.000000, -592.000000)" fill="#FFFFFF" fillRule="nonzero">
                                            <g transform="translate(1716.000000, 291.000000)">
                                            <g transform="translate(4.000000, 301.000000)">
                                                <path className="color-background" d="M39.3,0.706666667 C38.9660984,0.370464027 38.5048767,0.192278529 38.0316667,0.216666667 C14.6516667,1.43666667 6.015,22.2633333 5.93166667,22.4733333 C5.68236407,23.0926189 5.82664679,23.8009159 6.29833333,24.2733333 L15.7266667,33.7016667 C16.2013871,34.1756798 16.9140329,34.3188658 17.535,34.065 C17.7433333,33.98 38.4583333,25.2466667 39.7816667,1.97666667 C39.8087196,1.50414529 39.6335979,1.04240574 39.3,0.706666667 Z M25.69,19.0233333 C24.7367525,19.9768687 23.3029475,20.2622391 22.0572426,19.7463614 C20.8115377,19.2304837 19.9992882,18.0149658 19.9992882,16.6666667 C19.9992882,15.3183676 20.8115377,14.1028496 22.0572426,13.5869719 C23.3029475,13.0710943 24.7367525,13.3564646 25.69,14.31 C26.9912731,15.6116662 26.9912731,17.7216672 25.69,19.0233333 L25.69,19.0233333 Z"></path>
                                                <path className="color-background opacity-6" d="M1.855,31.4066667 C3.05106558,30.2024182 4.79973884,29.7296005 6.43969145,30.1670277 C8.07964407,30.6044549 9.36054508,31.8853559 9.7979723,33.5253085 C10.2353995,35.1652612 9.76258177,36.9139344 8.55833333,38.11 C6.70666667,39.9616667 0,40 0,40 C0,40 0,33.2566667 1.855,31.4066667 Z"></path>
                                                <path className="color-background opacity-6" d="M17.2616667,3.90166667 C12.4943643,3.07192755 7.62174065,4.61673894 4.20333333,8.04166667 C3.31200265,8.94126033 2.53706177,9.94913142 1.89666667,11.0416667 C1.5109569,11.6966059 1.61721591,12.5295394 2.155,13.0666667 L5.47,16.3833333 C8.55036617,11.4946947 12.5559074,7.25476565 17.2616667,3.90166667 L17.2616667,3.90166667 Z"></path>
                                                <path className="color-background opacity-6" d="M36.0983333,22.7383333 C36.9280725,27.5056357 35.3832611,32.3782594 31.9583333,35.7966667 C31.0587397,36.6879974 30.0508686,37.4629382 28.9583333,38.1033333 C28.3033941,38.4890431 27.4704606,38.3827841 26.9333333,37.845 L23.6166667,34.53 C28.5053053,31.4496338 32.7452344,27.4440926 36.0983333,22.7383333 L36.0983333,22.7383333 Z"></path>
                                            </g>
                                            </g>
                                        </g>
                                        </g>
                                    </svg>
                                    </div>
                                    <span className="nav-link-text ms-1">Sign Up</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="sidenav-footer mx-3 ">
                        <div className="card card-background shadow-none card-background-mask-secondary" id="sidenavCard">
                            <div className="full-background" style={{backgroundImage: "url(" +"./assets/img/curved-images/white-curved.jpg" + ")" }}></div>
                            <div className="card-body text-start p-3 w-100">
                                <div className="icon icon-shape icon-sm bg-white shadow text-center mb-3 d-flex align-items-center justify-content-center border-radius-md">
                                    <i className="ni ni-diamond text-dark text-gradient text-lg top-0" aria-hidden="true" id="sidenavCardIcon"></i>
                                </div>
                                <div className="docs-info">
                                    <h6 className="text-white up mb-0">Need help?</h6>
                                    <p className="text-xs font-weight-bold">Please check our docs</p>
                                    <a 
                                        href="https://www.creative-tim.com/learning-lab/bootstrap/license/soft-ui-dashboard" 
                                        classNametarget="_blank"="btn btn-white btn-sm w-100 mb-0"
                                    >
                                        Documentation
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a className="btn bg-gradient-primary mt-3 w-100" href="https://www.creative-tim.com/product/soft-ui-dashboard-pro?ref=sidebarfree">Upgrade to pro</a>
                    </div>
                </aside> */}
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                {/* Navbar */}
                {/* <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
                <div className="container-fluid py-1 px-3">
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                        <li className="breadcrumb-item text-sm"><a className="opacity-5 text-dark" href="/#">Pages</a></li>
                        <li className="breadcrumb-item text-sm text-dark active" aria-current="page">Tables</li>
                    </ol>
                    <h6 className="font-weight-bolder mb-0">Tables</h6>
                    </nav>
                    <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                    <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                        <div className="input-group">
                        <span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true"></i></span>
                        <input type="text" className="form-control" placeholder="Type here..." />
                        </div>
                    </div>
                    <ul className="navbar-nav  justify-content-end">
                        <li className="nav-item d-flex align-items-center">
                        <a 
                            className="btn btn-outline-primary btn-sm mb-0 me-3" 
                            href="https://www.creative-tim.com/builder?ref=navbar-soft-ui-dashboard"
                        >
                            Online Builder
                        </a>
                        </li>
                        <li className="nav-item d-flex align-items-center">
                        <a href="/#" className="nav-link text-body font-weight-bold px-0">
                            <i className="fa fa-user me-sm-1"></i>
                            <span className="d-sm-inline d-none">Sign In</span>
                        </a>
                        </li>
                        <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                        <a href="/#" className="nav-link text-body p-0" id="iconNavbarSidenav">
                            <div className="sidenav-toggler-inner">
                            <i className="sidenav-toggler-line"></i>
                            <i className="sidenav-toggler-line"></i>
                            <i className="sidenav-toggler-line"></i>
                            </div>
                        </a>
                        </li>
                        <li className="nav-item px-3 d-flex align-items-center">
                        <a 
                            href="/#" 
                            className="nav-link text-body p-0"
                        >
                            <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
                        </a>
                        </li>
                        <li className="nav-item dropdown pe-2 d-flex align-items-center">
                        <a 
                            href="/#" 
                            className="nav-link text-body p-0" 
                            id="dropdownMenuButton" 
                            data-bs-toggle="dropdown" 
                            aria-expanded="false"
                        >
                            <i className="fa fa-bell cursor-pointer"></i>
                        </a>
                        <ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
                            <li className="mb-2">
                            <a className="dropdown-item border-radius-md" href="/#">
                                <div className="d-flex py-1">
                                <div className="my-auto">
                                    <img 
                                        alt=""
                                        src="../assets/img/team-2.jpg" 
                                        className="avatar avatar-sm  me-3 "
                                    />
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <h6 className="text-sm font-weight-normal mb-1">
                                    <span className="font-weight-bold">New message</span> from Laur
                                    </h6>
                                    <p className="text-xs text-secondary mb-0 ">
                                    <i className="fa fa-clock me-1"></i>
                                    13 minutes ago
                                    </p>
                                </div>
                                </div>
                            </a>
                            </li>
                            <li className="mb-2">
                            <a className="dropdown-item border-radius-md" href="/#">
                                <div className="d-flex py-1">
                                <div className="my-auto">
                                    <img 
                                        alt=""
                                        src="../assets/img/small-logos/logo-spotify.svg" 
                                        className="avatar avatar-sm bg-gradient-dark  me-3 "
                                    />
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <h6 className="text-sm font-weight-normal mb-1">
                                    <span className="font-weight-bold">New album</span> by Travis Scott
                                    </h6>
                                    <p className="text-xs text-secondary mb-0 ">
                                    <i className="fa fa-clock me-1"></i>
                                    1 day
                                    </p>
                                </div>
                                </div>
                            </a>
                            </li>
                            <li>
                            <a className="dropdown-item border-radius-md" href="/#">
                                <div className="d-flex py-1">
                                <div className="avatar avatar-sm bg-gradient-secondary  me-3  my-auto">
                                    <svg width="12px" height="12px" viewBox="0 0 43 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <title>credit-card</title>
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-2169.000000, -745.000000)" fill="#FFFFFF" fillRule="nonzero">
                                        <g transform="translate(1716.000000, 291.000000)">
                                            <g transform="translate(453.000000, 454.000000)">
                                            <path className="color-background" d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z" opacity="0.593633743"></path>
                                            <path className="color-background" d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"></path>
                                            </g>
                                        </g>
                                        </g>
                                    </g>
                                    </svg>
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <h6 className="text-sm font-weight-normal mb-1">
                                    Payment successfully completed
                                    </h6>
                                    <p className="text-xs text-secondary mb-0 ">
                                    <i className="fa fa-clock me-1"></i>
                                    2 days
                                    </p>
                                </div>
                                </div>
                            </a>
                            </li>
                        </ul>
                        </li>
                    </ul>
                    </div>
                </div>
                </nav> */}
                {/* End Navbar */}
                <div class="d-flex bd-highlight align-items-center namePhoneSearch mb-3 justify-content-between">
                   <div className="me-auto p-2 bd-highlight">
                        <div className="form-check form-switch">
                        <Switch 
                            
                            checked={isNewClient} 
                            onChange={() => handleSwitchChangeNewCustomer('newCustomer')} 
                        />
                        <label className="form-check-label" htmlFor="flexSwitchCheckNewClient">
                            Nouveau client
                        </label>
                        </div>
                    </div>
                    <div className="me-auto p-2 bd-highlight">
                        <div className="form-check form-switch">
                        <Switch 
                            checked={!isNewClient} 
                            onChange={() => handleSwitchChangeNewCustomer('existingCustomer')} 
                        />
                        <label className="form-check-label" htmlFor="flexSwitchCheckExistingClient">
                            Client existant
                        </label>
                        </div>
                    </div>
                    <div class="p-2 bd-highlight">
                        <div class="ms-md-auto pe-md-3 d-flex align-items-center">
                            <div class="input-group relative">
                            <Select
                                showSearch
                                style={{ width: 400 }}
                                placeholder="Rechercher le client"
                                optionFilterProp="label"
                                disabled={isNewClient}
                                defaultActiveFirstOption={false}
                                suffixIcon={null}
                                filterOption={false}
                                onSearch={searchCustomerToBooking}
                                // filterSort={(optionA, optionB) =>
                                // (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                // }
                                options={transformedOptions}
                                onChange={handleChangeToSelectACustomer}
                            />
                            <div className="d-flex align-items-center position-absolute reloadCustomer" style={{right: "-14px"}}>
                                <i class="fa fa-refresh" aria-hidden="true" size="2x" onClick={()=> setReloadCustomer(!reloadCustomer)}></i>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {isBooking && 
                    (<div className="alert alert-warning alert-dismissible fade show" role="alert">
                        <strong>Hello</strong>{messageToBooking}
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>)
                } */}
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <Formik
                                enableReinitialize={true}
                                initialValues={
                                    customerToBooking?.type !== "BANNED"
                                      ? { 
                                            firstName: customerToBooking ? customerToBooking.firstName : "",
                                            lastName: customerToBooking ? customerToBooking.lastName : "",
                                            gender: customerToBooking ? customerToBooking?.gender : "",
                                            maidenName: customerToBooking ? customerToBooking.maidenName : "",
                                            birthDate: customerToBooking ? customerToBooking.birthDate : "",
                                            birthDatePlace: customerToBooking ? customerToBooking.birthDatePlace : "",
                                            profession: customerToBooking ? customerToBooking.profession :"",
                                            country: customerToBooking ? customerToBooking.country : "",
                                            countryIdentityType: customerToBooking ? customerToBooking.countryIdentityType : "",
                                            countryIdentityNumber: customerToBooking ? customerToBooking.countryIdentityNumber : "",
                                            city: customerToBooking ? customerToBooking.city :"",
                                            region: customerToBooking ? customerToBooking.region : "",
                                            street: customerToBooking ? customerToBooking.street : "",
                                            address: customerToBooking ? customerToBooking.address : "",
                                            phoneNumber: customerToBooking ? customerToBooking.phoneNumber : "",
                                            countryIdentityDeliveryDate: customerToBooking ? customerToBooking.countryIdentityDeliveryDate : "",
                                            countryIdentityDeliveryPlace: customerToBooking ? customerToBooking.countryIdentityDeliveryPlace: "",
                                            roomId: "",
                                            startAt: "",
                                            endAt: "",
                                            type: "NORMAL",
                                            price: null,
                                            discount: 0,
                                            earlyPayment: null,
                                            force: false,
                                        }
                                    : (() => {
                                        toast.error("La reservation de ce client n'est pas possible car il est banni")
                                        console.log("Ce client est banni")
                                    })()
                                    }
                                validationContext={{ totalAmount }}
                                onSubmit={handleSubmitToCreateABooking}
                                validateOnChange={true}
                                validate={(values) => {
                                    handleDateChange(values);
                                    handlePriceChange(values);
                                    handleDiscountChange(values);
                                }}
                                // onSubmit={handleSubmit}
                                validationSchema={Yup.object().shape({
                                    firstName: Yup.string(),
                                    lastName: Yup.string()
                                        .required("Veuillez entrez le nom du client"),
                                    gender: Yup.string()
                                        .oneOf(["male", "female"])
                                        .required("Veuillez choisir le genre"),
                                    maidenName: Yup.string(),
                                    birthDate: Yup.date(),
                                    birthDatePlace: Yup.string(),
                                    profession: Yup.string()
                                        .required("Veuillez entrer la profession"),
                                    country: Yup.string()
                                        .required("Veuillez entrer le pays du client"),
                                    countryIdentityType: Yup.string()
                                        .oneOf(["ID_CARD", "PASSPORT", "DRIVER_CARD"]),
                                    countryIdentityNumber: Yup.string()
                                        .required("Veuillez entrer le numéro de la pièce d'identité"),
                                    city: Yup.string(),
                                    region: Yup.string(),
                                    customerComingFrom: Yup.string(),
                                    customerGoingTo: Yup.string(),
                                    street: Yup.string(),
                                    countryIdentityDeliveryDate: Yup.date()
                                        .required("La date de délivrance est obligatoire"),
                                    countryIdentityDeliveryPlace: Yup.string()
                                        .required("Le lieu de délivrance est obligatoire"),
                                    address: Yup.string(),
                                    phoneNumber: Yup.string()
                                        .required("Veuillez entrer le numéro de téléphone du client"),
                                    roomId: Yup.number()
                                        .required("Le numéro de la chambre est obligatoire"),  
                                    startAt: Yup.date()
                                        .required("Veuillez entrer la date de début"),
                                    endAt: Yup.date()
                                        .required("Veuillez entrer la date de sortie du client"), 
                                    type: Yup.string()
                                        .oneOf(["NORMAL", "NAP"])
                                        .required("Choisir le type de reservation"),
                                    price: Yup.number()
                                        .required("Le prix de la chambre est obligatoire"),  
                                    discount: Yup.number()
                                        .required('La réduction est requise')
                                        .max(totalAmount, `La réduction ne peut pas dépasser ${totalAmount}`),
                                    earlyPayment: Yup.number()
                                        .required("Veuillez entrer le montant avancé par le client"),
                                    force: Yup.boolean()
                                })}
                            >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        dirty,
                                        setFieldValue,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit: handleSubmitToCreateABooking,
                                        handleReset
                                    } = props;
                                    
                                    return (
                                        <form onSubmit={handleSubmitToCreateABooking}>
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="firstName" 
                                                        className="col-form-label"
                                                    >
                                                        Prenom:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="firstName"
                                                        name="firstName"
                                                        value={values?.firstName}
                                                        disabled={isFormDisabled} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control `}
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="lastName" 
                                                        className="col-form-label"
                                                    >
                                                        Nom *:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="lastName"
                                                        name="lastName"
                                                        value={values?.lastName} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={isFormDisabled}
                                                        className={`form-control ${
                                                            errors.lastName && touched.lastName
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.lastName && touched.lastName && (
                                                <div className="input-feedback">{errors.lastName}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="gender" 
                                                        className="col-form-label"
                                                    >
                                                        Genre *:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <select 
                                                        id="gender"
                                                        name="gender"
                                                        value={values?.gender} 
                                                        onChange={handleChange}
                                                        disabled={isFormDisabled}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.gender && touched.gender
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    >
                                                        <option value="">Veuillez choisir le genre de la personne</option> 
                                                        <option value="male">HOMME</option>
                                                        <option value="female">FEMME</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {errors?.gender && touched?.gender && (
                                                <div className="input-feedback">{errors?.gender}</div>
                                            )}
                                            {values?.gender === 'female' && (
                                                <div class="row">
                                                    <div className="col-3">
                                                        <label 
                                                            htmlFor="maidenName" 
                                                            class="col-form-label"
                                                        >
                                                            Nom de jeune Fille:
                                                        </label>
                                                    </div>
                                                    <div className="col-9 mt-2 mb-2">
                                                        <input 
                                                            value={values.maidenName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            disabled={isFormDisabled}
                                                            type="text" 
                                                            className={`form-control ${
                                                                errors.maidenName && touched.maidenName
                                                                    ? "text-input error"
                                                                    : "text-input"
                                                            }`} 
                                                            id="maidenName" 
                                                            name="maidenName"
                                                        /> 
                                                    </div>
                                                </div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="profession" 
                                                        className="col-form-label"
                                                    >
                                                        Profession *:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="profession"
                                                        name="profession"
                                                        value={values?.profession} 
                                                        onChange={handleChange}
                                                        disabled={isFormDisabled}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.profession && touched.profession
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.profession && touched.profession && (
                                                <div className="input-feedback">{errors.profession}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="country" 
                                                        className="col-form-label"
                                                    >
                                                        Pays du client *:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="country"
                                                        name="country"
                                                        value={values?.country} 
                                                        onChange={handleChange}
                                                        disabled={isFormDisabled}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.country && touched.country
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.country && touched.country && (
                                                <div className="input-feedback">{errors.country}</div>
                                            )}
                                            <div class="row">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="birthDate" 
                                                        class="col-form-label"
                                                    >
                                                        Date de naissance :
                                                    </label>
                                                </div>
                                                <div className="col-9 mt-2 mb-2">
                                                    <DatePicker
                                                        selected={values?.birthDate}
                                                        onChange={(birthDate) => setFieldValue('birthDate', birthDate)}
                                                        onBlur={handleBlur}
                                                        disabled={isFormDisabled}
                                                        dateFormat={['dd-MM-yyyy', 'dd/MM/yyyy']}
                                                        placeholderText="JJ-MM-AAAA ou JJ/MM/AAAA"
                                                        open={false}
                                                        //locale="fr"
                                                        showYearDropdown
                                                        scrollableYearDropdown
                                                        //minDate={new Date(new Date().getFullYear() - 40, 0, 1)}
                                                        //maxDate={new Date(new Date().getFullYear(), 11, 31)}
                                                        //yearDropdownItemNumber={40}
                                                        className={`form-control dateWith ${
                                                            errors.birthDate && touched.birthDate ? "text-input error" : "text-input"
                                                        }`}
                                                    />
                                                    {/* <DatePicker
                                                        selected={values.birthDate}
                                                        onChange={(birthDate) => setFieldValue('birthDate', birthDate)}
                                                        onBlur={handleBlur}
                                                        showTimeSelect
                                                        dateFormat="dd MMMM yyyy"
                                                        locale="fr"
                                                        className={`form-control dateWith ${
                                                            errors.birthDate && touched.birthDate ? "text-input error" : "text-input"
                                                        }`}
                                                    /> */}
                                                    {/* <DatePicker
                                                        selected={values?.countryIdentityDeliveryDate}
                                                        onChange={handleChange}
                                                        dateFormat="dd MMMM yyyy"
                                                        showTimeInput
                                                        locale="fr"
                                                        placeholderText="Sélectionnez une date"
                                                        className="form-control"
                                                    /> */}
                                                    {/* <input 
                                                        value={values.countryIdentityDeliveryDate}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        type="date" 
                                                        className={`form-control ${
                                                            errors.countryIdentityDeliveryDate && touched.countryIdentityDeliveryDate
                                                                ? "text-input error"
                                                                : "text-input"
                                                        }`} 
                                                        id="countryIdentityDeliveryDate" 
                                                        name="countryIdentityDeliveryDate"
                                                    />  */}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="birthDatePlace" 
                                                        class="col-form-label"
                                                    >
                                                        Lieu de naissance:
                                                    </label>
                                                </div>
                                                <div className="col-9 mt-2 mb-2">
                                                    <input 
                                                        value={values?.birthDatePlace}
                                                        onChange={handleChange}
                                                        disabled={isFormDisabled}
                                                        onBlur={handleBlur}
                                                        type="text" 
                                                        className={`form-control ${
                                                            errors.birthDatePlace && touched.birthDatePlace
                                                                ? "text-input error"
                                                                : "text-input"
                                                        }`} 
                                                        id="birthDatePlace" 
                                                        name="birthDatePlace"
                                                    /> 
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="countryIdentityType" 
                                                        className="col-form-label"
                                                    >
                                                        Type d'identité du pays *:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <select 
                                                        id="countryIdentityType"
                                                        name="countryIdentityType"
                                                        type="text"
                                                        value={values?.countryIdentityType} 
                                                        onChange={handleChange}
                                                        disabled={isFormDisabled}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.countryIdentityType && touched.countryIdentityType
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    >
                                                        <option value="">Veuillez selectionné un moyenne d'identification</option>
                                                        <option value="ID_CARD">CARTE D'IDENTITE</option>
                                                        <option value="PASSPORT">PASSEPORT</option>
                                                        <option value="DRIVER_CARD">PERMIS DE CONDUIRE</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {errors.countryIdentityType && touched.countryIdentityType && (
                                                <div className="input-feedback">{errors.countryIdentityType}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="countryIdentityNumber" 
                                                        className="col-form-label"
                                                    >
                                                        Numéro d'identité dans son pays *:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="countryIdentityNumber"
                                                        name="countryIdentityNumber"
                                                        type="text"
                                                        value={values?.countryIdentityNumber} 
                                                        onChange={handleChange}
                                                        disabled={isFormDisabled}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.countryIdentityNumber && touched.countryIdentityNumber
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.countryIdentityNumber && touched.countryIdentityNumber && (
                                                <div className="input-feedback">{errors.countryIdentityNumber}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="countryIdentityDeliveryDate" 
                                                        className="col-form-label"
                                                    >
                                                        Date de délivrance *:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    {/* <input 
                                                        id="countryIdentityDeliveryDate"
                                                        name="countryIdentityDeliveryDate"
                                                        type="date"
                                                        value={values?.countryIdentityDeliveryDate} 
                                                        onChange={handleChange}
                                                        disabled={isFormDisabled}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.countryIdentityDeliveryDate && touched.countryIdentityDeliveryDate
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    /> */}
                                                    <DatePicker
                                                        selected={values?.countryIdentityDeliveryDate}
                                                        onChange={(date) => setFieldValue('countryIdentityDeliveryDate', date)}
                                                        onBlur={handleBlur}
                                                        //showTimeSelect
                                                        dateFormat={['dd-MM-yyyy', 'dd/MM/yyyy']}
                                                        placeholderText="JJ-MM-AAAA ou JJ/MM/AAAA"
                                                        open={false}
                                                        showYearDropdown
                                                        scrollableYearDropdown
                                                        //minDate={new Date(new Date().getFullYear() - 40, 0, 1)}
                                                        //maxDate={new Date(new Date().getFullYear(), 11, 31)}
                                                        //yearDropdownItemNumber={40}
                                                        disabled={isFormDisabled}
                                                        //locale="fr"
                                                        className={`form-control dateWith ${
                                                            errors.countryIdentityDeliveryDate && touched.countryIdentityDeliveryDate ? "text-input error" : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.countryIdentityDeliveryDate && touched.countryIdentityDeliveryDate && (
                                                <div className="input-feedback">{errors.countryIdentityDeliveryDate}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="countryIdentityDeliveryPlace" 
                                                        className="col-form-label"
                                                    >
                                                        Lieu de délivrance *:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="countryIdentityDeliveryPlace"
                                                        name="countryIdentityDeliveryPlace"
                                                        type="text"
                                                        value={values?.countryIdentityDeliveryPlace} 
                                                        onChange={handleChange}
                                                        disabled={isFormDisabled}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.countryIdentityDeliveryPlace && touched.countryIdentityDeliveryPlace
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.countryIdentityDeliveryPlace && touched.countryIdentityDeliveryPlace && (
                                                <div className="input-feedback">{errors.countryIdentityDeliveryPlace}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="city" 
                                                        className="col-form-label"
                                                    >
                                                        Ville du client :
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="city"
                                                        name="city"
                                                        type="text"
                                                        value={values?.city} 
                                                        onChange={handleChange}
                                                        disabled={isFormDisabled}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.city && touched.city
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.city && touched.city && (
                                                <div className="input-feedback">{errors.city}</div>
                                            )}
                                            
                                            
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="region" 
                                                        className="col-form-label"
                                                    >
                                                        Région du client :
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="region"
                                                        name="region"
                                                        type="text"
                                                        value={values?.region} 
                                                        onChange={handleChange}
                                                        disabled={isFormDisabled}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.region && touched.region
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.region && touched.region && (
                                                <div className="input-feedback">{errors.region}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="street" 
                                                        className="col-form-label"
                                                    >
                                                        Quartier :
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="street"
                                                        name="street"
                                                        type="text"
                                                        value={values?.street} 
                                                        onChange={handleChange}
                                                        disabled={isFormDisabled}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.street && touched.street
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.street && touched.street && (
                                                <div className="input-feedback">{errors.street}</div>
                                            )}
                                            
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="address" 
                                                        className="col-form-label"
                                                    >
                                                        Adresse du client :
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="address"
                                                        name="address"
                                                        type="text"
                                                        value={values?.address} 
                                                        onChange={handleChange}
                                                        disabled={isFormDisabled}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.address && touched.address
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.address && touched.address && (
                                                <div className="input-feedback">{errors.address}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="phoneNumber" 
                                                        className="col-form-label"
                                                    >
                                                        Telephone du client *:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="phoneNumber"
                                                        name="phoneNumber"
                                                        type="text"
                                                        value={values?.phoneNumber} 
                                                        onChange={handleChange}
                                                        disabled={isFormDisabled}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.phoneNumber && touched.phoneNumber
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.phoneNumber && touched.phoneNumber && (
                                                <div className="input-feedback">{errors.phoneNumber}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="roomId" 
                                                        className="col-form-label"
                                                    >
                                                        Nom de la chambre * :
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <Field name="roomId">
                                                        {({ field }) => (
                                                            <Select 
                                                                {...field}
                                                                showSearch
                                                                id="roomId"
                                                                placeholder="Sélectionner une chambre"
                                                                optionFilterProp="children"
                                                                name="roomId"
                                                                value={values?.roomId} 
                                                                onChange={(value) => {
                                                                    console.log('dans onchange ==> ', value)
                                                                    //const roomId = parseInt(event.target.value, 10);
                                                                    const room = rooms.find((room) => room.id === value);
                                                                    setFieldValue('roomId', value);
                                                                    setFieldValue('price', room.price);
                                                                }}
                                                                onBlur={handleBlur}
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                style={{ width: '100%' }}
                                                            >   
                                                                <Option value="">
                                                                    Veuillez entrez le numéro le numéro de la chambre
                                                                </Option>
                                                                {rooms.map((room => (
                                                                    <Option key={room.id} value={room.id}>
                                                                        {room.number}
                                                                    </Option>
                                                                )))}
                                                            </Select>
                                                        )} 
                                                    </Field>
                                                    {/* <select 
                                                            id="roomId"
                                                            name="roomId"
                                                            value={values.roomId} 
                                                            onChange={(event) => {
                                                                const roomId = parseInt(event.target.value, 10);
                                                                const room = rooms.find((room) => room.id === roomId);
                                                                setFieldValue('roomId', roomId);
                                                                setFieldValue('price', room.price);
                                                            }}
                                                            onBlur={handleBlur}
                                                            className={`form-control form-select`}
                                                            aria-label="Default select example"
                                                        >
                                                            <option value="">Veuillez Choisir la chambre à reserver</option>
                                                            {rooms.map(room => (
                                                                <option value={room.id}>{room.number}</option>
                                                            ))}
                                                            
                                                        </select> */} 
                                                </div>
                                            </div>
                                            {errors.roomId && touched.roomId && (
                                                <div className="input-feedback">{errors.roomId}</div>
                                            )}
                                            <div className="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="startAt" 
                                                        className="col-form-label"
                                                    >
                                                        Date de début *:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                <DatePicker
                                                    selected={values?.startAt}
                                                    onChange={(date) => {
                                                        const selectedDate = moment(date);
                                                        const finalDate = selectedDate.toDate();
                                                        setFieldValue('startAt', finalDate);
                                                        handleDateChange({ ...values, startAt: finalDate });
                                                    }}
                                                    onBlur={handleBlur}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={15}
                                                    locale="fr"
                                                    dateFormat="d MMMM yyyy 'à' HH:mm"
                                                    className={`form-control dateWith ${
                                                        errors.startAt && touched.startAt ? "text-input error" : "text-input"
                                                    }`}
                                                    showTimeInput={true}
                                                    
                                                />
                                                    {/* <DatePicker
                                                        selected={values?.startAt}
                                                        onChange={(date) => {
                                                            const selectedDate = moment(date);

                                                            // Si l'heure n'a pas été modifiée, on la fixe à 12:00 PM
                                                            if (selectedDate.hour() !== 12 || selectedDate.minute() !== 0) {
                                                            selectedDate.set({ hour: 12, minute: 0, second: 0 });
                                                            }

                                                            const finalDate = selectedDate.toDate();
                                                            setFieldValue('startAt', finalDate);
                                                            handleDateChange({ ...values, startAt: finalDate });
                                                        }}
                                                        onBlur={handleBlur}
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        locale="fr"
                                                        dateFormat="d MMMM yyyy 'à' HH:mm"
                                                        className={`form-control dateWith ${
                                                            errors.startAt && touched.startAt ? "text-input error" : "text-input"
                                                        }`}
                                                        showTimeInput={true}
                                                    /> */}
                                                    {/* <DatePicker
                                                        selected={values?.startAt}
                                                        onChange={(date) => {
                                                            const selectedDate = moment(date);
                                                            const currentDate = moment(values?.startAt);

                                                            // Si c'est une nouvelle sélection ou si l'heure n'a pas été modifiée, on la fixe à 12:00 PM
                                                            // if (!values?.startAt || (currentDate.hour() === 12 && currentDate.minute() === 0 && 
                                                            //     (selectedDate.hour() !== 12 || selectedDate.minute() !== 0))) {
                                                            //     selectedDate.set({hour: 12, minute: 0, second: 0});
                                                            // }
                                                            if (selectedDate.hour() === currentDate.hour() && selectedDate.minute() === currentDate.minute()) {
                                                                selectedDate.set({ hour: 12, minute: 0, second: 0 });
                                                            }

                                                            const finalDate = selectedDate.toDate();
                                                            setFieldValue('startAt', finalDate);
                                                            handleDateChange({ ...values, startAt: finalDate });
                                                        }}
                                                        onBlur={handleBlur}
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        locale="fr"
                                                        dateFormat="d MMMM yyyy 'à' HH:mm"
                                                        timeCaption="Heure"
                                                        className={`form-control dateWith ${
                                                            errors.startAt && touched.startAt ? "text-input error" : "text-input"
                                                        }`}
                                                        showTimeInput={true}
                                                    /> */}
                                                    {/* <DatePicker
                                                        selected={values?.startAt}
                                                        onChange={(date) => {
                                                            const selectedDate = moment(date);

                                                        // Si l'heure n'a pas été modifiée, on la fixe à 12:00 PM
                                                        if (selectedDate.hour() !== 12 || selectedDate.minute() !== 0) {
                                                            selectedDate.set({ hour: 12, minute: 0, second: 0 });
                                                        }

                                                        const finalDate = selectedDate.toDate();
                                                        setFieldValue('startAt', finalDate);
                                                        handleDateChange({ ...values, startAt: finalDate });
                                                        }}
                                                        onBlur={handleBlur}
                                                        showTimeSelect
                                                        locale="fr"
                                                        dateFormat="d MMMM yyyy 'à' HH:mm"
                                                        className={`form-control dateWith ${
                                                            errors.startAt && touched.startAt ? "text-input error" : "text-input"
                                                        }`}
                                                        showTimeInput={true}
                                                    /> */}
                                                    {/* <DatePicker
                                                        selected={values?.startAt}
                                                        onChange={(date) => {
                                                            const dateAt12PM = moment(date).set({hour: 12, minute: 0, second: 0}).toDate();
                                                            setFieldValue('startAt', dateAt12PM);
                                                            handleDateChange({ ...values, startAt: dateAt12PM });
                                                        }}
                                                        onBlur={handleBlur}
                                                        showTimeSelect
                                                        dateFormat="Pp"
                                                        className={`form-control dateWith ${
                                                            errors.startAt && touched.startAt ? "text-input error" : "text-input"
                                                        }`}
                                                        showTimeInput={false}
                                                    /> */}
                                                    {/* <DatePicker
                                                        selected={values.startAt}
                                                        onChange={(date) => {
                                                            setFieldValue('startAt', date);
                                                            handleDateChange({ ...values, startAt: date });
                                                        }}
                                                        onBlur={handleBlur}
                                                        showTimeSelect
                                                        dateFormat="Pp"
                                                        className={`form-control dateWith ${
                                                            errors.startAt && touched.startAt ? "text-input error" : "text-input"
                                                        }`}
                                                    /> */}
                                                    
                                                </div>
                                            </div>
                                            {errors.startAt && touched.startAt && (
                                                <div className="input-feedback">{errors.startAt}</div>
                                            )}
                                            <div className="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="endAt" 
                                                        className="col-form-label"
                                                    >
                                                        Date de fin *:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <DatePicker
                                                        selected={values?.endAt}
                                                        onChange={(date) => {
                                                            const selectedDate = moment(date);
                                                            const currentDate = moment(values?.endAt || moment());

                                                            // Si l'heure n'a pas été modifiée, on la fixe à 12:00 PM
                                                            // if (selectedDate.hour() === currentDate.hour() && selectedDate.minute() === currentDate.minute()) {
                                                            // selectedDate.set({ hour: 12, minute: 0, second: 0 });
                                                            // }
                                                            if (selectedDate.hour() === new Date().getHours() && selectedDate.minute() === new Date().getMinutes()) {
                                                                finalDate = new Date(
                                                                  selectedDate.year(),
                                                                  selectedDate.month(),
                                                                  selectedDate.date(),
                                                                  12,
                                                                  0,
                                                                  0,
                                                                  0
                                                                );
                                                              }

                                                            const finalDate = selectedDate.toDate();
                                                            setFieldValue('endAt', finalDate);
                                                            handleDateChange({ ...values, endAt: finalDate });
                                                        }}
                                                        // onChange={(date) => {
                                                        //     const dateAt12PM = moment(date).set({hour: 12, minute: 0, second: 0}).toDate();
                                                        //     setFieldValue('endAt', dateAt12PM);
                                                        //     handleDateChange({ ...values, endAt: dateAt12PM });
                                                        // }}
                                                        onBlur={handleBlur}
                                                        showTimeSelect
                                                        //dateFormat="Pp"
                                                        timeIntervals={15}
                                                        locale="fr"
                                                        dateFormat="d MMMM yyyy 'à' HH:mm"
                                                        className={`form-control dateWith ${
                                                            errors.endAt && touched.endAt ? "text-input error" : "text-input"
                                                        }`}
                                                        showTimeInput={true}
                                                    />
                                                    {/* <DatePicker
                                                        selected={values.endAt}
                                                        onChange={(date) => {
                                                            setFieldValue('endAt', date);
                                                            handleDateChange({ ...values, endAt: date });
                                                        }}
                                                        onBlur={handleBlur}
                                                        showTimeSelect
                                                        dateFormat="Pp"
                                                        style={{width: "100%"}}
                                                        className={`form-control ${
                                                            errors.endAt && touched.endAt ? "text-input error" : "text-input"
                                                        } dateWith`}
                                                    /> */}
                                                </div>
                                            </div>
                                            {errors.endAt && touched.endAt && (
                                                <div className="input-feedback">{errors.endAt}</div>
                                            )}
                                            <div className="row mb-2">
                                                <div className="col-3">
                                                    <label htmlFor="nights" style={{fontSize: "16px"}} className="col-form-label">Nombre de nuitées</label>
                                                </div>
                                                <div className="col-9">
                                                    <input
                                                        type="text"
                                                        id="nights"
                                                        name="nights"
                                                        value={nights}
                                                        readOnly
                                                        disabled
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="type" 
                                                        className="col-form-label"
                                                    >
                                                        Type de reservation *:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <select 
                                                        id="type"
                                                        name="type"
                                                        value={values?.type} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.type && touched.type
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                        aria-label="Default select example"
                                                    >
                                                        {/* <option value="">Veuillez Choisir le type de reservation</option> */}
                                                        <option value="NORMAL">NORMAL</option>
                                                        <option value="NAP">SIESTE</option>
                                                        
                                                    </select>
                                                </div>
                                            </div>
                                            {errors.type && touched.type && (
                                                <div className="input-feedback">{errors.type}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="price" 
                                                        className="col-form-label"
                                                    >
                                                        Prix de la reservation *:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        type="number"
                                                        id="price" 
                                                        name="price"
                                                        value={values?.price} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.price && touched.price
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {customerToBooking?.vip && (
                                               <div className="text-primary">Prix préférentiel {customerToBooking?.preferredPrice}</div> 
                                            )}
                                            {errors.price && touched.price && (
                                                <div className="input-feedback">{errors.price}</div>
                                            )}
                                            <div className="row mb-2">
                                                <div className="col-3">
                                                    <label htmlFor="totalAmount" style={{fontSize: "16px"}} className="col-form-label">Montant total</label>
                                                </div>
                                                <div className="col-9">
                                                    <input
                                                        type="text"
                                                        id="totalAmount"
                                                        name="totalAmount"
                                                        value={totalAmount}
                                                        disabled
                                                        readOnly
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="discount" 
                                                        className="col-form-label"
                                                    >
                                                        Reduction:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        type="number"
                                                        id="discount" 
                                                        name="discount"
                                                        value={values?.discount} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.discount && touched.discount
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.discount && touched.discount && (
                                                <div className="input-feedback">{errors.discount}</div>
                                            )}
                                            <div className="row mb-2">
                                                <div className="col-3">
                                                    <label htmlFor="finalAmount" style={{fontSize: "16px"}} className="col-form-label">Montant après réduction</label>
                                                </div>
                                                <div className="col-9">
                                                    <input
                                                        type="text"
                                                        id="finalAmount"
                                                        name="finalAmount"
                                                        value={finalAmount}
                                                        disabled
                                                        readOnly
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="earlyPayment" 
                                                        className="col-form-label"
                                                    >
                                                        Payement anticipé *:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        type="number"
                                                        id="earlyPayment" 
                                                        name="earlyPayment"
                                                        value={values?.earlyPayment} 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.earlyPayment && touched.earlyPayment
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="customerComingFrom" 
                                                        className="col-form-label"
                                                    >
                                                        Lieu de provenance:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="customerComingFrom"
                                                        name="customerComingFrom"
                                                        type="text"
                                                        value={values?.customerComingFrom} 
                                                        onChange={handleChange}
                                                        //disabled={isFormDisabled}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.customerComingFrom && touched.customerComingFrom
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="customerGoingTo" 
                                                        className="col-form-label"
                                                    >
                                                        Lieu de destination:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <input 
                                                        id="customerGoingTo"
                                                        name="customerGoingTo"
                                                        type="text"
                                                        value={values?.customerGoingTo} 
                                                        onChange={handleChange}
                                                        //disabled={isFormDisabled}
                                                        onBlur={handleBlur}
                                                        className={`form-control ${
                                                            errors.customerGoingTo && touched.customerGoingTo
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {errors.earlyPayment && touched.earlyPayment && (
                                                <div className="input-feedback">{errors.earlyPayment}</div>
                                            )}
                                            <div class="row mb-2">
                                                <div className="col-3">
                                                    <label 
                                                        htmlFor="force" 
                                                        className="col-form-label"
                                                    >
                                                        Forcer la reservation:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <select 
                                                        defaultValue={false}
                                                        id="force"
                                                        name="force"
                                                        value={values?.force} 
                                                        onChange={(e) => {
                                                            const value = e.target.value === 'true';
                                                            setFieldValue('force', value);
                                                        }}
                                                        // onBlur={}
                                                        className={`form-control form-select`}
                                                    >
                                                        <option value="false">NON</option>
                                                        <option value="true">OUI</option>
                                                        
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <button 
                                                    type="submit" 
                                                    className="btn btn-primary "
                                                >
                                                    Creer une Reservation
                                                </button>
                                                {/* <button 
                                                    type="button" 
                                                    className="btn btn-secondary"
                                                    data-bs-toggle="offcanvas" 
                                                    data-bs-target="#offcanvasRight" 
                                                    aria-controls="offcanvasRight"
                                                >
                                                    Voir info reservation
                                                </button> */}
                                            </div>
                                            
                                            
                                        </form>
                                    )
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
                {/* {isBooking && 
                    (<div className="alert alert-warning alert-dismissible fade show" role="alert">
                        <strong>Hello</strong>{messageToBooking}
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>)
                } */}
            </main>
            <div className="fixed-plugin">
                {/* <a 
                    href="/#"
                    className="fixed-plugin-button text-dark position-fixed px-3 py-2"
                >
                <i className="fa fa-cog py-2"> </i>
                </a> */}
                <div className="card shadow-lg ">
                    <div className="card-header pb-0 pt-3 ">
                        <div className="float-start">
                            <h5 className="mt-3 mb-0">Soft UI Configurator</h5>
                            <p>See our dashboard options.</p>
                        </div>
                        <div className="float-end mt-4">
                            <button className="btn btn-link text-dark p-0 fixed-plugin-close-button">
                                <i className="fa fa-close"></i>
                            </button>
                        </div>
                        {/* End Toggle Button */}
                    </div>
                    <hr className="horizontal dark my-1"/>
                    <div className="card-body pt-sm-3 pt-0">
                        {/*Sidebar Backgrounds */}
                        <div>
                        <h6 className="mb-0">Sidebar Colors</h6>
                        </div>
                        <a href={"javascript"} className="switch-trigger background-color">
                        <div className="badge-colors my-2 text-start">
                            <span className="badge filter bg-gradient-primary active" data-color="primary" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-dark" data-color="dark" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-info" data-color="info" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-success" data-color="success" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-warning" data-color="warning" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-danger" data-color="danger" onclick="sidebarColor(this)"></span>
                        </div>
                        </a>
                        {/*Sidenav Type*/}
                        <div className="mt-3">
                        <h6 className="mb-0">Sidenav Type</h6>
                        <p className="text-sm">Choose between 2 different sidenav types.</p>
                        </div>
                        <div className="d-flex">
                        <button className="btn bg-gradient-primary w-100 px-3 mb-2 active" data-className="bg-transparent" onclick="sidebarType(this)">Transparent</button>
                        {/* <button className="btn bg-gradient-primary w-100 px-3 mb-2 ms-2" data-className="bg-white" onclick="sidebarType(this)">White</button> */}
                        </div>
                        <p className="text-sm d-xl-none d-block mt-2">You can change the sidenav type just on desktop view.</p>
                        {/* Navbar Fixed */}
                        <div className="mt-3">
                        <h6 className="mb-0">Navbar Fixed</h6>
                        </div>
                        <div className="form-check form-switch ps-0">
                        <input 
                            className="form-check-input mt-1 ms-auto" 
                            type="checkbox" 
                            id="navbarFixed" 
                            onclick="navbarFixed(this)"
                        />
                        </div>
                        <hr className="horizontal dark my-sm-4" />
                        <a className="btn bg-gradient-dark w-100" href="https://www.creative-tim.com/product/soft-ui-dashboard">Free Download</a>
                        <a className="btn btn-outline-dark w-100" href="https://www.creative-tim.com/learning-lab/bootstrap/license/soft-ui-dashboard">View documentation</a>
                        <div className="w-100 text-center">
                        <a className="github-button" href="https://github.com/creativetimofficial/soft-ui-dashboard" data-icon="octicon-star" data-size="large" data-show-count="true" aria-label="Star creativetimofficial/soft-ui-dashboard on GitHub">Star</a>
                        <h6 className="mt-3">Thank you for sharing!</h6>
                        <a 
                            href="https://twitter.com/intent/tweet?text=Check%20Soft%20UI%20Dashboard%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%23bootstrap5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fsoft-ui-dashboard" 
                            className="btn btn-dark mb-0 me-2"
                        >
                            <i className="fab fa-twitter me-1" aria-hidden="true"></i> Tweet
                        </a>
                        <a 
                            href={"https://www.facebook.com/sharer/sharer.php"} 
                            className="btn btn-dark mb-0 me-2"
                        >
                            <i className="fab fa-facebook-square me-1" aria-hidden="true" />Share
                        </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasBottomLabel">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasBottomLabel">Information sur la reservation</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body small">
                    <div className="row mb-2">
                        <div className="col-3">
                            <label htmlFor="nights" style={{fontSize: "16px"}} className="col-form-label">Nombre de nuitées</label>
                        </div>
                        <div className="col-9">
                            <input
                                type="text"
                                id="nights"
                                name="nights"
                                value={nights}
                                readOnly
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-3">
                            <label htmlFor="totalAmount" style={{fontSize: "16px"}} className="col-form-label">Montant total</label>
                        </div>
                        <div className="col-9">
                            <input
                                type="text"
                                id="totalAmount"
                                name="totalAmount"
                                value={totalAmount}
                                readOnly
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-3">
                            <label htmlFor="finalAmount" style={{fontSize: "16px"}} className="col-form-label">Montant après réduction</label>
                        </div>
                        <div className="col-9">
                            <input
                                type="text"
                                id="finalAmount"
                                name="finalAmount"
                                value={finalAmount}
                                readOnly
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            
        </div>
        </>
        
        
    )
}
export default CreateABooking;