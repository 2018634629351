import { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import RoomService from "../services/RoomService";
import { Formik } from 'formik';
import * as Yup from "yup";
//import { Pagination } from "antd";
import ReactPaginate from "react-paginate";
import { getRole, logout } from "../auth/auth";
import UserService from "../services/userService";
import Sidebar from "../components/Sidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import Navbar from "../components/Navbar";

const PasswordChangeSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Mot de passe actuel est requis'),
    newPassword: Yup.string()
      .min(6, 'Le nouveau mot de passe doit comporter au moins 6 caractères')
      .required('Nouveau mot de passe est requis'),
});

const Rooms = () => {
    const navigate = useNavigate()
    const [rooms, setRooms] = useState([]);
    const [search, setSearch] = useState("")
    //const [mySearch, setSearch] = useState({"search": ""})
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setloading] = useState(false)
    const closeModalCreateRoom = useRef(null)
    const closeModalToDelete = useRef(null)
    const closeModalToUpdate = useRef(null)
    const [roomUpdateOrDelete, setRoomUpdateOrDelete] = useState(null);
    const [totalNumberOfPage, setTotalNumberOfPage] = useState(0)
    const [totalNumberPerPage, setTotalNumberPerPage] = useState(8)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalPage, setTotalResult] = useState(0)

    const handleSearchRooms =  async (event) => {
        const query = event.target.value;
        setSearch(query);
        setCurrentPage(0)
        
    }

    const listAllRooms = async (search, page, numberPerPage) => {
        const data = {}
        data.search = search;
        data.page = page;
        data.numberPerPage = numberPerPage;
        setloading(true)
        try {
            const response = await RoomService.getAllRooms(data)
            if (response.errorResponse) {
                toast.error(response.message)
            } else {
                setRooms(response.result);
                setTotalNumberOfPage(response.totalNumberOfPage)
                setTotalResult(response.totalResult)
            }
            
        } catch (error) {
            console.log('Error fetching rooms')
        } finally {
            setloading(false)

        }
    }

    useEffect(() => {
        listAllRooms(search, currentPage, totalNumberPerPage)
    }, [search, currentPage, totalNumberPerPage])
    //console.log("voir toutes les chambres ==>",rooms)
    // const listAllRooms = async () => {
    //     try {
    //         setloading(true)
    //         const response = await RoomService.getAllRooms(mySearch);
    //         setloading(false)
            
    //         setMypagination((prevState) => ({...prevState,current: response.page, total: response.totalResult}))
    //         //console.log("voir la taille => ", response)
    //         setRooms(response.result)
    //         //console.log(`loader rooms ${loading}`)
    //         //console.log(`list all rooms => ${JSON.stringify(response)}`);
    //     } catch (error) {
    //         console.error('Erreur lors de la récupération des clients:', error);
    //     }
    // };

    //console.log('mon current ', roomUpdateOrDelete)

    // useEffect(() => {
    //     listAllRooms();
    // }, []);
    const handleSubmitToCreateARoom = async (values, { resetForm }) => {
        //alert(JSON.stringify(values, null, 4))
        const response = await RoomService.createRooms(values)
        if (response) {
            console.log('ajout chambre ==>', response)
            resetForm()
            console.log('le current click ==>> ', closeModalCreateRoom.current)
            closeModalCreateRoom.current.click()
            listAllRooms(search, currentPage, totalNumberPerPage);
            setCurrentPage(0)
            toast("Le Chambre a été Crée avec succès")
            
        } else {
            console.log('mon erreur')
            
        }
    };

    const getRoomInformationsDetail = async (id) => {
        console.log("Detail ===> user",id)
        setloading(true)
        const response =  await RoomService.roomInformationsDetail(id)
        setloading(false)
        setRoomUpdateOrDelete(response)
        console.log(`detail du user ${response}`)
    }

    const roomADelete = async (id) => {
        try {
            setloading(true)
            const response = await RoomService.deleteRoom(id)
            if (response.errorResponse) {
                toast.error(response.message)
            } else {
                toast.success("La chambre a été Supprimé avec succès")
                listAllRooms(search, currentPage, totalNumberPerPage);
                closeModalToDelete.current.click()
            }
            
        } catch (error) {
            console.error("Erreur lors de la suppression de la chambre:", error)
            toast.error("Une erreur inattendue s'est produite lors de la suppression de la chambre.")
        } finally {
            setloading(false)
        }
        //console.log('bouton de fermeture  de delete customer ==> ', closeModalToDelete.current)


    }

    const handleSubmitToUpdateARoom = async (values, { resetForm }) => {
        console.log(`fermer apres mise à jour bon`)
        setloading(true)
        const response = await RoomService.updateRoomInformation(roomUpdateOrDelete.id, values)
        setloading(false)
        console.log(`fermer le modal de mise à jour ${closeModalToUpdate.current}`)
        closeModalToUpdate.current.click()
        listAllRooms(search, currentPage, totalNumberPerPage)
        toast("Le chambre a été Modifié avec succès")
        //console.log(`loader mise à jour ${loading}`)

    }

    const handleChangeCurrentPage = async(data) => {
        let currentPageu = data.selected;
        setCurrentPage(currentPageu)
        return ;
    }

    // const handleChangeCurrentPage = (page) => {
    //     setCurrentPage(page - 1)
    // }
        
    // const handlePaginationChange= async (page, pageSize) => {
    //     console.log('which page customer ===>> ', page)
    //     try {
    //         setloading(true)
    //         mySearch.page = page - 1;
    //         const response  = await RoomService.getRoomsCurrentPage(mySearch)
    //         setRooms(response.result)
    //         setloading(false)
    //         //console.log('data from header ===>>> ', data)
    //         //navigate(`/shop?page=${my_page}`)
    //         document.body.scrollTop = 0;
    //         document.documentElement.scrollTop = 0;
    //     } catch (error) {
    //         console.log(error)
    //     }
        
    // };

    const itemRender = (current, type, originalElement) => {
        if (type === 'page') {
          return <a>{current}</a>;
        }
        
        return originalElement;
    };
    
    const changePassword = async (values) => {
        //alert(values)
        const response = await UserService.updatePassword(values)
    }
    
    const handleRoomsPerPageChange = (e) => {
        setCurrentPage(0);
        setTotalNumberPerPage(Number(e.target.value));
        return ;
    }
    return (
        <>
        <Navbar />
        <div className="g-sidenav-show  bg-gray-100">
            {/* <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 " id="sidenav-main">
                <div className="sidenav-header">
                    <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
                    <a className="navbar-brand m-0" href=" https://demos.creative-tim.com/soft-ui-dashboard/pages/dashboard.html " target="_blank">
                        <img src="../assets/img/logo-ct-dark.png" className="navbar-brand-img h-100" alt="main_logo" />
                        <span className="ms-1 font-weight-bold">Soft UI Dashboard</span>
                    </a>
                </div>
                <hr className="horizontal dark mt-0"/>
                <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link  active" to="/rooms">
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                <i class="fa-solid fa-bed"></i>
                                </div>
                                <span className="nav-link-text ms-1">Chambres</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link  active" to="/customer">
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="fa fa-user" aria-hidden="true"></i>
                                </div>
                                <span className="nav-link-text ms-1">Clients</span>
                            </Link>
                        </li>
                        {getRole() == "ROLE_ADMIN" && (
                            <li className="nav-item">
                                <Link className="nav-link  active" to="/workers">
                                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <i class="fa fa-user" aria-hidden="true"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">Employé</span>
                                </Link>
                            </li>
                        )}
                        <li className="nav-item">
                            <a className="nav-link  " href="/bookings">
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="fa fa-ticket" aria-hidden="true"></i>
                                </div>
                                <span className="nav-link-text ms-1"> Reservation</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link  " to="/create-a-booking">
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="fa fa-ticket" aria-hidden="true"></i>
                                </div>
                                <span className="nav-link-text ms-1"> Créer une reservation</span>
                            </Link>
                        </li>
                        <li className="nav-item mt-3">
                            <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Account pages</h6>
                        </li>
                        <li className="nav-item">
                            
                        </li>
                        <div className="d-flex align-items-center justify-content-center">
                            <button 
                                    className="nav-link-text ms-1"
                                    onClick={() => {logout(); navigate('/login')}}    
                                >
                                Déconnecter
                            </button>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <button 
                                className="nav-link-text ms-1"
                                data-bs-toggle="modal" 
                                data-bs-target="#staticChangePasswordBackdrop"
                            >
                                Changer mot de passer
                            </button>
                        </div>
                    </ul>
                </div>
            </aside> */}
            <Sidebar />
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                {/* Navbar */}
                {/* <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
                <div className="container-fluid py-1 px-3">
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                        <li className="breadcrumb-item text-sm"><a className="opacity-5 text-dark" href="/#">Pages</a></li>
                        <li className="breadcrumb-item text-sm text-dark active" aria-current="page">Tables</li>
                    </ol>
                    <h6 className="font-weight-bolder mb-0">Tables</h6>
                    </nav>
                    <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                    <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                        <div className="input-group">
                        <span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true"></i></span>
                        <input type="text" className="form-control" placeholder="Type here..." />
                        </div>
                    </div>
                    <ul className="navbar-nav  justify-content-end">
                        <li className="nav-item d-flex align-items-center">
                        <a 
                            className="btn btn-outline-primary btn-sm mb-0 me-3" 
                            href="https://www.creative-tim.com/builder?ref=navbar-soft-ui-dashboard"
                        >
                            Online Builder
                        </a>
                        </li>
                        <li className="nav-item d-flex align-items-center">
                        <a href="/#" className="nav-link text-body font-weight-bold px-0">
                            <i className="fa fa-user me-sm-1"></i>
                            <span className="d-sm-inline d-none">Sign In</span>
                        </a>
                        </li>
                        <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                        <a href="/#" className="nav-link text-body p-0" id="iconNavbarSidenav">
                            <div className="sidenav-toggler-inner">
                            <i className="sidenav-toggler-line"></i>
                            <i className="sidenav-toggler-line"></i>
                            <i className="sidenav-toggler-line"></i>
                            </div>
                        </a>
                        </li>
                        <li className="nav-item px-3 d-flex align-items-center">
                        <a 
                            href="/#" 
                            className="nav-link text-body p-0"
                        >
                            <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
                        </a>
                        </li>
                        <li className="nav-item dropdown pe-2 d-flex align-items-center">
                        <a 
                            href="/#" 
                            className="nav-link text-body p-0" 
                            id="dropdownMenuButton" 
                            data-bs-toggle="dropdown" 
                            aria-expanded="false"
                        >
                            <i className="fa fa-bell cursor-pointer"></i>
                        </a>
                        <ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
                            <li className="mb-2">
                            <a className="dropdown-item border-radius-md" href="/#">
                                <div className="d-flex py-1">
                                <div className="my-auto">
                                    <img 
                                        alt=""
                                        src="../assets/img/team-2.jpg" 
                                        className="avatar avatar-sm  me-3 "
                                    />
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <h6 className="text-sm font-weight-normal mb-1">
                                    <span className="font-weight-bold">New message</span> from Laur
                                    </h6>
                                    <p className="text-xs text-secondary mb-0 ">
                                    <i className="fa fa-clock me-1"></i>
                                    13 minutes ago
                                    </p>
                                </div>
                                </div>
                            </a>
                            </li>
                            <li className="mb-2">
                            <a className="dropdown-item border-radius-md" href="/#">
                                <div className="d-flex py-1">
                                <div className="my-auto">
                                    <img 
                                        alt=""
                                        src="../assets/img/small-logos/logo-spotify.svg" 
                                        className="avatar avatar-sm bg-gradient-dark  me-3 "
                                    />
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <h6 className="text-sm font-weight-normal mb-1">
                                    <span className="font-weight-bold">New album</span> by Travis Scott
                                    </h6>
                                    <p className="text-xs text-secondary mb-0 ">
                                    <i className="fa fa-clock me-1"></i>
                                    1 day
                                    </p>
                                </div>
                                </div>
                            </a>
                            </li>
                            <li>
                            <a className="dropdown-item border-radius-md" href="/#">
                                <div className="d-flex py-1">
                                <div className="avatar avatar-sm bg-gradient-secondary  me-3  my-auto">
                                    <svg width="12px" height="12px" viewBox="0 0 43 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <title>credit-card</title>
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-2169.000000, -745.000000)" fill="#FFFFFF" fillRule="nonzero">
                                        <g transform="translate(1716.000000, 291.000000)">
                                            <g transform="translate(453.000000, 454.000000)">
                                            <path className="color-background" d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z" opacity="0.593633743"></path>
                                            <path className="color-background" d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"></path>
                                            </g>
                                        </g>
                                        </g>
                                    </g>
                                    </svg>
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <h6 className="text-sm font-weight-normal mb-1">
                                    Payment successfully completed
                                    </h6>
                                    <p className="text-xs text-secondary mb-0 ">
                                    <i className="fa fa-clock me-1"></i>
                                    2 days
                                    </p>
                                </div>
                                </div>
                            </a>
                            </li>
                        </ul>
                        </li>
                    </ul>
                    </div>
                </div>
                </nav> */}
                {/* End Navbar */}
                <div class="d-flex justify-content-between bd-highlight align-items-center namePhoneSearch mb-3">
                    <div class="p-2 bd-highlight">
                        <button 
                            type="button" 
                            class="btn btn-outline-info" 
                            data-bs-toggle="modal" 
                            data-bs-target="#exampleModalToCreateRoom"
                        >
                            Créer une Chambre
                        </button>
                    </div>
                    <div class="p-2 bd-highlighalign-items-centert d-flex align-items-center">
                        <select className="h3 form-select" value={totalNumberPerPage} onChange={handleRoomsPerPageChange}>
                            <option selected>Nombre de chambres</option>
                            <option value={5}>5</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>

                        </select>
                    </div>
                    <div class="p-2 bd-highlight">
                        <div class="ms-md-auto pe-md-3 d-flex align-items-center">
                            <div class="input-group">
                            <span class="input-group-text text-body"><i class="fas fa-search" aria-hidden="true"></i></span>
                            <input 
                                type="text" 
                                
                                class="form-control" 
                                placeholder="Numero Chambre"
                                onChange={handleSearchRooms}
                            />
                        </div>
                    </div>
                    </div>
                </div>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-4">
                                {loading &&  (
                                        <div class="d-flex justify-content-center align-item-center">
                                            <div class="spinner-border" role="status">
                                            <   span class="visually-hidden">Loading...</span>
                                            </div>
                                    </div>
                                )}
                                <div className="card-header pb-0">
                                    <h6>Liste des Chambres</h6>
                                </div>
                                <div className="card-body px-0 pt-0 pb-2">
                                    <div className="table-responsive p-0">
                                        <table className="table align-items-center mb-0">
                                            <thead>
                                                <tr>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Chambres</th>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Prix</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre de reservation courante</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Qualité</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Batiment</th>
                                                {/* <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Region</th> */}
                                                <th className="text-secondary opacity-7"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rooms.map(room => (
                                                    <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            {/* <div>
                                                                <img 
                                                                    src="../assets/img/team-2.jpg" 
                                                                    className="avatar avatar-sm me-3" 
                                                                    alt="user1"
                                                                />
                                                            </div> */}
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-sm">{room.number}</h6>
                                                                <p className="text-xs text-secondary mb-0">{room.price}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">{room.price}</p>
                                                        <p className="text-xs text-secondary mb-0">{room.quality}</p>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-success">{room.type}</span>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className={`badge badge-sm ${room.numberOfCurrentBooking < 1 ? "bg-gradient-success" : "bg-gradient-danger"}`}>{room.numberOfCurrentBooking}</span>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-success">{room.quality}</span>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-success">{room.location}</span>
                                                    </td>
                                                    <td className="align-middle">
                                                    
                                                        {/* <i 
                                                            class="fas fa-edit fa-2x" 
                                                            style={{marginRight: "32px"}} 
                                                            aria-hidden="true" 
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#updateRoomModal" 
                                                            onClick={() => {getRoomInformationsDetail(room.id)}}
                                                        />

                                                        <i 
                                                            style={{marginRight: "32px"}}
                                                            class="fa fa-trash editOrUpdate fa-2x" 
                                                            aria-hidden="true"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#staticBackdrop"
                                                            onClick={() => {setRoomUpdateOrDelete(room)}}
                                                        /> */}
                                                        <FontAwesomeIcon 
                                                            icon={faEdit} 
                                                            style={{marginRight: "32px"}}
                                                            size="2x" 
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#updateRoomModal" 
                                                            onClick={() => {getRoomInformationsDetail(room.id)}}
                                                        />
                                                        <FontAwesomeIcon 
                                                            icon={faTrash} 
                                                            style={{marginRight: "32px"}}
                                                            size="2x" 
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#staticBackdrop"
                                                            onClick={() => {setRoomUpdateOrDelete(room)}}
                                                        />                
                                                        {/* <button type="button" style={{
                                                            top: calc(100% - 10px),
                                                            position: "absolute",
                                                            marginRight: "15px",
                                                            left: "calc(100% - 200px)",
                                                        }} class="btn btn-success">Reservation</button> */}
                                                        {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Open modal for @mdo</button> */}
                                                    </td>
                                                        
                                                </tr>
                                                ))}
                                                {/* <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                        <div>
                                                            <img 
                                                                src="../assets/img/team-3.jpg" 
                                                                className="avatar avatar-sm me-3" 
                                                                alt="user2"
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <h6 className="mb-0 text-sm">Alexa Liras</h6>
                                                            <p className="text-xs text-secondary mb-0">alexa@creative-tim.com</p>
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">Programator</p>
                                                        <p className="text-xs text-secondary mb-0">Developer</p>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-secondary">Offline</span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <span className="text-secondary text-xs font-weight-bold">11/01/19</span>
                                                    </td>
                                                    <td className="align-middle">
                                                        <a href="/#" className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                        <i class="fas fa-edit"></i>
                                                        </a>
                                                    </td>
                                                </tr> */}
                                                {/* <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                        <div>
                                                            <img 
                                                                src="../assets/img/team-4.jpg" 
                                                                className="avatar avatar-sm me-3" 
                                                                alt="user3"
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <h6 className="mb-0 text-sm">Laurent Perrier</h6>
                                                            <p className="text-xs text-secondary mb-0">laurent@creative-tim.com</p>
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">Executive</p>
                                                        <p className="text-xs text-secondary mb-0">Projects</p>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-success">Online</span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <span className="text-secondary text-xs font-weight-bold">19/09/17</span>
                                                    </td>
                                                    <td className="align-middle">
                                                        <a href="/#" className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                            <i class="fas fa-edit" style={{width: "16px", height: "16px"}}></i>
                                                        </a>
                                                        <div>
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </div>
                                                    </td>
                                                </tr> */}
                                                {/* <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                        <div>
                                                            <img 
                                                                src="../assets/img/team-3.jpg" 
                                                                className="avatar avatar-sm me-3" 
                                                                alt="user4"
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <h6 className="mb-0 text-sm">Michael Levi</h6>
                                                            <p className="text-xs text-secondary mb-0">michael@creative-tim.com</p>
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">Programator</p>
                                                        <p className="text-xs text-secondary mb-0">Developer</p>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-success">Online</span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <span className="text-secondary text-xs font-weight-bold">24/12/08</span>
                                                    </td>
                                                    <td className="align-middle">
                                                        <a 
                                                            href="/#" 
                                                            className="text-secondary font-weight-bold text-xs" 
                                                            data-toggle="tooltip" 
                                                            data-original-title="Edit user"
                                                        >
                                                        <i class="fas fa-edit"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                        <div>
                                                            <img 
                                                                src="../assets/img/team-2.jpg" 
                                                                className="avatar avatar-sm me-3" 
                                                                alt="user5"
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <h6 className="mb-0 text-sm">Richard Gran</h6>
                                                            <p className="text-xs text-secondary mb-0">richard@creative-tim.com</p>
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">Manager</p>
                                                        <p className="text-xs text-secondary mb-0">Executive</p>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-secondary">Offline</span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <span className="text-secondary text-xs font-weight-bold">04/10/21</span>
                                                    </td>
                                                    <td className="align-middle">
                                                        <a href={"javascript"} className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                        <i class="fas fa-edit"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                        <div>
                                                            <img 
                                                                src="../assets/img/team-4.jpg" 
                                                                className="avatar avatar-sm me-3" 
                                                                alt="user6"
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <h6 className="mb-0 text-sm">Miriam Eric</h6>
                                                            <p className="text-xs text-secondary mb-0">miriam@creative-tim.com</p>
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">Programtor</p>
                                                        <p className="text-xs text-secondary mb-0">Developer</p>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-secondary">Offline</span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <span className="text-secondary text-xs font-weight-bold">14/09/20</span>
                                                    </td>
                                                    <td className="align-middle">
                                                        <a href={"javascript"} className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                        <i class="fas fa-edit"></i>
                                                        </a>
                                                    </td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                        
                                    </div>
                                </div>
                            </div>
                            {/* <Pagination
                                itemRender={itemRender}
                                defaultCurrent={currentPage}
                                onChange={handleChangeCurrentPage}
                                //onChange={(page, pageSize) => handlePaginationChange(page, pageSize)}
                                total={totalPage} 
                            />*/}
                            <ReactPaginate
                                key={currentPage}
                                initialPage={currentPage}
                                forcePage={currentPage}
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                pageCount={totalNumberOfPage}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handleChangeCurrentPage}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeLinkClassName={"active"}
                            />
                        </div>    
                    </div>
                </div>
            </main>
            <div className="fixed-plugin">
                {/* <a 
                    href="/#"
                    className="fixed-plugin-button text-dark position-fixed px-3 py-2"
                >
                <i className="fa fa-cog py-2"> </i>
                </a> */}
                <div className="card shadow-lg ">
                    <div className="card-header pb-0 pt-3 ">
                        <div className="float-start">
                            <h5 className="mt-3 mb-0">Soft UI Configurator</h5>
                            <p>See our dashboard options.</p>
                        </div>
                        <div className="float-end mt-4">
                            <button className="btn btn-link text-dark p-0 fixed-plugin-close-button">
                                <i className="fa fa-close"></i>
                            </button>
                        </div>
                        {/* End Toggle Button */}
                    </div>
                    <hr className="horizontal dark my-1"/>
                    <div className="card-body pt-sm-3 pt-0">
                        {/*Sidebar Backgrounds */}
                        <div>
                        <h6 className="mb-0">Sidebar Colors</h6>
                        </div>
                        <a href={"javascript"} className="switch-trigger background-color">
                        <div className="badge-colors my-2 text-start">
                            <span className="badge filter bg-gradient-primary active" data-color="primary" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-dark" data-color="dark" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-info" data-color="info" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-success" data-color="success" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-warning" data-color="warning" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-danger" data-color="danger" onclick="sidebarColor(this)"></span>
                        </div>
                        </a>
                        {/*Sidenav Type*/}
                        <div className="mt-3">
                        <h6 className="mb-0">Sidenav Type</h6>
                        <p className="text-sm">Choose between 2 different sidenav types.</p>
                        </div>
                        <div className="d-flex">
                        <button className="btn bg-gradient-primary w-100 px-3 mb-2 active" data-className="bg-transparent" onclick="sidebarType(this)">Transparent</button>
                        {/* <button className="btn bg-gradient-primary w-100 px-3 mb-2 ms-2" data-className="bg-white" onclick="sidebarType(this)">White</button> */}
                        </div>
                        <p className="text-sm d-xl-none d-block mt-2">You can change the sidenav type just on desktop view.</p>
                        {/* Navbar Fixed */}
                        <div className="mt-3">
                        <h6 className="mb-0">Navbar Fixed</h6>
                        </div>
                        <div className="form-check form-switch ps-0">
                        <input 
                            className="form-check-input mt-1 ms-auto" 
                            type="checkbox" 
                            id="navbarFixed" 
                            onclick="navbarFixed(this)"
                        />
                        </div>
                        <hr className="horizontal dark my-sm-4" />
                        <a className="btn bg-gradient-dark w-100" href="https://www.creative-tim.com/product/soft-ui-dashboard">Free Download</a>
                        <a className="btn btn-outline-dark w-100" href="https://www.creative-tim.com/learning-lab/bootstrap/license/soft-ui-dashboard">View documentation</a>
                        <div className="w-100 text-center">
                        <a className="github-button" href="https://github.com/creativetimofficial/soft-ui-dashboard" data-icon="octicon-star" data-size="large" data-show-count="true" aria-label="Star creativetimofficial/soft-ui-dashboard on GitHub">Star</a>
                        <h6 className="mt-3">Thank you for sharing!</h6>
                        <a 
                            href="https://twitter.com/intent/tweet?text=Check%20Soft%20UI%20Dashboard%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%23bootstrap5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fsoft-ui-dashboard" 
                            className="btn btn-dark mb-0 me-2"
                        >
                            <i className="fab fa-twitter me-1" aria-hidden="true"></i> Tweet
                        </a>
                        <a 
                            href={"https://www.facebook.com/sharer/sharer.php"} 
                            className="btn btn-dark mb-0 me-2"
                        >
                            <i className="fab fa-facebook-square me-1" aria-hidden="true" />Share
                        </a>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade modal-lg" id="exampleModalToCreateRoom" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Créer une chambre</h5>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                        <Formik
                            initialValues={{ 
                                number: "",
                                description: "",
                                price: "",
                                location: "",
                                quality: "",
                                type: "",
                            }}
                            onSubmit={handleSubmitToCreateARoom}
                            // onSubmit={handleSubmit}
                            validationSchema={Yup.object().shape({
                                number: Yup.string()
                                    .required("Le numéro de la chambre est obligatoire"),   
                                price: Yup.number()
                                    .required("Le prix de la chambre est obligatoire"),   
                                type: Yup.string()
                                    .oneOf(["ROOM", "HALL"])
                                    .required("Choisir le type de chambre"),
                                quality: Yup.string()
                                    .oneOf(["SIMPLE","MEDIUM","HIGH"])
                                    .required("La qualité de la chambre est obligatoire"),
                                location: Yup.string()
                                    .required("La chambre se trouve dans quel batiment ?"),
                                description: Yup.string() 
                            })}
                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    dirty,
                                    isSubmitting,
                                    setFieldValue,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit: handleSubmitToCreateARoom,
                                    handleReset
                                } = props;
                                return (
                                    
                                    <form onSubmit={handleSubmitToCreateARoom}>
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="number" 
                                                    className="col-form-label"
                                                >
                                                    Nom de la chambre *:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text" 
                                                    id="number" 
                                                    name="number"
                                                    placeholder="Chambre-1"
                                                    value={values.number} 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.number && touched.number
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label for="price" class="col-form-label">Prix *:</label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    id="price"
                                                    value={values.price} 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.price && touched.price
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.price && touched.price && (
                                            <div className="input-feedback">{errors.price}</div>
                                        )}
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="quality" 
                                                    className="col-form-label"
                                                >
                                                    Qualité *:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <select 
                                                    defaultValue="MEDIUM"
                                                    id="quality"
                                                    name="quality"
                                                    value={values.quality} 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.quality && touched.quality
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }`}
                                                    class="form-select" 
                                                    aria-label="Default select example">
                                                    <option value="">Veuillez choisir la qualité de chambre</option>
                                                    <option value="SIMPLE">SIMPLE</option>
                                                    <option value="MEDIUM">MOYENNE</option>
                                                    <option value="HIGH">ELEVE</option>
                                                </select>
                                            </div>
                                        </div>
                                        {errors.quality && touched.quality && (
                                            <div className="input-feedback">{errors.quality}</div>
                                        )}
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="type" 
                                                    className="col-form-label"
                                                >
                                                    Type *:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <select 
                                                    defaultValue="ROOM"
                                                    id="type"
                                                    name="type"
                                                    value={values.type} 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.type && touched.type
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }`}
                                                    aria-label="Default select example">
                                                    <option value="">Veuillez Choisir le type de chambre</option>
                                                    <option value="ROOM">CHAMBRE</option>
                                                    <option value="HALL">SALLE</option>
                                                </select>
                                            </div>
                                        </div>
                                        {errors.type && touched.type && (
                                            <div className="input-feedback">{errors.type}</div>
                                        )}
                                        
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="location" 
                                                    className="col-form-label"
                                                >
                                                    Quel batiment *:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text"  
                                                    id="location" 
                                                    name="location"
                                                    value={values.location} 
                                                    placeholder="Batiment A"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.location && touched.location
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.location && touched.location && (
                                            <div className="input-feedback">{errors.location}</div>
                                        )}
                                        <div class="row">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="description" 
                                                    className="col-form-label"
                                                >
                                                    Description:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <textarea 
                                                    type="text"
                                                    id="description" 
                                                    name="description"
                                                    value={values.description} 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.description && touched.description
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button 
                                                type="button" 
                                                ref={closeModalCreateRoom}
                                                className="btn btn-secondary" 
                                                data-bs-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                            <button 
                                                type="submit" 
                                                className="btn btn-primary"
                                            >
                                                Creer une chambre
                                            </button>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                        </div>
                        {/* <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Creer une chambre</button>
                        </div> */}
                    </div>
                </div>
            </div>


            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Supprimer La chambre  {roomUpdateOrDelete?.number }</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    <div class="modal-body">
                        Etes-vous sur de vouloir supprimer {roomUpdateOrDelete?.number }
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" ref={closeModalToDelete}>Non</button>
                        <button type="button" class="btn btn-primary" onClick={() => roomADelete(roomUpdateOrDelete.id)}>Oui</button>
                    </div>
                    </div>
                </div>
            </div>

            
            <div class="modal fade modal-lg" id="updateRoomModal" tabindex="-1" role="dialog" aria-labelledby="updateRoomModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Modifier une chambre</h5>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{ 
                                number: roomUpdateOrDelete?.number,
                                description: roomUpdateOrDelete?.description,
                                price: roomUpdateOrDelete?.price,
                                location: roomUpdateOrDelete?.location,
                                quality: roomUpdateOrDelete?.quality,
                                type: roomUpdateOrDelete?.type,
                            }}
                            onSubmit={handleSubmitToUpdateARoom}
                            // onSubmit={handleSubmit}
                            validationSchema={Yup.object().shape({
                                number: Yup.string()
                                    .required("Le numéro de la chambre est obligatoire"),   
                                price: Yup.number()
                                    .required("Le prix de la chambre est obligatoire"),   
                                type: Yup.string()
                                    .oneOf(["ROOM", "HALL"])
                                    .required("Choisir le type de chambre"),
                                quality: Yup.string()
                                    .oneOf(["SIMPLE","MEDIUM","HIGH"])
                                    .required("La qualité de la chambre est obligatoire"),
                                location: Yup.string()
                                    .required("La chambre se trouve dans quel batiment ?")   
                            })}
                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    dirty,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit: handleSubmitToUpdateARoom,
                                    handleReset
                                } = props;
                                return (
                                    
                                    <form onSubmit={handleSubmitToUpdateARoom}>
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="nameRoom" 
                                                    className="col-form-label"
                                                >
                                                    Nom de la chambre:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text" 
                                                    id="number" 
                                                    name="number"
                                                    value={values.number}
                                                    placeholder="Chambre-1"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.number && touched.number
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label for="price" class="col-form-label">Prix:</label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    id="price"
                                                    value={values.price} 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.price && touched.price
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.price && touched.price && (
                                            <div className="input-feedback">{errors.price}</div>
                                        )}
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="quality" 
                                                    className="col-form-label"
                                                >
                                                    Qualité
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <select 
                                                    defaultValue="MEDIUM"
                                                    id="quality"
                                                    name="quality"
                                                    value={values.quality}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.quality && touched.quality
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }`}
                                                    class="form-select" 
                                                    aria-label="Default select example">
                                                    <option value="SIMPLE">SIMPLE</option>
                                                    <option value="MEDIUM">MOYENNE</option>
                                                    <option value="HIGH">ELEVE</option>
                                                </select>
                                            </div>
                                        </div>
                                        {errors.quality && touched.quality && (
                                            <div className="input-feedback">{errors.quality}</div>
                                        )}
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="type" 
                                                    className="col-form-label"
                                                >
                                                    Type
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <select 
                                                    defaultValue="ROOM"
                                                    id="type"
                                                    name="type"
                                                    value={values.type}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.type && touched.type
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }`}
                                                    aria-label="Default select example">
                                                    <option value="ROOM">CHAMBRE</option>
                                                    <option value="HALL">SALLE</option>
                                                </select>
                                            </div>
                                        </div>
                                        {errors.type && touched.type && (
                                            <div className="input-feedback">{errors.type}</div>
                                        )}
                                        
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="location" 
                                                    className="col-form-label"
                                                >
                                                    Quel batiment:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text"  
                                                    id="location" 
                                                    name="location"
                                                    value={values.location}
                                                    placeholder="Batiment A"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.location && touched.location
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.location && touched.location && (
                                            <div className="input-feedback">{errors.location}</div>
                                        )}
                                        <div class="row">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="description" 
                                                    className="col-form-label"
                                                >
                                                    Description:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <textarea 
                                                    type="text"
                                                    id="description" 
                                                    name="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.description && touched.description
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button 
                                                type="button" 
                                                ref={closeModalToUpdate}
                                                className="btn btn-secondary" 
                                                data-bs-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                            <button 
                                                type="submit" 
                                                className="btn btn-primary"
                                            >
                                                Modifier une chambre
                                            </button>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                        </div>
                        {/* <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Creer une chambre</button>
                        </div> */}
                    </div>
                </div>
            </div>
            
            <div class="modal fade" id="staticChangePasswordBackdrop" tabindex="-1" aria-labelledby="staticChangePasswordBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="paymentModalLabel">Paiement</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <Formik
                                initialValues={{
                                    currentPassword: '',
                                    newPassword: ''
                                }}
                                validationSchema={PasswordChangeSchema}
                                validateOnChange={true}
                                validateOnBlur={false}
                                onSubmit={changePassword}
                            >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit: changePassword }) => (
                                    <form onSubmit={changePassword}>
                                        <div className="mb-3">
                                            <label htmlFor="amount" className="form-label">
                                                Mot de passe actuel
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.currentPassword && touched.currentPassword ? 'is-invalid' : ''}`}
                                                id="currentPassword"
                                                name="currentPassword"
                                                value={values.currentPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.currentPassword && touched.currentPassword && (
                                                <div className="invalid-feedback">{errors.currentPassword}</div>
                                            )}
                                        </div>
                                        <div>
                                        <label htmlFor="newPassword" className="form-label">
                                                Nouveau password
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.newPassword && touched.newPassword ? 'is-invalid' : ''}`}
                                                id="newPassword"
                                                name="newPassword"
                                                value={values.newPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.newPassword && touched.newPassword && (
                                                <div className="invalid-feedback">{errors.newPassword}</div>
                                            )}
                                        </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                            Fermer
                                        </button>
                                        <button type="submit" className="btn btn-primary">
                                            Modifier
                                        </button>
                                    </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Rooms;