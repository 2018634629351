import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment';
import 'moment/locale/fr';
//import { fr } from 'date-fns/locale';
//import { format, parseISO } from 'date-fns';

const styles = StyleSheet.create({
    page: {
        padding: 30,
    },
    rightAlign: {
        alignSelf: 'flex-end',
        marginBottom: 10,
        fontSize: 12,
    },
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    info: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 2,

    },
    column: {
        width: '48%',
    },
    title: {
        fontSize: 15,
        textAlign: 'center',
        marginTop: 20,
    },
    logo: {
        width: 100,
        height: 90,
        marginBottom: 10,
        alignSelf: 'center',
    },
    text: {
       fontSize: 12,
       marginBottom: 5, 
    },
    invoiceTitle: {
        fontSize: 24,
        textAlign: 'right'
    },
    fiedsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    field: {
        fontSize: 14,
        marginVertical: 5,
        marginTop: 5
    },
    fieldRight: {
        textAlign: 'right',
        fontSize: 12,
        marginBottom: 5,
    }, 
    fiedLeft: {
        fontSize: 12,
        marginBottom: 2,
    },
    table: {
        display: 'table',
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginTop: 5
    },
    tableRow: {
        flexDirection: 'row'
    },
    tableCol: {
        width: '25%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: 'auto',
        marginTop: 5,
        fontSize: 12
    },
    blockWithMarginTop: {
        marginTop: 20, // Ajoutez la marge supérieure souhaitée ici
    },
});

// const formatDate = (dateString) => {
//     const date = parseISO(dateString);
//     return format(date, "dd-MM-yyyy ' ' HH'h'");
// };

// Configuration de la locale française
//moment.locale('fr');

// const formatDate = (dateString) => {
//   return moment(dateString).format("DD-MM-YYYY à HH[h]");
// };

const formatDate = (dateString) => {
    moment.locale('fr');
    return moment(dateString).format("D MMMM YYYY à HH[h]");
};

const formatDateWithSecond = (dateString) => {
    moment.locale('fr');
    const date = moment(dateString);
    return date.format("DD-MM-YYYY [à] HH[h] mm [min] ss [s]");
  };


const Invoice = ({ reservation }) => {
    //console.log('Je vois le resultat du format =>',format("2024-07-19T00:00:00.000+00:00"))
    console.log(`Invoice reservation ===> `, reservation)
    // const startAt = formatDate(reservation?.startAt)
    // const endAt = formatDate(reservation?.endAt)
   return ( 
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={{marginBottom: "18px"}}>
                    <Image style={styles.logo} src="/images.png"/>
                    <Text style={styles.info}>Tel: +237 {process.env.REACT_APP_PHONE}</Text>
                    <Text style={styles.info}>Email: {process.env.REACT_APP_EMAIL}</Text>
                    <Text style={styles.info}>{process.env.REACT_APP_CITY}</Text>
                </View>
                
                {/* <View style={styles.container}>
                    <View style={styles.column}>
                        <Text style={styles.title}>{process.env.REACT_APP_HOTEL_NAME}</Text> 
                        <Image style={styles.logo} src="/images.png" />
                        <Text style={styles.text}>Email: {process.env.REACT_APP_EMAIL}</Text> 
                        <Text style={styles.text}>Telephone {process.env.REACT_APP_PHONE}</Text> 
                        <Text style={styles.text}>Telephone {process.env.REACT_APP_PHONE}</Text> 

                    </View>
                    <View style={styles.column}>
                        <Text style={styles.title}>Facture</Text>  
                    </View>
                </View> */}
                <View style={styles.blockWithMarginTop}>
                    <Text style={styles.field}>Facture:    {reservation?.reference}</Text>
                    <Text style={styles.field}>Nom :   {reservation?.customer?.firstName}  {reservation?.customer?.lastName}</Text>
                    <Text style={styles.field}>Téléphone :  {reservation?.customer?.phoneNumber}</Text>
                    <Text style={styles.rightAlign}>Date:   {formatDateWithSecond(reservation?.createdAt)}</Text>
                </View>
                {/* <Text style={styles.fieldRight}>Date</Text> */}
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Numéro de chambre</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Date d'arrivée</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Date de départ</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Nombre de nuitées</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Prix unitaire</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Montant payé</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Montant restant</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Prix total</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{reservation?.room.number}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{formatDate(reservation?.startAt)}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{formatDate(reservation?.endAt)}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{reservation?.numberOfDays}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{Math.ceil(reservation?.actualPrice / reservation?.numberOfDays)}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{reservation?.totalPaidAmount}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{reservation?.remainingAmount}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{reservation?.actualPrice}</Text>
                        </View>
                    </View>
                </View>
                <View style={{
                        alignSelf: 'flex-end',
                        marginBottom: 20,
                        marginTop: 5,
                        fontSize: 12,
                    }}
                >
                    <Text>Signature du receptionniste</Text>
                </View>
                {/* <View style={{
                        alignSelf: 'flex-end',
                        marginBottom: 17,
                        marginTop: 12,
                        fontSize: 12,
                    }}
                >
                    <Text>Téléphone client:  {reservation?.customer?.phoneNumber}</Text>
                </View> */}
                {/* <Text style={styles.text}>Arrêté la présente facture à la somme de _________{reservation?.totalPaidAmount}_______</Text> */}
                <Text style={styles.title}>L'HOTEL MBANYA vous remercie pour votre séjour</Text> 
            </Page>
        </Document>
   )
};

export default Invoice;
