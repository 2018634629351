import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer';
import moment from 'moment';
import 'moment/locale/fr';

//Styles for the PDF document
const styles = StyleSheet.create({
    logo: {
        width: 100,
        height: 90,
        alignSelf: 'center',
        marginBottom: 10,
    },
    info: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 2,

    },
    rightAlign: {
        alignSelf: 'flex-end',
        marginBottom: 10,
        fontSize: 12,
    },
    field: {
        fontSize: 14,
        marginVertical: 5,
        marginTop: 5
    },
    page: {
        flexDirection: 'column',
        padding: 30
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableCol: {
      width: '12.5%', // Divise l'espace en 7 colonnes égales
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      padding: 5,
    },
    tableCellHeader: {
      margin: 5,
      fontSize: 10,
      fontWeight: 'bold',
    },
    tableCell: {
      margin: 5,
      fontSize: 9,
    },
});

//moment.locale('fr');

// const formatDate = (dateString) => {
//     return moment(dateString, 'DD-MM-YYYY HH').format('D MMMM YYYY [à] HH[h]');
// };

const formatDate = (dateString) => {
    moment.locale('fr');
    return moment(dateString).format("D MMMM YYYY à HH[h]");
  };

  const formatDateWihoutHour = (dateString) => {
    moment.locale('fr');
    return moment(dateString).format("D MMMM YYYY");
  };

//console.log('Voir la date formaté ==>', formatDate("2024-07-28T17:00:00.000Z"))
export const GlobalPayment = ({ paymentGlobal }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            {/* <Text style={styles.title}>{process.env.REACT_APP_HOTEL_NAME}</Text> */}
            <View style={{marginBottom: "18px"}}>
                <Image style={styles.logo} src="/images.png"/>
                <Text style={styles.info}>Tel: (+237) {process.env.REACT_APP_PHONE}</Text>
                <Text style={styles.info}>Email: {process.env.REACT_APP_EMAIL}</Text>
                <Text style={styles.info}>{process.env.REACT_APP_CITY}</Text>
            </View>
            
            <View>
                <Text style={{textAlign: "center", marginRight: 6, fontSize: 17, marginBottom: 4}}>Bilan Global</Text>
            </View>
            <View>
                <Text style={styles.rightAlign}>Date:   {formatDate(paymentGlobal?.printDate)}</Text>
            </View>
            <View style={{marginBottom: "18px"}}>
                <Text style={styles.field} >Date de début:    {formatDateWihoutHour(paymentGlobal?.startDate)}  </Text>
                <Text style={styles.field}>Date de fin:  {formatDateWihoutHour(paymentGlobal?.endDate)}  </Text>
                <Text style={styles.field}>Montant total:   {paymentGlobal?.totalPaidAmount}  FCFA</Text>
                <Text style={styles.field}>Montant restant:   {paymentGlobal?.totalRemainingAmount}  FCFA</Text>
                <Text style={styles.field}>Nombre de paiements:   {paymentGlobal.totalNumberOfBookings} </Text>
            </View>

            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCellHeader}>Numéro</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCellHeader}>Nom</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCellHeader}>Téléphone</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCellHeader}>Pays</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCellHeader}>Sexe</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCellHeader}>Numéro de chambre</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCellHeader}>Nombre de jours</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCellHeader}>Paiements</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCellHeader}>Reservation</Text>
                    </View>
                </View>


                {paymentGlobal.bookings.map((booking, index) => (
                    <View key={index} style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{index + 1}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{booking.customerFullName}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{booking.customerPhoneNumber}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{booking.customerCountry}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{booking.customerGender}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{booking.roomNumber}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{booking.numberOfDays}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Montant payé: {booking.paidAmount}</Text>
                            <Text style={styles.tableCell}>Montant total: {booking.totalAmount}</Text>
                            <Text style={styles.tableCell}>Montant restant: {booking.remainingAmount}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Début: {formatDate(booking.startDate)}</Text>
                            <Text style={styles.tableCell}>Fin: {formatDate(booking.endDate)}</Text>
                        </View>
                    </View>
                ))}
            </View>
        </Page>
    </Document>
)