import {POST, sGET,  sPOST, sPUT,  sDELETE, sPATCH} from '../http/http';
import backendUrls from '../urls/url';

const StatisticsService = {
  dailyPayment: async function() {
    const url = backendUrls.Statistics.DailyPaymentSummary;
    //console.log(`url detail du user ===>> ${url}`)
    const result = await sGET(url).then((r) => 
      r.json())
    return result;
  },
  globalPayment: async function(data) {
    const url = backendUrls.Statistics.globalPaymentSummary;
    const result = await sPOST(url, data).then((r) =>
      r.json()
    )
    return result;
  }
}

export default StatisticsService