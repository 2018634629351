import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { getRole, logout } from '../auth/auth';
import { Formik } from 'formik';
import * as Yup from "yup";
import { Link, NavLink } from 'react-router-dom';
import UserService from '../services/userService';
import { toast } from 'react-toastify';


const PasswordChangeSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Mot de passe actuel est requis'),
    newPassword: Yup.string()
      .min(6, 'Le nouveau mot de passe doit comporter au moins 6 caractères')
      .required('Nouveau mot de passe est requis'),
});


function Sidebar() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const closeModalToChangePassword = useRef(null)

    // const changePassword = async (values) => {
    //     alert(values);
    //     const response = await UserService.updatePassword(values);
    // }
    const changePassword = async (values, {resetForm}) => {
        //alert(values)
        try {
            setLoading(true)
            const response = await UserService.updatePassword(values);
            if (response.errorResponse) {
                toast.error(response.message)
                //closeModalToChangePassword.current.click()
            } else {
                toast.success('Votre Mot de passe a été modifié avec succès')
                console.log('fermer => ',closeModalToChangePassword.current)
                closeModalToChangePassword.current.click()
                resetForm()
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la modification du mot de passe")
            
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 " id="sidenav-main">
                <div className="sidenav-header d-flex justify-content-center position-relative z-index-0">
                    <div className='position-absolute'>
                        <img 
                            src="/images-removebg-preview.png" 
                            style={{minHeight: "100px", minWidth: "100px"}}
                            className="navbar-brand-img h-100" 
                            alt="main_logo" 
                        />
                    </div>
                    
                    {/* <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i> */}
                    {/* <a className="navbar-brand m-0" href=" https://demos.creative-tim.com/soft-ui-dashboard/pages/dashboard.html " target="_blank"> */}
                        {/* <img src="/images.png" className="navbar-brand-img h-100" alt="main_logo" /> */}
                        {/* <span className="ms-1 font-weight-bold">Soft UI Dashboard</span> */}
                    {/* </a> */}
                </div>
                <hr className="horizontal dark mt-0"/>
                <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink 
                                className={({ isActive }) => isActive ? "nav-link active" : "nav-link"
                                } 
                                to="/rooms"
                            >
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                <i class="fa-solid fa-bed"></i>
                                {/* <svg width="12px" height="12px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <title>office</title>
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g transform="translate(-1869.000000, -293.000000)" fill="#FFFFFF" fillRule="nonzero">
                                        <g transform="translate(1716.000000, 291.000000)">
                                        <g id="office" transform="translate(153.000000, 2.000000)">
                                            <path className="color-background opacity-6" d="M12.25,17.5 L8.75,17.5 L8.75,1.75 C8.75,0.78225 9.53225,0 10.5,0 L31.5,0 C32.46775,0 33.25,0.78225 33.25,1.75 L33.25,12.25 L29.75,12.25 L29.75,3.5 L12.25,3.5 L12.25,17.5 Z"></path>
                                            <path className="color-background" d="M40.25,14 L24.5,14 C23.53225,14 22.75,14.78225 22.75,15.75 L22.75,38.5 L19.25,38.5 L19.25,22.75 C19.25,21.78225 18.46775,21 17.5,21 L1.75,21 C0.78225,21 0,21.78225 0,22.75 L0,40.25 C0,41.21775 0.78225,42 1.75,42 L40.25,42 C41.21775,42 42,41.21775 42,40.25 L42,15.75 C42,14.78225 41.21775,14 40.25,14 Z M12.25,36.75 L7,36.75 L7,33.25 L12.25,33.25 L12.25,36.75 Z M12.25,29.75 L7,29.75 L7,26.25 L12.25,26.25 L12.25,29.75 Z M35,36.75 L29.75,36.75 L29.75,33.25 L35,33.25 L35,36.75 Z M35,29.75 L29.75,29.75 L29.75,26.25 L35,26.25 L35,29.75 Z M35,22.75 L29.75,22.75 L29.75,19.25 L35,19.25 L35,22.75 Z"></path>
                                        </g>
                                        </g>
                                    </g>
                                    </g>
                                </svg> */}
                                </div>
                                <span className="nav-link-text ms-1">Chambres</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink 
                                className={({ isActive }) => isActive ? "nav-link active" : "nav-link"
                                } 
                                to="/customer"
                            >
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="fa fa-user" aria-hidden="true"></i>
                                {/* <svg width="12px" height="12px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <title>office</title>
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g transform="translate(-1869.000000, -293.000000)" fill="#FFFFFF" fillRule="nonzero">
                                        <g transform="translate(1716.000000, 291.000000)">
                                        <g id="office" transform="translate(153.000000, 2.000000)">
                                            <path className="color-background opacity-6" d="M12.25,17.5 L8.75,17.5 L8.75,1.75 C8.75,0.78225 9.53225,0 10.5,0 L31.5,0 C32.46775,0 33.25,0.78225 33.25,1.75 L33.25,12.25 L29.75,12.25 L29.75,3.5 L12.25,3.5 L12.25,17.5 Z"></path>
                                            <path className="color-background" d="M40.25,14 L24.5,14 C23.53225,14 22.75,14.78225 22.75,15.75 L22.75,38.5 L19.25,38.5 L19.25,22.75 C19.25,21.78225 18.46775,21 17.5,21 L1.75,21 C0.78225,21 0,21.78225 0,22.75 L0,40.25 C0,41.21775 0.78225,42 1.75,42 L40.25,42 C41.21775,42 42,41.21775 42,40.25 L42,15.75 C42,14.78225 41.21775,14 40.25,14 Z M12.25,36.75 L7,36.75 L7,33.25 L12.25,33.25 L12.25,36.75 Z M12.25,29.75 L7,29.75 L7,26.25 L12.25,26.25 L12.25,29.75 Z M35,36.75 L29.75,36.75 L29.75,33.25 L35,33.25 L35,36.75 Z M35,29.75 L29.75,29.75 L29.75,26.25 L35,26.25 L35,29.75 Z M35,22.75 L29.75,22.75 L29.75,19.25 L35,19.25 L35,22.75 Z"></path>
                                        </g>
                                        </g>
                                    </g>
                                    </g>
                                </svg> */}
                                </div>
                                <span className="nav-link-text ms-1">Clients</span>
                            </NavLink>
                        </li>
                        {getRole() == "ROLE_ADMIN" && (
                            <li className="nav-item">
                                <NavLink 
                                    className={({ isActive }) => isActive ? "nav-link active" : "nav-link"
                                    } 
                                    to="/workers"
                                >
                                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <i class="fa fa-user" aria-hidden="true"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">Employé</span>
                                </NavLink>
                            </li>
                        )}
                        <li className="nav-item">
                            <NavLink 
                                className={({ isActive }) => isActive ? "nav-link active" : "nav-link"
                                }  
                                to="/bookings"
                            >
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="fa fa-ticket" aria-hidden="true"></i>
                                </div>
                                <span className="nav-link-text ms-1"> Reservation</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink 
                                className={({ isActive }) => isActive ? "nav-link active" : "nav-link"
                                } 
                                to="/create-a-booking">
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="fa fa-ticket" aria-hidden="true"></i>
                                </div>
                                <span className="nav-link-text ms-1"> Créer une reservation</span>
                            </NavLink>
                        </li>
                        {/* <li className="nav-item mt-3">
                            <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Account pages</h6>
                        </li> */}
                        <li className="nav-item">
                            {/* <a className="nav-link  " href="../pages/sign-up.html">
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                <svg width="12px" height="20px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <title>spaceship</title>
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g transform="translate(-1720.000000, -592.000000)" fill="#FFFFFF" fillRule="nonzero">
                                        <g transform="translate(1716.000000, 291.000000)">
                                        <g transform="translate(4.000000, 301.000000)">
                                            <path className="color-background" d="M39.3,0.706666667 C38.9660984,0.370464027 38.5048767,0.192278529 38.0316667,0.216666667 C14.6516667,1.43666667 6.015,22.2633333 5.93166667,22.4733333 C5.68236407,23.0926189 5.82664679,23.8009159 6.29833333,24.2733333 L15.7266667,33.7016667 C16.2013871,34.1756798 16.9140329,34.3188658 17.535,34.065 C17.7433333,33.98 38.4583333,25.2466667 39.7816667,1.97666667 C39.8087196,1.50414529 39.6335979,1.04240574 39.3,0.706666667 Z M25.69,19.0233333 C24.7367525,19.9768687 23.3029475,20.2622391 22.0572426,19.7463614 C20.8115377,19.2304837 19.9992882,18.0149658 19.9992882,16.6666667 C19.9992882,15.3183676 20.8115377,14.1028496 22.0572426,13.5869719 C23.3029475,13.0710943 24.7367525,13.3564646 25.69,14.31 C26.9912731,15.6116662 26.9912731,17.7216672 25.69,19.0233333 L25.69,19.0233333 Z"></path>
                                            <path className="color-background opacity-6" d="M1.855,31.4066667 C3.05106558,30.2024182 4.79973884,29.7296005 6.43969145,30.1670277 C8.07964407,30.6044549 9.36054508,31.8853559 9.7979723,33.5253085 C10.2353995,35.1652612 9.76258177,36.9139344 8.55833333,38.11 C6.70666667,39.9616667 0,40 0,40 C0,40 0,33.2566667 1.855,31.4066667 Z"></path>
                                            <path className="color-background opacity-6" d="M17.2616667,3.90166667 C12.4943643,3.07192755 7.62174065,4.61673894 4.20333333,8.04166667 C3.31200265,8.94126033 2.53706177,9.94913142 1.89666667,11.0416667 C1.5109569,11.6966059 1.61721591,12.5295394 2.155,13.0666667 L5.47,16.3833333 C8.55036617,11.4946947 12.5559074,7.25476565 17.2616667,3.90166667 L17.2616667,3.90166667 Z"></path>
                                            <path className="color-background opacity-6" d="M36.0983333,22.7383333 C36.9280725,27.5056357 35.3832611,32.3782594 31.9583333,35.7966667 C31.0587397,36.6879974 30.0508686,37.4629382 28.9583333,38.1033333 C28.3033941,38.4890431 27.4704606,38.3827841 26.9333333,37.845 L23.6166667,34.53 C28.5053053,31.4496338 32.7452344,27.4440926 36.0983333,22.7383333 L36.0983333,22.7383333 Z"></path>
                                        </g>
                                        </g>
                                    </g>
                                    </g>
                                </svg>
                                </div>
                                <span className="nav-link-text ms-1">Sign Up</span>
                            </a> */}
                        </li>
                        <div className="d-flex align-items-center justify-content-center">
                            <button 
                                    className="nav-link-text ms-1"
                                    onClick={() => {logout(); navigate('/login')}}    
                                >
                                Déconnecter
                            </button>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <button 
                                className="nav-link-text ms-1"
                                data-bs-toggle="modal" 
                                data-bs-target="#staticChangePasswordBackdrop"
                                //onClick={() => {logout(); navigate('/login')}}    
                            >
                                Changer mot de passer
                            </button>
                        </div>
                    </ul>
                </div>
            </aside>

            <div class="modal fade" id="staticChangePasswordBackdrop" tabindex="-1" aria-labelledby="staticChangePasswordBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="paymentModalLabel">Modifier le mot de passe</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <Formik
                                initialValues={{
                                    currentPassword: '',
                                    newPassword: ''
                                }}
                                validationSchema={PasswordChangeSchema}
                                validateOnChange={true}
                                validateOnBlur={false}
                                onSubmit={changePassword}
                            >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit: changePassword }) => (
                                    <form onSubmit={changePassword}>
                                        <div className="mb-3">
                                            <label htmlFor="amount" className="form-label">
                                                Mot de passe actuel
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.currentPassword && touched.currentPassword ? 'is-invalid' : ''}`}
                                                id="currentPassword"
                                                name="currentPassword"
                                                value={values.currentPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.currentPassword && touched.currentPassword && (
                                                <div className="invalid-feedback">{errors.currentPassword}</div>
                                            )}
                                        </div>
                                        <div>
                                        <label htmlFor="newPassword" className="form-label">
                                                Nouveau password
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.newPassword && touched.newPassword ? 'is-invalid' : ''}`}
                                                id="newPassword"
                                                name="newPassword"
                                                value={values.newPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.newPassword && touched.newPassword && (
                                                <div className="invalid-feedback">{errors.newPassword}</div>
                                            )}
                                        </div>
                                    <div className="modal-footer">
                                        <button 
                                            type="button" 
                                            className="btn btn-secondary" 
                                            ref={closeModalToChangePassword}
                                            data-bs-dismiss="modal"
                                        >
                                            Fermer
                                        </button>
                                        <button type="submit" className="btn btn-primary">
                                            Modifier
                                        </button>
                                    </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sidebar;