import Footer from "../components/Footer";
import Header from "../components/Header";
import { Formik } from 'formik';
import * as Yup from "yup";
import "../helper.css";
import userService from "../services/userService"
// import * as yup from "yup"
// import { useForm } from "react-hook-form"
// import { yupResolver } from "@hookform/resolvers/yup"


// const schema = yup
//   .object({
//     firstName: yup.string().required(),
//     age: yup.number().positive().integer().required(),
//   })
//   .required()

  
function Register() {
    // const {
    //     register,
    //     handleSubmit,
    //     formState: { errors },
    //   } = useForm({
    //     resolver: yupResolver(schema),
    //   })
    const handleSubmit = async (values) => {
        userService.createAccount(values)
        // await new Promise((resolve) => setTimeout(resolve, 500));
        // alert(JSON.stringify(values, null, 2));
    };
    return (
        <>
            <Header />
            <main class="main-content  mt-0">
                <section>
                    <div class="page-header min-vh-75">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
                                    <div class="card card-plain mt-8">
                                        <div class="card-header pb-0 text-left bg-transparent">
                                            <h3 class="font-weight-bolder text-info text-gradient">Welcome back</h3>
                                            <p class="mb-0">Enter your email and password to sign in</p>
                                        </div>
                                        <div class="card-body">
                                            <Formik
                                                initialValues={{ email: "", password: "" }}
                                                onSubmit={handleSubmit}
                                                validationSchema={Yup.object().shape({
                                                    email: Yup.string()
                                                        .email()
                                                        .required("Email Required"),
                                                    password: Yup.string()
                                                        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&])[A-Za-z\d@$!%?&]{8,}$/, 
                                                        "Le mot de passe doit contenir au moins :\n- 8 caractères\n- 1 lettre minuscule\n- 1 lettre majuscule\n- 1 chiffre\n- 1 caractère spécial (@$!%?&)")
                                                        .required("Le mot de passe est requis")        
                                                })}
                                            >
                                                {props => {
                                                    const {
                                                    values,
                                                    touched,
                                                    errors,
                                                    dirty,
                                                    isSubmitting,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    handleReset
                                                    } = props;
                                                    return (
                                                    <form onSubmit={handleSubmit}>
                                                        <label htmlFor="email" style={{ display: "block" }}>
                                                            Email
                                                        </label>
                                                        <div>
                                                            <input
                                                                id="email"

                                                                placeholder="Enter your email"
                                                                type="text"
                                                                value={values.email}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={`form-control ${
                                                                    errors.email && touched.email
                                                                    ? "text-input error"
                                                                    : "text-input"
                                                                }`}
                                                            />
                                                        </div>
                                                        
                                                        {errors.email && touched.email && (
                                                            <div className="input-feedback">{errors.email}</div>
                                                        )}
                                                        <label htmlFor="password" style={{ display: "block" }}>
                                                            Password
                                                        </label>
                                                        <div>
                                                            <input
                                                                id="password"

                                                                placeholder="Enter your password"
                                                                type="text"
                                                                value={values.password}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={`form-control ${
                                                                    errors.password && touched.password
                                                                    ? "text-input error"
                                                                    : "text-input"
                                                                }`}
                                                            />
                                                        </div>
                                                        {errors.password && touched.password && (
                                                            <div className="input-feedback">{errors.password}</div>
                                                        )}
                                                        <div className="text-center">
                                                            <button
                                                                type="button"
                                                                className="btn bg-gradient-info w-100 mt-4 mb-0"
                                                                onClick={handleReset}
                                                                disabled={!dirty || isSubmitting}
                                                            >
                                                                Reset
                                                            </button>
                                                        </div> 
                                                        <div className="text-center">
                                                            <button 
                                                                className="btn bg-gradient-info w-100 mt-4 mb-0"
                                                                type="submit" 
                                                                disabled={isSubmitting}>
                                                                Submit
                                                            </button>
                                                        </div>    
                                                            

                                                    </form>
                                                    );
                                                }}
                                            </Formik>
                                        </div>
                                        <div class="card-footer text-center pt-0 px-lg-2 px-1">
                                            <p class="mb-4 text-sm mx-auto">
                                            Don't have an account?
                                            <a href="/#" class="text-info text-gradient font-weight-bold">Sign up</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                                <div class="col-md-6">
                                    <div class="oblique position-absolute top-0 h-100 d-md-block d-none me-n8">
                                        <div class="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style={{backgroundImage:"url(" + '../assets/img/curved-images/curved6.jpg' +")"}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        <Footer />
    {/* START FOOTER 3 w/ COMPANY DESCRIPTION WITH LINKS & SOCIAL ICONS & COPYRIGHT  */}
        </>
    );
}

export default Register;
