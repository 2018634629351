import { getBearerAccessToken } from '../auth/auth';
//module.exports = {
const POST = function (url, data) {
    console.log("login ===>",data);
    return fetch(url, {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json"
        },
        "body": JSON.stringify(data)
    })
};

const sPOST = function (url, data) {
    const token = getBearerAccessToken();
    console.log('test pour la liste des clients ==> ',data)
    return fetch(url, {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization": "Bearer " + token
        },
        "body": JSON.stringify(data)
    })
};


const GET = function (url) {
    return fetch(url, {
        "method": "GET",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json"
        }
    })
};

const sGET = function (url) {
    const token = getBearerAccessToken();
    return fetch(url, {
        "method": "GET",
        "headers": {
            "accept": "application/json",
            "Authorization": "Bearer " + token
        }
    })
};

const PUT = function (url, data) {
    return fetch(url, {
        "method": "PUT",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json"
        },
        "body": JSON.stringify(data)
    })
};

const sPUT = function (url, data) {
    const token = getBearerAccessToken();
     return fetch(url, {
        "method": "PUT",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization": "Bearer " + token
        },
        "body": JSON.stringify(data)
    })
};
const PATCH = function (url, data) {
    fetch(url, {
        "method": "PATCH",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json"
        },
        "body": JSON.stringify(data)
    })
};

const DELETE = function (url) {
    return fetch(url, {
        "method": "DELETE",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json"
        }
    })
};

const sDELETE = (url) => {
    const token = getBearerAccessToken();
     return fetch(url, {
        "method": "DELETE",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization": "Bearer " + token
        },
        
    })

}

// using Java Script method to get PDF file
const downloadFile=(url,fileName,data)=>{
    fetch(url, {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
        },
        "body": JSON.stringify(data)
    }).then(response => {
        response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = fileName;
        alink.click();
    })
});
};



const sPOST_FILE = function (url, data) {
    const token = getBearerAccessToken();
    return fetch(url, {
        "method": "POST",
        "headers": {
            "accept": "application/json",
            "Authorization": "Bearer " + token
        },
        "body": data
    })
};

const sPATCH = function (url, data) {
    const token = getBearerAccessToken();
    console.log('test pour la liste des clients ==> ',data)
    return fetch(url, {
        "method": "PATCH",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization": "Bearer " + token
        },
        "body": JSON.stringify(data)
    })
};



// using Java Script method to get PDF file
const sDownloadFile=(url,fileName,data)=>{
    const token = getBearerAccessToken();
    fetch(url, {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "Authorization": "Bearer " + token
        },
        "body": JSON.stringify(data)
    })
};

const formDataPOST_FETCH = (url, data) => {
    
    return fetch(url, {
        "method": "POST",
        "credentials": "include",
       /* "headers": {
            "type": "formData"
        },*/
        "body": data
    });
};
export {
    POST,
    PATCH,
    GET,
    PUT,
    DELETE,
    sPOST,
    sGET,
    sPATCH,
    sPUT,
    sDELETE,
    //pGET,
    //upPOST,
    //upPUT,
    sPOST_FILE,
    //spGET,
    //supPUT,
    formDataPOST_FETCH,
    downloadFile,
    sDownloadFile
};