import Login from "../pages/Login";
import Customer from "../pages/Customer"
import Rooms from "../pages/Rooms";
import Booking from "../pages/Booking"
import Register from "../pages/Register";
import Workers from "../pages/Workers";
import CreateABooking from "../pages/CreateAbooking";


export const spaces = [
    {
        name: "public",
        secured: false,
        routes: [
            {
                path: '/login',
                component: Login,
                exact: true
            },
        ]
    }
    ,
    {
        name: "userspace",
        secured: true,
        role : "ROLE_USER",
        routes: [
            {
                
                path: '/',
                component: Booking,
                exact: true
            },
            {
             
                path: '/customer',
                component: Customer,
                exact: true     
                   },
            {
             
                path: '/rooms',
                component: Rooms,
                exact: true
            },
            {
                
                path: '/bookings',
                component: Booking,
                exact: true
            },
            {
                path: '/create-a-booking',
                component: CreateABooking,
                exact: true
            }
        
        ]
    },
    {
        name: "admin",
        secured: true,
        role : "ROLE_ADMIN",
        routes: [
            {
                path: '/user-admin',
                component: Login, // this component must be changed
                exact: true,
            },
            {
                path: '/workers',
                component: Workers, // this component must be changed
                exact: true,
            }
        ]
    }

      
]

export const flattenSpace = (spaces) =>{
    let flatten = [];
    spaces.forEach(space => {
    const {name,secured,role,routes} = space;
    routes.forEach(route=>{
        let element={}
        element.namespace=name;
        element.role=role;
        element.secured=secured;
        element.path=route.path;
        element.exact=route.exact;
        element.component=route.component;
        flatten.push(element);
    })
    
   });
   return flatten;
}

export const allRoutes = flattenSpace(spaces);