import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import RoomService from "../services/RoomService";
import { Formik } from 'formik';
import * as Yup from "yup";
//import { Pagination } from "antd";
import ReactPaginate from "react-paginate";
import UserService from "../services/userService";
import { getRole, hasRole } from "../auth/auth";
import Sidebar from "../components/Sidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import Navbar from "../components/Navbar";

const Workers = () => {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setloading] = useState(false)
    const closeModalCreateUser = useRef(null)
    const closeModalToDelete = useRef(null)
    const closeModalToUpdate = useRef(null)
    const [userUpdateOrDelete, setUserUpdateOrDelete] = useState(null);
    const [totalNumberOfPage, setTotalNumberOfPage] = useState(0);
    const [totalResult, setTotalResult] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const closeUpdatePasswordModal = useRef(null)
    // const [myPagination, setMypagination]= useState({
    //     current: null,
    //     total: null,
    // })
    // const handleSearchUsers =  async (e,search) => {
        
    //     const data = { search: , page:0}
    //     try {
    //         setloading(true)
    //         const response  = await UserService.getAllUsers(data)
    //         setUsers(response.result)
    //         setloading(false)
            
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
    const listAllUsers = async (search, page) => {
        try {
          //setLoading(true);
          const data = {}
          data.search = search
          data.page = page
          data.numberPerPage = 9
          //console.log('regarde le contenu ==>>> ', data);
          setloading(true)
          const response = await UserService.getAllUsers(data);
          setloading(false)
          //setLoading(false);
          setUsers(response.result);
          setTotalNumberOfPage(response.totalNumberOfPage)
            //setCurrentPage(response.page)
            setTotalResult(response.totalResult)
        } catch (error) {
          //setLoading(false);
          console.error('Erreur lors de la récupération des clients:', error);
        }
    };

    // const listAllUsers = async (search, page) => {
    //     try {
    //       //setLoading(true);
    //       const data = {}
    //       data.search = search
    //       data.page = page
    //       data.numberPerPage = 9
    //       console.log('regarde le contenu ==>>> ', data);
    //       setloading(true)
    //       const response = await UserService.getAllUsers(data);
    //       setloading(false)
    //       //setLoading(false);
    //       setMypagination((prevState) => ({
    //         ...prevState,
    //         current: response.page,
    //         total: response.totalResult
    //       }));
    //       console.log("voir la taille => ", response);
    //       setUsers(response.result);
    //     } catch (error) {
    //       //setLoading(false);
    //       console.error('Erreur lors de la récupération des clients:', error);
    //     }
    // };

    //console.log('mon current ', roomUpdateOrDelete)

    // useEffect(() => {
    //     listAllUsers(search, page-1);
    // }, [search, page]);

    useEffect(() => {
        listAllUsers(search, currentPage);
    }, []);

    const handleSubmitToCreateAWorker = async (values, { resetForm }) => {
        //alert(JSON.stringify(values))
        try {
            setloading(true)
            const response = await UserService.createAccount(values)
            if (response.errorResponse) {
                toast.error(response.message)
            } else {
                toast.success("L'utilisateur a été crée avec succès");
                listAllUsers(search, page-1); 
                resetForm()
                closeModalCreateUser.current.click()  
            }
            
        } catch (error) {
            console.error("Erreur lors de la création :", error)
            toast.error("Une erreur inattendue s'est produite lors de la création d'un utilisateur.")
        } finally {
            setloading(false)
        }
        
        //if (response) {
        //    console.log('ajout chambre ==>', response)
        
            //console.log('le current click ==>> ', closeModalCreateRoom.current)
        
        
        //toast("L'utilisateur a été Crée avec succès")
            
        //} else {
        //    console.log('mon erreur')
            
        //}
    };

    const getUserInformationsDetail = async (id) => {
        console.log("Detail ===> user",id)
        setloading(true)
        const response =  await UserService.workerOrAdminInformationDetail(id)
        setloading(false)
        setUserUpdateOrDelete(response)
        //console.log(`detail du user ${response}`)
    }

    const userADelete = async (id) => {
        try {
            setloading(true)
            const response = await UserService.deleteAnUser(id)
            if (response.errorResponse) {
                toast.error(response.message)
            } else {
                toast("L'utilisateur a été Supprimé avec succès")
                listAllUsers(search, page-1);
                closeModalToDelete.current.click()
            }
            
        } catch (error) {
            console.log("Erreur lors de la suppressiond'un utilisateur");
            toast.error("Une érreur inattendue s'est produite")
        } finally {
            setloading(false)
            //setUserUpdateOrDelete(null)
        }
        //setSearch("")
        //console.log('bouton de fermeture  de delete customer ==> ', closeModalToDelete.current)
    }
    
    //console.log(`user a supprimer ==> ${userUpdateOrDelete?.id}`)

    // const handleSubmitToUpdateWorkerOrAdmin = async (values, { resetForm }) => {
    //     console.log(`fermer apres mise à jour bon`)
    //     setloading(true)
    //     const response = await UserService.updateWorkerOrAdminInformations(userUpdateOrDelete.id, values)
    //     if (response.errorResponse) {
    //         toast.error("Error lors de la modification.")
    //     } else {
    //         toast("L'utilisateur a été Modifié avec succès");
    //     }
    //     setloading(false)
    //     listAllUsers(search, page-1)
    //     closeModalToUpdate.current.click()
    // }

    const handleSubmitToUpdateWorkerOrAdmin = async (values, { resetForm }) => {
        console.log(`fermer apres mise à jour bon`)
        setloading(true)
        try {
            const response = await UserService.updateWorkerOrAdminInformations(userUpdateOrDelete.id, values)

            console.log('Voir la réponse de update ===>>> ', response)
            if (response.errorResponse == true) {
                toast.error(response.message)
            } else {
                toast.success("L'utilisateur a été modifié avec succès");
                listAllUsers(search, page-1)
                closeModalToUpdate.current.click()
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour :", error)
            toast.error("Une erreur inattendue s'est produite lors de la modification.")
        } finally {
            setloading(false)
        }
    }
        //console.log(`loader mise à jour ${loading}`)


        //console.log(`loader mise à jour ${loading}`)
        
    // const handlePaginationChange= async (page, pageSize) => {
    //     console.log('which page customer ===>> ', page)
    //     try {
    //         setloading(true)
    //         mySearch.page = page - 1;
    //         const response  = await RoomService.getRoomsCurrentPage(mySearch)
    //         setRooms(response.result)
    //         setloading(false)
    //         //console.log('data from header ===>>> ', data)
    //         //navigate(`/shop?page=${my_page}`)
    //         document.body.scrollTop = 0;
    //         document.documentElement.scrollTop = 0;
    //     } catch (error) {
    //         console.log(error)
    //     }
        
    // };

    // const itemRender = (current, type, originalElement) => {
    //     if (type === 'page') {
    //       return <a>{current}</a>;
    //     }
        
    //     return originalElement;
    // };

    const handleSearchUser = (event) => {
        const searchUser = event.target.value;

        console.log('Voir le contenu de la recherche user ==> ', searchUser)
        const data = {}
        data.search = searchUser
        data.numberPerPage = 9
        //const response = BookingService.getBookings(data)
        listAllUsers(data.search)
        //setBookings(response.result)
        //console.log(`Expression ====> `, searchBooking)
    }

    const handleChangeCurrentPage = async(data) => {

        let currentPageu = data.selected;

        //setCurrentPage(page+1)
        // const data = {}
        // data.page = currentPage
        await listAllUsers(search, currentPageu)
    }

    // const initialValuesToUpdatePassword = {
    //     password: '',
    //     confirmPassword: '',
    // };
    
    const updatePasswordByAdmin = async (value, { resetForm }) => {
        //alert(JSON.stringify(value,null,4))
        const data = {}
        data.password = value.password
        const response = await UserService.updateWorkerOrAdminInformations(userUpdateOrDelete.id, data)
        toast.success("Le mot de passe a été modifié avec succès")
        resetForm()
        closeUpdatePasswordModal.current.click()
    }
    
    return (
        <>
        <Navbar />
        <div className="g-sidenav-show  bg-gray-100">
            <Sidebar />
            {/* <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 " id="sidenav-main">
                <div className="sidenav-header">
                    <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
                    <a className="navbar-brand m-0" href=" https://demos.creative-tim.com/soft-ui-dashboard/pages/dashboard.html " target="_blank">
                        <img src="../assets/img/logo-ct-dark.png" className="navbar-brand-img h-100" alt="main_logo" />
                        <span className="ms-1 font-weight-bold">Soft UI Dashboard</span>
                    </a>
                </div>
                <hr className="horizontal dark mt-0"/>
                <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link  active" to="/rooms">
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                <i class="fa-solid fa-bed"></i>
                                </div>
                                <span className="nav-link-text ms-1">Chambres</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link  active" to="/customer">
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="fa fa-user" aria-hidden="true"></i>
                                </div>
                                <span className="nav-link-text ms-1">Clients</span>
                            </Link>
                        </li>
                        {getRole() == "ROLE_ADMIN" && (
                            <li className="nav-item">
                                <Link className="nav-link  active" to="/workers">
                                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <i class="fa fa-user" aria-hidden="true"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">Employé</span>
                                </Link>
                            </li>
                        )}
                        <li className="nav-item">
                            <a className="nav-link  " href="/bookings">
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="fa fa-ticket" aria-hidden="true"></i>
                                </div>
                                <span className="nav-link-text ms-1"> Reservation</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link  " to="/bookings">
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="fa fa-ticket" aria-hidden="true"></i>
                                </div>
                                <span className="nav-link-text ms-1"> Créer une reservation</span>
                            </Link>
                        </li>
                        <li className="nav-item mt-3">
                        <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Account pages</h6>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link  " href="../pages/sign-in.html">
                            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <svg width="12px" height="12px" viewBox="0 0 40 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <title>document</title>
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g transform="translate(-1870.000000, -591.000000)" fill="#FFFFFF" fillRule="nonzero">
                                    <g transform="translate(1716.000000, 291.000000)">
                                    <g transform="translate(154.000000, 300.000000)">
                                        <path className="color-background opacity-6" d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"></path>
                                        <path className="color-background" d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"></path>
                                    </g>
                                    </g>
                                </g>
                                </g>
                            </svg>
                            </div>
                            <span className="nav-link-text ms-1">Déconnecter</span>
                        </a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link  " href="../pages/sign-up.html">
                            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <svg width="12px" height="20px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <title>spaceship</title>
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g transform="translate(-1720.000000, -592.000000)" fill="#FFFFFF" fillRule="nonzero">
                                    <g transform="translate(1716.000000, 291.000000)">
                                    <g transform="translate(4.000000, 301.000000)">
                                        <path className="color-background" d="M39.3,0.706666667 C38.9660984,0.370464027 38.5048767,0.192278529 38.0316667,0.216666667 C14.6516667,1.43666667 6.015,22.2633333 5.93166667,22.4733333 C5.68236407,23.0926189 5.82664679,23.8009159 6.29833333,24.2733333 L15.7266667,33.7016667 C16.2013871,34.1756798 16.9140329,34.3188658 17.535,34.065 C17.7433333,33.98 38.4583333,25.2466667 39.7816667,1.97666667 C39.8087196,1.50414529 39.6335979,1.04240574 39.3,0.706666667 Z M25.69,19.0233333 C24.7367525,19.9768687 23.3029475,20.2622391 22.0572426,19.7463614 C20.8115377,19.2304837 19.9992882,18.0149658 19.9992882,16.6666667 C19.9992882,15.3183676 20.8115377,14.1028496 22.0572426,13.5869719 C23.3029475,13.0710943 24.7367525,13.3564646 25.69,14.31 C26.9912731,15.6116662 26.9912731,17.7216672 25.69,19.0233333 L25.69,19.0233333 Z"></path>
                                        <path className="color-background opacity-6" d="M1.855,31.4066667 C3.05106558,30.2024182 4.79973884,29.7296005 6.43969145,30.1670277 C8.07964407,30.6044549 9.36054508,31.8853559 9.7979723,33.5253085 C10.2353995,35.1652612 9.76258177,36.9139344 8.55833333,38.11 C6.70666667,39.9616667 0,40 0,40 C0,40 0,33.2566667 1.855,31.4066667 Z"></path>
                                        <path className="color-background opacity-6" d="M17.2616667,3.90166667 C12.4943643,3.07192755 7.62174065,4.61673894 4.20333333,8.04166667 C3.31200265,8.94126033 2.53706177,9.94913142 1.89666667,11.0416667 C1.5109569,11.6966059 1.61721591,12.5295394 2.155,13.0666667 L5.47,16.3833333 C8.55036617,11.4946947 12.5559074,7.25476565 17.2616667,3.90166667 L17.2616667,3.90166667 Z"></path>
                                        <path className="color-background opacity-6" d="M36.0983333,22.7383333 C36.9280725,27.5056357 35.3832611,32.3782594 31.9583333,35.7966667 C31.0587397,36.6879974 30.0508686,37.4629382 28.9583333,38.1033333 C28.3033941,38.4890431 27.4704606,38.3827841 26.9333333,37.845 L23.6166667,34.53 C28.5053053,31.4496338 32.7452344,27.4440926 36.0983333,22.7383333 L36.0983333,22.7383333 Z"></path>
                                    </g>
                                    </g>
                                </g>
                                </g>
                            </svg>
                            </div>
                            <span className="nav-link-text ms-1">Sign Up</span>
                        </a>
                        </li>
                    </ul>
                </div>
            </aside> */}
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                {/* Navbar */}
                {/* <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
                <div className="container-fluid py-1 px-3">
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                        <li className="breadcrumb-item text-sm"><a className="opacity-5 text-dark" href="/#">Pages</a></li>
                        <li className="breadcrumb-item text-sm text-dark active" aria-current="page">Tables</li>
                    </ol>
                    <h6 className="font-weight-bolder mb-0">Tables</h6>
                    </nav>
                    <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                    <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                        <div className="input-group">
                        <span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true"></i></span>
                        <input type="text" className="form-control" placeholder="Type here..." />
                        </div>
                    </div>
                    <ul className="navbar-nav  justify-content-end">
                        <li className="nav-item d-flex align-items-center">
                        <a 
                            className="btn btn-outline-primary btn-sm mb-0 me-3" 
                            href="https://www.creative-tim.com/builder?ref=navbar-soft-ui-dashboard"
                        >
                            Online Builder
                        </a>
                        </li>
                        <li className="nav-item d-flex align-items-center">
                        <a href="/#" className="nav-link text-body font-weight-bold px-0">
                            <i className="fa fa-user me-sm-1"></i>
                            <span className="d-sm-inline d-none">Sign In</span>
                        </a>
                        </li>
                        <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                        <a href="/#" className="nav-link text-body p-0" id="iconNavbarSidenav">
                            <div className="sidenav-toggler-inner">
                            <i className="sidenav-toggler-line"></i>
                            <i className="sidenav-toggler-line"></i>
                            <i className="sidenav-toggler-line"></i>
                            </div>
                        </a>
                        </li>
                        <li className="nav-item px-3 d-flex align-items-center">
                        <a 
                            href="/#" 
                            className="nav-link text-body p-0"
                        >
                            <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
                        </a>
                        </li>
                        <li className="nav-item dropdown pe-2 d-flex align-items-center">
                        <a 
                            href="/#" 
                            className="nav-link text-body p-0" 
                            id="dropdownMenuButton" 
                            data-bs-toggle="dropdown" 
                            aria-expanded="false"
                        >
                            <i className="fa fa-bell cursor-pointer"></i>
                        </a>
                        <ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
                            <li className="mb-2">
                            <a className="dropdown-item border-radius-md" href="/#">
                                <div className="d-flex py-1">
                                <div className="my-auto">
                                    <img 
                                        alt=""
                                        src="../assets/img/team-2.jpg" 
                                        className="avatar avatar-sm  me-3 "
                                    />
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <h6 className="text-sm font-weight-normal mb-1">
                                    <span className="font-weight-bold">New message</span> from Laur
                                    </h6>
                                    <p className="text-xs text-secondary mb-0 ">
                                    <i className="fa fa-clock me-1"></i>
                                    13 minutes ago
                                    </p>
                                </div>
                                </div>
                            </a>
                            </li>
                            <li className="mb-2">
                            <a className="dropdown-item border-radius-md" href="/#">
                                <div className="d-flex py-1">
                                <div className="my-auto">
                                    <img 
                                        alt=""
                                        src="../assets/img/small-logos/logo-spotify.svg" 
                                        className="avatar avatar-sm bg-gradient-dark  me-3 "
                                    />
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <h6 className="text-sm font-weight-normal mb-1">
                                    <span className="font-weight-bold">New album</span> by Travis Scott
                                    </h6>
                                    <p className="text-xs text-secondary mb-0 ">
                                    <i className="fa fa-clock me-1"></i>
                                    1 day
                                    </p>
                                </div>
                                </div>
                            </a>
                            </li>
                            <li>
                            <a className="dropdown-item border-radius-md" href="/#">
                                <div className="d-flex py-1">
                                <div className="avatar avatar-sm bg-gradient-secondary  me-3  my-auto">
                                    <svg width="12px" height="12px" viewBox="0 0 43 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <title>credit-card</title>
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-2169.000000, -745.000000)" fill="#FFFFFF" fillRule="nonzero">
                                        <g transform="translate(1716.000000, 291.000000)">
                                            <g transform="translate(453.000000, 454.000000)">
                                            <path className="color-background" d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z" opacity="0.593633743"></path>
                                            <path className="color-background" d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"></path>
                                            </g>
                                        </g>
                                        </g>
                                    </g>
                                    </svg>
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <h6 className="text-sm font-weight-normal mb-1">
                                    Payment successfully completed
                                    </h6>
                                    <p className="text-xs text-secondary mb-0 ">
                                    <i className="fa fa-clock me-1"></i>
                                    2 days
                                    </p>
                                </div>
                                </div>
                            </a>
                            </li>
                        </ul>
                        </li>
                    </ul>
                    </div>
                </div>
                </nav> */}
                {/* End Navbar */}
                <div class="d-flex bd-highlight align-items-center namePhoneSearch mb-3">
                    <div class="me-auto p-2 bd-highlight">
                        <button 
                            type="button" 
                            class="btn btn-outline-info" 
                            data-bs-toggle="modal" 
                            data-bs-target="#exampleModalToCreateRoom"
                        >
                            Créer un Employé
                        </button>
                    </div>
                    <div class="p-2 bd-highlight">
                        <div class="ms-md-auto pe-md-3 d-flex align-items-center">
                            <div class="input-group">
                            <span class="input-group-text text-body">
                                <i class="fas fa-search" aria-hidden="true" />
                            </span>
                            <input 
                                type="text" 
                                //value={search}
                                class="form-control" 
                                placeholder="Nom d'un employé"
                                //onChange={(e) => setSearch(e.target.value)}
                                onChange={handleSearchUser}
                            />
                        </div>
                    </div>
                    </div>
                </div>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-4">
                                {loading &&  (
                                        <div class="d-flex justify-content-center align-item-center">
                                            <div class="spinner-border" role="status">
                                            <   span class="visually-hidden">Loading...</span>
                                            </div>
                                    </div>
                                )}
                                <div className="card-header pb-0">
                                    <h6>Liste des Employés</h6>
                                </div>
                                <div className="card-body px-0 pt-0 pb-2">
                                    <div className="table-responsive p-0">
                                        <table className="table align-items-center mb-0">
                                            <thead>
                                                <tr>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nom</th>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Nom d'utilisateur</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Genre</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Role</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Téléphone</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Quartier</th>
                                                {/* <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Region</th> */}
                                                <th className="text-secondary opacity-7"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users?.map(user => (
                                                    <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            {/* <div>
                                                                <img 
                                                                    src="../assets/img/team-2.jpg" 
                                                                    className="avatar avatar-sm me-3" 
                                                                    alt="user1"
                                                                />
                                                            </div> */}
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-sm">{user.lastName}</h6>
                                                                <h6 className="mb-0 text-sm">{user.firstName}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0 text-center">{user.username}</p>
                                                        {/* <p className="text-xs text-secondary mb-0">{user.street}</p> */}
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-success">{user.gender === "female" ? "FEMME" : "HOMME"}</span>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-success">{user.role == "ROLE_USER" ? "TRAVAILLEUR" : "ADMINISTRATEUR"}</span>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-success">{user.phoneNumber}</span>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-success">{user.street}</span>
                                                    </td>
                                                    <td className="align-middle">
                                                    
                                                        {/* <i 
                                                            class="fas fa-edit fa-2x" 
                                                            style={{marginRight: "32px"}} 
                                                            aria-hidden="true" 
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#updateUserModal" 
                                                            onClick={() => {getUserInformationsDetail(user.id)}}
                                                        />

                                                        <i 
                                                            style={{marginRight: "32px"}}
                                                            class="fa fa-trash editOrUpdate fa-2x" 
                                                            aria-hidden="true"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#staticBackdrop"
                                                            onClick={() => {setUserUpdateOrDelete(user)}}
                                                        >
                                                        </i> */}
                                                        <FontAwesomeIcon 
                                                            icon={faEdit} 
                                                            style={{marginRight: "32px"}}
                                                            size="2x" 
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#updateUserModal" 
                                                            onClick={() => {getUserInformationsDetail(user.id)}}/>
                                                        <FontAwesomeIcon 
                                                            icon={faTrash} 
                                                            style={{marginRight: "32px"}}
                                                            size="2x" 
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#staticBackdrop"
                                                            onClick={() => {setUserUpdateOrDelete(user)}}/>
                                                        
                                                        {/* <button 
                                                            type="button" 
                                                            className="btn btn-primary" 
                                                            style={{minWidth: "234px"}}
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#updatePasswordByAdmiModal"
                                                            onClick={() => {setUserUpdateOrDelete(user)}}
                                                            //onClick={() => {getBookingInformationsDetailToPayment(booking.reference)}}
                                                        >
                                                            Modifier son mot de passe
                                                        </button> */}
                                                        {/* <button
                                                            style={{marginRight: "32px"}} 
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#updateUserModal" 
                                                            onClick={() => {getUserInformationsDetail(user.id)}}
                                                        >Editer</button>
                                                        <button 
                                                            style={{marginRight: "32px"}}
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#staticBackdrop"
                                                            onClick={() => {setUserUpdateOrDelete(user)}}
                                                        >Supprimer</button> */}
                                                        {/* <button type="button" style={{
                                                            top: calc(100% - 10px),
                                                            position: "absolute",
                                                            marginRight: "15px",
                                                            left: "calc(100% - 200px)",
                                                        }} class="btn btn-success">Reservation</button> */}
                                                        {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Open modal for @mdo</button> */}
                                                    </td>
                                                    <td>
                                                    <button 
                                                            type="button" 
                                                            className="btn btn-primary" 
                                                            style={{minWidth: "234px"}}
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#updatePasswordByAdmiModal"
                                                            onClick={() => {setUserUpdateOrDelete(user)}}
                                                            //onClick={() => {getBookingInformationsDetailToPayment(booking.reference)}}
                                                        >
                                                            Modifier son mot de passe
                                                        </button>
                                                        {/* <FontAwesomeIcon 
                                                            icon={faCreditCard} 
                                                            style={{marginRight: "32px"}}
                                                            size="2x" 
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#paymentModal"
                                                            onClick={()=>{getBookingInformationsDetail(booking.reference)}}
                                                        /> */}
                                                        {/* <button 
                                                            type="button" 
                                                            className="btn btn-primary" 
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#updatePasswordByAdmiModal"
                                                            onClick={() => {setUserUpdateOrDelete(user)}}
                                                            //onClick={() => {getBookingInformationsDetailToPayment(booking.reference)}}
                                                        >
                                                            Modifier son mot de passe
                                                        </button> */}
                                                    </td>
                                                        
                                                </tr>
                                                ))}
                                                {/* <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                        <div>
                                                            <img 
                                                                src="../assets/img/team-3.jpg" 
                                                                className="avatar avatar-sm me-3" 
                                                                alt="user2"
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <h6 className="mb-0 text-sm">Alexa Liras</h6>
                                                            <p className="text-xs text-secondary mb-0">alexa@creative-tim.com</p>
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">Programator</p>
                                                        <p className="text-xs text-secondary mb-0">Developer</p>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-secondary">Offline</span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <span className="text-secondary text-xs font-weight-bold">11/01/19</span>
                                                    </td>
                                                    <td className="align-middle">
                                                        <a href="/#" className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                        <i class="fas fa-edit"></i>
                                                        </a>
                                                    </td>
                                                </tr> */}
                                                {/* <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                        <div>
                                                            <img 
                                                                src="../assets/img/team-4.jpg" 
                                                                className="avatar avatar-sm me-3" 
                                                                alt="user3"
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <h6 className="mb-0 text-sm">Laurent Perrier</h6>
                                                            <p className="text-xs text-secondary mb-0">laurent@creative-tim.com</p>
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">Executive</p>
                                                        <p className="text-xs text-secondary mb-0">Projects</p>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-success">Online</span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <span className="text-secondary text-xs font-weight-bold">19/09/17</span>
                                                    </td>
                                                    <td className="align-middle">
                                                        <a href="/#" className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                            <i class="fas fa-edit" style={{width: "16px", height: "16px"}}></i>
                                                        </a>
                                                        <div>
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </div>
                                                    </td>
                                                </tr> */}
                                                {/* <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                        <div>
                                                            <img 
                                                                src="../assets/img/team-3.jpg" 
                                                                className="avatar avatar-sm me-3" 
                                                                alt="user4"
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <h6 className="mb-0 text-sm">Michael Levi</h6>
                                                            <p className="text-xs text-secondary mb-0">michael@creative-tim.com</p>
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">Programator</p>
                                                        <p className="text-xs text-secondary mb-0">Developer</p>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-success">Online</span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <span className="text-secondary text-xs font-weight-bold">24/12/08</span>
                                                    </td>
                                                    <td className="align-middle">
                                                        <a 
                                                            href="/#" 
                                                            className="text-secondary font-weight-bold text-xs" 
                                                            data-toggle="tooltip" 
                                                            data-original-title="Edit user"
                                                        >
                                                        <i class="fas fa-edit"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                        <div>
                                                            <img 
                                                                src="../assets/img/team-2.jpg" 
                                                                className="avatar avatar-sm me-3" 
                                                                alt="user5"
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <h6 className="mb-0 text-sm">Richard Gran</h6>
                                                            <p className="text-xs text-secondary mb-0">richard@creative-tim.com</p>
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">Manager</p>
                                                        <p className="text-xs text-secondary mb-0">Executive</p>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-secondary">Offline</span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <span className="text-secondary text-xs font-weight-bold">04/10/21</span>
                                                    </td>
                                                    <td className="align-middle">
                                                        <a href={"javascript"} className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                        <i class="fas fa-edit"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                        <div>
                                                            <img 
                                                                src="../assets/img/team-4.jpg" 
                                                                className="avatar avatar-sm me-3" 
                                                                alt="user6"
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <h6 className="mb-0 text-sm">Miriam Eric</h6>
                                                            <p className="text-xs text-secondary mb-0">miriam@creative-tim.com</p>
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">Programtor</p>
                                                        <p className="text-xs text-secondary mb-0">Developer</p>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-secondary">Offline</span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <span className="text-secondary text-xs font-weight-bold">14/09/20</span>
                                                    </td>
                                                    <td className="align-middle">
                                                        <a href={"javascript"} className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                        <i class="fas fa-edit"></i>
                                                        </a>
                                                    </td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                        
                                    </div>
                                </div>
                            </div>
                            {/* <Pagination
                                itemRender={itemRender}
                                defaultCurrent={myPagination.current}
                                //onChange={(page, pageSize) => handlePaginationChange(page, pageSize)}
                                total={myPagination.total}
                            /> */}
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                pageCount={totalNumberOfPage}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handleChangeCurrentPage}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>    
                    </div>
                </div>
            </main>
            <div className="fixed-plugin">
                {/* <a 
                    href="/#"
                    className="fixed-plugin-button text-dark position-fixed px-3 py-2"
                >
                <i className="fa fa-cog py-2"> </i>
                </a> */}
                <div className="card shadow-lg ">
                    <div className="card-header pb-0 pt-3 ">
                        <div className="float-start">
                            <h5 className="mt-3 mb-0">Soft UI Configurator</h5>
                            <p>See our dashboard options.</p>
                        </div>
                        <div className="float-end mt-4">
                            <button className="btn btn-link text-dark p-0 fixed-plugin-close-button">
                                <i className="fa fa-close"></i>
                            </button>
                        </div>
                        {/* End Toggle Button */}
                    </div>
                    <hr className="horizontal dark my-1"/>
                    <div className="card-body pt-sm-3 pt-0">
                        {/*Sidebar Backgrounds */}
                        <div>
                        <h6 className="mb-0">Sidebar Colors</h6>
                        </div>
                        <a href={"javascript"} className="switch-trigger background-color">
                        <div className="badge-colors my-2 text-start">
                            <span className="badge filter bg-gradient-primary active" data-color="primary" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-dark" data-color="dark" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-info" data-color="info" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-success" data-color="success" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-warning" data-color="warning" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-danger" data-color="danger" onclick="sidebarColor(this)"></span>
                        </div>
                        </a>
                        {/*Sidenav Type*/}
                        <div className="mt-3">
                        <h6 className="mb-0">Sidenav Type</h6>
                        <p className="text-sm">Choose between 2 different sidenav types.</p>
                        </div>
                        <div className="d-flex">
                        <button className="btn bg-gradient-primary w-100 px-3 mb-2 active" data-className="bg-transparent" onclick="sidebarType(this)">Transparent</button>
                        {/* <button className="btn bg-gradient-primary w-100 px-3 mb-2 ms-2" data-className="bg-white" onclick="sidebarType(this)">White</button> */}
                        </div>
                        <p className="text-sm d-xl-none d-block mt-2">You can change the sidenav type just on desktop view.</p>
                        {/* Navbar Fixed */}
                        <div className="mt-3">
                        <h6 className="mb-0">Navbar Fixed</h6>
                        </div>
                        <div className="form-check form-switch ps-0">
                        <input 
                            className="form-check-input mt-1 ms-auto" 
                            type="checkbox" 
                            id="navbarFixed" 
                            onclick="navbarFixed(this)"
                        />
                        </div>
                        <hr className="horizontal dark my-sm-4" />
                        <a className="btn bg-gradient-dark w-100" href="https://www.creative-tim.com/product/soft-ui-dashboard">Free Download</a>
                        <a className="btn btn-outline-dark w-100" href="https://www.creative-tim.com/learning-lab/bootstrap/license/soft-ui-dashboard">View documentation</a>
                        <div className="w-100 text-center">
                        <a className="github-button" href="https://github.com/creativetimofficial/soft-ui-dashboard" data-icon="octicon-star" data-size="large" data-show-count="true" aria-label="Star creativetimofficial/soft-ui-dashboard on GitHub">Star</a>
                        <h6 className="mt-3">Thank you for sharing!</h6>
                        <a 
                            href="https://twitter.com/intent/tweet?text=Check%20Soft%20UI%20Dashboard%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%23bootstrap5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fsoft-ui-dashboard" 
                            className="btn btn-dark mb-0 me-2"
                        >
                            <i className="fab fa-twitter me-1" aria-hidden="true"></i> Tweet
                        </a>
                        <a 
                            href={"https://www.facebook.com/sharer/sharer.php"} 
                            className="btn btn-dark mb-0 me-2"
                        >
                            <i className="fab fa-facebook-square me-1" aria-hidden="true" />Share
                        </a>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade modal-lg" id="exampleModalToCreateRoom" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Créer un Employé</h5>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                        <Formik
                            initialValues={{ 
                                username: "",
                                password: "",
                                firstName: "",
                                lastName: "",
                                email: "",
                                gender: "male",
                                street: "",
                                city: "",
                                region: "",
                                phoneNumber: "",
                                role: "ROLE_USER",
                                country: ""
                            }}
                            onSubmit={handleSubmitToCreateAWorker}
                            // onSubmit={handleSubmit}
                            validationSchema={Yup.object().shape({
                                username: Yup.string()
                                    .required("Entrer le nom de l'employé"),   
                                password: Yup.string()
                                    .required("Entrer le password de l'employé"),   
                                firstName: Yup.string(),
                                lastName: Yup.string()
                                    .required("Veuillez entrer le nom de l'employé"),
                                email: Yup.string()
                                    .required("Veuillez entrer L'Email de l'employé"),
                                gender: Yup.string()
                                    .oneOf(["male", "female"])
                                    .required("Veuiller choisir le sexe de l'employé"),
                                street: Yup.string()
                                    .required("Veuillez entrer le quartier de l'employé"),
                                city: Yup.string()
                                    .required("Veuillez entrer la ville de l'employé"),
                                region: Yup.string()
                                    .required("Veuillez entrer la region de l'employé"),
                                phoneNumber: Yup.number()
                                    .required("Veuillez entrer le numéro de téléphone de l'employé"), 
                                role: Yup.string()
                                    .oneOf(["ROLE_ADMIN", "ROLE_USER"], "Veuillez choisir soit un role employé, soit un role administrateur"),
                                country: Yup.string()
                                    .required("De quelle nationalité est l'employé ")
                            })}
                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    dirty,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit: handleSubmitToCreateAWorker,
                                    handleReset
                                } = props;
                                return (
                                    
                                    <form onSubmit={handleSubmitToCreateAWorker}>
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="username" 
                                                    className="col-form-label"
                                                >
                                                    Nom d'utilisateur
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text" 
                                                    id="username" 
                                                    name="username"
                                                    placeholder="Entrer un nom d'utilisateur"
                                                    value={values.username} 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.username && touched.username
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.username && touched.username && (
                                            <div className="input-feedback">{errors.username}</div>
                                        )}
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label for="password" class="col-form-label">Mot de passe:</label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    id="password"
                                                    name="password"
                                                    value={values.password} 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.password && touched.password
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.password && touched.password && (
                                            <div className="input-feedback">{errors.password}</div>
                                        )}
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="quality" 
                                                    className="col-form-label"
                                                >
                                                    Prenom:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    id="firstName"
                                                    name="firstName"
                                                    value={values.firstName} 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.firstName && touched.firstName
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.firstName && touched.firstName && (
                                            <div className="input-feedback">{errors.firstName}</div>
                                        )}
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="type" 
                                                    className="col-form-label"
                                                >
                                                    Nom 
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text" 
                                                    name="lastName"
                                                    id="lastName"
                                                    value={values.lastName} 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.lastName && touched.lastName
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.lastName && touched.lastName && (
                                            <div className="input-feedback">{errors.lastName}</div>
                                        )}
                                        
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="mail" 
                                                    className="col-form-label"
                                                >
                                                    Email:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="email"  
                                                    id="email" 
                                                    name="email"
                                                    value={values.email} 
                                                    placeholder="jeanmichel@gmail.com"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.email && touched.email
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.email && touched.email && (
                                            <div className="input-feedback">{errors.email}</div>
                                        )}
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="gender" 
                                                    className="col-form-label"
                                                >
                                                    Genre:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <select
                                                    id="gender"
                                                    name="gender"
                                                    value={values.gender}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.gender && touched.gender
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }`}
                                                    class="form-select" 
                                                    aria-label="Default select example"
                                                >
                                                    <option value="male">Homme</option>
                                                    <option value="female">Femme</option>
                                                </select>
                                            </div>
                                        </div>
                                        {errors.gender && touched.gender && (
                                            <div className="input-feedback">{errors.gender}</div>
                                        )}
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="location" 
                                                    className="col-form-label"
                                                >
                                                    Quartier:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text"  
                                                    id="street" 
                                                    name="street"
                                                    value={values.street} 
                                                    placeholder="Veuillez saisir le quartier "
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.street && touched.street
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.street && touched.street && (
                                            <div className="input-feedback">{errors.street}</div>
                                        )}
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="city" 
                                                    className="col-form-label"
                                                >
                                                    Ville:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text"  
                                                    id="city" 
                                                    name="city"
                                                    value={values.city} 
                                                    placeholder="Veuillez saisir la ville "
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.city && touched.city
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.city && touched.city && (
                                            <div className="input-feedback">{errors.city}</div>
                                        )}
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="region" 
                                                    className="col-form-label"
                                                >
                                                    Region:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text"  
                                                    id="region" 
                                                    name="region"
                                                    value={values.region} 
                                                    placeholder="Veuillez saisir la region "
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.region && touched.region
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.region && touched.region && (
                                            <div className="input-feedback">{errors.region}</div>
                                        )}
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="phoneNumber" 
                                                    className="col-form-label"
                                                >
                                                    Telephone:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    id="phoneNumber" 
                                                    name="phoneNumber"
                                                    value={values.phoneNumber} 
                                                    placeholder="Veuillez saisir la region "
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.phoneNumber && touched.phoneNumber
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.phoneNumber && touched.phoneNumber && (
                                            <div className="input-feedback">{errors.phoneNumber}</div>
                                        )}
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="role" 
                                                    className="col-form-label"
                                                >
                                                    Role:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <select
                                                    id="role"
                                                    name="role"
                                                    value={values.role}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.role && touched.role
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }`}
                                                >
                                                    <option value="ROLE_USER">Employé</option>
                                                    <option value="ROLE_ADMIN">Administrateur</option>
                                                </select>
                                            </div>
                                        </div>
                                        {errors.role && touched.role && (
                                            <div className="input-feedback">{errors.role}</div>
                                        )}
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="country" 
                                                    className="col-form-label"
                                                >
                                                    Pays de L'utilisateur:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    id="country" 
                                                    name="country"
                                                    value={values.country} 
                                                    placeholder="Veuillez saisir le pays "
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.country && touched.country
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.country && touched.country && (
                                            <div className="input-feedback">{errors.country}</div>
                                        )}
                                        <div class="modal-footer">
                                            <button 
                                                type="button" 
                                                ref={closeModalCreateUser}
                                                className="btn btn-secondary" 
                                                data-bs-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                            <button 
                                                type="submit" 
                                                className="btn btn-primary"
                                            >
                                                Creer un utilisateur main
                                            </button>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                        </div>
                        {/* <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Creer une chambre</button>
                        </div> */}
                    </div>
                </div>
            </div>


            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Supprimer L'utilisateur  {userUpdateOrDelete?.username }</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    <div class="modal-body">
                        Etes-vous sur de vouloir supprimer {userUpdateOrDelete?.username }
                    </div>
                    <div class="modal-footer">
                        <button 
                            type="button" 
                            className="btn btn-secondary" 
                            data-bs-dismiss="modal" 
                            ref={closeModalToDelete}
                        >
                            Non
                        </button>
                        <button 
                            type="button" 
                            class="btn btn-primary" 
                            onClick={() => userADelete(userUpdateOrDelete.id)}
                        >
                            Oui
                        </button>
                    </div>
                    </div>
                </div>
            </div>

            
            <div class="modal fade modal-lg" id="updateUserModal" tabindex="-1" role="dialog" aria-labelledby="updateUserModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Modifier un utilisateur</h5>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{ 
                                username: userUpdateOrDelete?.username,
                                firstName: userUpdateOrDelete?.firstName,
                                lastName: userUpdateOrDelete?.lastName,
                                email: userUpdateOrDelete?.email,
                                gender: userUpdateOrDelete?.gender,
                                street: userUpdateOrDelete?.street,
                                city: userUpdateOrDelete?.city,
                                region: userUpdateOrDelete?.region,
                                phoneNumber: userUpdateOrDelete?.phoneNumber,
                                role: userUpdateOrDelete?.role,
                                country: userUpdateOrDelete?.country,
                            }}
                            onSubmit={handleSubmitToUpdateWorkerOrAdmin}
                            // onSubmit={handleSubmit}
                            validationSchema={Yup.object().shape({
                                username: Yup.string()
                                    .required("Veuillez saisir le username"),   
                                firstName: Yup.string(),   
                                lastName: Yup.string()
                                    .required("Veuillez saisir le nom de famille"),
                                email: Yup.string()
                                    .required("Veuillez saisir l' Email de l'utilisateur"),
                                gender: Yup.string(),
                                street: Yup.string()
                                    .required("Veuillez entrez le quartier"),
                                city: Yup.string()
                                    .required("Veuillez entrer la ville de l'utilisateur"),
                                region: Yup.string()
                                    .required("Veuillez saisir la région de cet utilisateur"),
                                phoneNumber: Yup.number()
                                    .required("Veuillez saisir le numéro de téléphone"),
                                role: Yup.string(),
                                country: Yup.string()
                                    .required("Veuillez saisir le pays d'origine de cet utilisateur")
                            })}
                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    dirty,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit: handleSubmitToUpdateWorkerOrAdmin,
                                    handleReset
                                } = props;
                                return (
                                    
                                    <form onSubmit={handleSubmitToUpdateWorkerOrAdmin}>
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="username" 
                                                    className="col-form-label"
                                                >
                                                    Username:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text" 
                                                    id="username" 
                                                    name="username"
                                                    value={values.username}
                                                    placeholder="Entrez un username"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.username && touched.username
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.username && touched.username && (
                                            <div className="input-feedback">{errors.username}</div>
                                        )}
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="firstName" 
                                                    className="col-form-label"
                                                >
                                                    Prenom:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    name="firstName"
                                                    id="firstName"
                                                    value={values.firstName} 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.firstName && touched.firstName
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="lastName" 
                                                    className="col-form-label"
                                                >
                                                    Nom:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    name="lastName"
                                                    id="lastName"
                                                    value={values.lastName} 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.lastName && touched.lastName
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                                {/* <select 
                                                    defaultValue="MEDIUM"
                                                    id="quality"
                                                    name="quality"
                                                    value={values.quality}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.quality && touched.quality
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }`}
                                                    class="form-select" 
                                                    aria-label="Default select example">
                                                    <option value="SIMPLE">SIMPLE</option>
                                                    <option value="MEDIUM">MOYENNE</option>
                                                    <option value="HIGHT">ELEVE</option>
                                                </select> */}
                                            </div>
                                        </div>
                                        {errors.lastName && touched.lastName && (
                                            <div className="input-feedback">{errors.lastName}</div>
                                        )}
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="email" 
                                                    className="col-form-label"
                                                >
                                                    Email: 
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="email" 
                                                    class="form-control" 
                                                    name="email"
                                                    id="emali"
                                                    value={values.email} 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.email && touched.email
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.email && touched.email && (
                                            <div className="input-feedback">{errors.email}</div>
                                        )}
                                        
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="gender" 
                                                    className="col-form-label"
                                                >
                                                    Genre:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <select 
                                                    defaultValue="male"
                                                    id="gender"
                                                    name="gender"
                                                    value={values.gender}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.gender && touched.gender
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }`}
                                                    aria-label="Default select example">
                                                    <option value="male">HOMME</option>
                                                    <option value="female">FEMME</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="street" 
                                                    className="col-form-label"
                                                >
                                                    Quartier:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text"
                                                    id="street" 
                                                    name="street"
                                                    value={values.street}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.street && touched.street
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.street && touched.street && (
                                            <div className="input-feedback">{errors.street}</div>
                                        )}
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="city" 
                                                    className="col-form-label"
                                                >
                                                    Ville:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text"
                                                    id="city" 
                                                    name="city"
                                                    value={values.city}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.city && touched.city
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.city && touched.city && (
                                            <div className="input-feedback">{errors.city}</div>
                                        )}
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="region" 
                                                    className="col-form-label"
                                                >
                                                    Region:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text"
                                                    id="region" 
                                                    name="region"
                                                    value={values.region}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.region && touched.region
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.region && touched.region && (
                                            <div className="input-feedback">{errors.street}</div>
                                        )} 
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="phoneNumber" 
                                                    className="col-form-label"
                                                >
                                                    Téléphone:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    type="text"
                                                    id="phoneNumber" 
                                                    name="phoneNumber"
                                                    value={values.phoneNumber}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.phoneNumber && touched.phoneNumber
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.phoneNumber && touched.phoneNumber && (
                                            <div className="input-feedback">{errors.phoneNumber}</div>
                                        )}
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="role" 
                                                    className="col-form-label"
                                                >
                                                    Role:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <select 
                                                    defaultValue="ROLE_USER"
                                                    id="role"
                                                    name="role"
                                                    value={values.role}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.role && touched.role
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }`}
                                                    aria-label="Default select example">
                                                    <option value="ROLE_USER">EMPLOYÉ</option>
                                                    <option value="ROLE_ADMIN">ADMINISTRATEUR</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="role" 
                                                    className="col-form-label"
                                                >
                                                    Pays:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    id="country"
                                                    name="country"
                                                    value={values.country}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.country && touched.country
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }`}
                                                />
                                                    
                                            </div>
                                        </div>
                                        {errors.country && touched.country && (
                                            <div className="input-feedback">{errors.country}</div>
                                        )}
                                        <div class="modal-footer">
                                            <button 
                                                type="button" 
                                                ref={closeModalToUpdate}
                                                className="btn btn-secondary" 
                                                data-bs-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                            <button 
                                                type="submit" 
                                                className="btn btn-primary"
                                            >
                                                Modifier un utilisateur
                                            </button>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                        </div>
                        {/* <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Creer une chambre</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div class="modal fade" id="updatePasswordByAdmiModal" tabindex="-1" aria-labelledby="updatePasswordByAdmiModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="updatePasswordModalLabel">Modifier le mot de passe de utilisateur {userUpdateOrDelete?.lastName}</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <Formik
                                    initialValues={{
                                        password: '',
                                        confirmPassword: ''
                                    }}
                                    validationSchema= {Yup.object().shape({
                                        password: Yup.string()
                                          .min(6, 'Le mot de passe doit contenir au moins 6 caractères')
                                          .required('Mot de passe requis'),
                                        confirmPassword: Yup.string()
                                          .oneOf([Yup.ref('password'), null], 'Les mots de passe doivent correspondre')
                                          .required('Confirmation du mot de passe requise'),
                                      })}
                                    // validateOnChange={true}
                                    // validateOnBlur={false}
                                    onSubmit={updatePasswordByAdmin}
                                >
                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit: updatePasswordByAdmin }) => (
                                        <form onSubmit={updatePasswordByAdmin}>
                                            <div className="mb-3">
                                                <label htmlFor="password" className="form-label">
                                                    Mot de passe 
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.password && touched.password ? 'is-invalid' : ''}`}
                                                    id="password"
                                                    name="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.password && touched.password && (
                                                    <div className="invalid-feedback">{errors.password}</div>
                                                )}
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="password" className="form-label">
                                                    Confirmer le mot de passe
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.confirmPassword && touched.confirmPassword ? 'is-invalid' : ''}`}
                                                    id="confirmPassword"
                                                    name="confirmPassword"
                                                    value={values.confirmPassword}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.confirmPassword && touched.confirmPassword && (
                                                    <div className="invalid-feedback">{errors.confirmPassword}</div>
                                                )}
                                            </div>
                                            <div className="modal-footer">
                                                <button 
                                                    type="button" 
                                                    className="btn btn-secondary" 
                                                    ref={closeUpdatePasswordModal}
                                                    data-bs-dismiss="modal"
                                                >
                                                    Fermer
                                                </button>
                                                <button type="submit" className="btn btn-primary">
                                                    Modifier le mot de passe
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                            
                        </div>
                    </div>
                </div>
            
            
        </div>
        </>
    )
}
export default Workers;