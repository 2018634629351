import {POST, sGET,  sPOST, sPUT,  sDELETE, sPATCH} from '../http/http';
import backendUrls from '../urls/url';

const BookingService = {
  createBooking: async function (data) {
    const url = backendUrls.BOOKING.createBooking;
    const result = await sPOST(url, data).then((r) =>
    r.json())
    console.log('my login ==> ',result)
    return result
  },
  getBookings: async function(data) {
    const url = backendUrls.BOOKING.listRoomBooking;
    const result = await sPOST(url, data)
    .then((r) => r.json())
    console.log("Voir les data et voir les results ===>> ",data)
    return result;
  },
  deleteABooking: async function(data) {
    const url = backendUrls.BOOKING.deleteABooking+data
    console.log(`url pour supprimer une chambre ${url}`)
    const result = await sDELETE(url)
    return result
  },
  bookingInformationsDetail: async function(reference) {
    const url = backendUrls.BOOKING.bookingInformationDetail+reference;
    console.log(`url detail du room ===>> ${url}`)
    const result = await sGET(url)
    .then((r) => r.json())
    return result;
  },
  updateBookingInformation: async function(id, data) {
    const url = backendUrls.BOOKING.updateBookingInformations+id;
    const result = await sPATCH(url, data).then((r) => 
    r.json())
    return result;
  },
  paymentInstallment: async function (reference, data) {
    console.log('payment => ',data)
    const url = backendUrls.BOOKING.paymentInstallment+reference;
    const result = await sPOST(url, data).then((r) => 
    r.json())
    return result;
  }, 
  terminateABooking: async function (reference) {
    const url = backendUrls.BOOKING.terminateABooking+reference;
    const result = await sPATCH(url).then((r) =>
    r.json());
    return result;
  }
};
export default BookingService;