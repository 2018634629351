import { POST, sPOST, sDELETE, GET, sGET, PATCH, sPATCH } from "../http/http";
import backendUrls from '../urls/url';

const RoomService = {
    createRooms: async function (data) {
        const url = backendUrls.Room.createARoom;
        console.log(`data de create Room ==>>`)
        const result = await sPOST(url, data)
        .then((r) => r.json())
        return result;
    }, 
    getAllRooms: async function(data) {
        console.log('Voir mon bonjour ==>>')
        const url = backendUrls.Room.getAllRooms;
        const result = await sPOST(url, data).
        then((r) => r.json())
        return result;
    },
    getListAllRooms: async function() {
        const url = backendUrls.Room.getListAllRooms;
        const result = await sGET(url)
        .then((r) => r.json())
        return result
    },
    deleteRoom: async function(data) {
        const url = backendUrls.Room.deleteRoom+data
        console.log(`url pour supprimer une chambre ${url}`)
        const result = await sDELETE(url)
        return result
    },
    roomInformationsDetail: async function(id) {
        const url = backendUrls.Room.roomInformationsDetail+id;
        console.log(`url detail du room ===>> ${url}`)
        const result = await sGET(url).then((r) => 
        r.json())
        return result;
    },
    updateRoomInformation: async function (id, data) {
        const url = backendUrls.Room.updateRoomInformations+id;
        console.log(`url pour LA CHAMRE à modifier == ${url}`)
        const result = await sPATCH(url, data)
        return result
    },
    getRoomsCurrentPage: async function(data) {
        console.log(`verifier la page si elle est JSON ===>> ${data}`)
        const url = backendUrls.Room.getRoomsPagination;
        const result = await sPOST(url, data).then((r) => 
        r.json())
        return result;
    },
}

export default RoomService;