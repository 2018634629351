import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import userService from "../services/userService";
import { Formik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
//import { Pagination } from "antd";
import ReactPaginate from "react-paginate";
import { getRole } from "../auth/auth";
import DatePicker from 'react-datepicker';
import Sidebar from '../components/Sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import moment from 'moment';
import fr from 'date-fns/locale/fr';
import Navbar from "../components/Navbar";
//import 'react-datepicker/dist/react-datepicker.css';
// import { Pagination, Typography, Stack } from "@mui/material"

const Customer = () => {
    const [Customers, setCustomers] = useState([]);
    const closeModalToDelete = useRef(null)
    const closeModalToUpdate = useRef(null)
    const closeModalCreateClient = useRef(null)
    const [search, setSearch] = useState("")
    const [currentPage, setCurrentPage] = useState(0);
    const [totalResult, setTotalResult] = useState(null);
    const [loading, setloading] = useState(false)
    const [totalNumberOfPage, setTotalNumberOfPage] = useState(0)
    const [totalNumberPerPage, setTotalNumberPerPage] = useState(8)
    const [userUpdateOrDelete, setUserUpdateOrDelete] = useState(null);

    const listAllCustomers = async (search, page, numberPerPage) => {
        const data = {}
        data.search = search;
        data.page = page;
        data.numberPerPage = numberPerPage;
        setloading(true);
        try {
            const response = await userService.getAllCustomers(data);
            if (response.errorResponse) {
                toast.error(response.message)
            } else {
                setCustomers(response.result);
                setTotalNumberOfPage(response.totalNumberOfPage)
                setTotalResult(response.totalResult)
            }
        } catch (error) {
            console.log('Error fetching customers')
        } finally {
            setloading(false)
        }
        
    }
    
    const handleSubmitToCreateClient = async (values, { resetForm }) => {
        try {
            setloading(true)
            const response = await userService.createclient(values)
            if (response.errorResponse) {
                toast.error(response.message)
            } else {
                toast("Le client a été Crée avec succès")
                listAllCustomers(search, currentPage, totalNumberPerPage);
                resetForm()
                closeModalCreateClient.current.click()
            }
        } catch (error) {
            console.log("Erreur lors de la creation : ",error);
            toast.error("Une érreur inattendue s'est produite lors de la creation du clien")
        } finally {
            setloading(false)
        }
        
    };

    const customerDelete = async (id) => {
        try {
            setloading(true)
            const response = await userService.deleteCustomer(id)
            if (response.errorResponse) {
                toast.error(response.message)
            } else {
                toast("Le client a été Supprimé avec succès")
                closeModalToDelete.current.click()
                listAllCustomers(search, currentPage, totalNumberPerPage);
                setCurrentPage(0)
            }
            
        } catch (error) {
            console.log('Erreur lors de la suppression du client');
            toast.error("Une erreur inattendue s'est produite")
        } finally {
            setloading(false)
        }
        //console.log('bouton de fermeture  de delete customer ==> ', closeModalToDelete.current)

    }
    const getUserInformationsDetail = async (id) => {
        console.log("Detail ===> user",id)
        setloading(true)
        const response =  await userService.userInformationsDetail(id)
        setloading(false)
        setUserUpdateOrDelete(response)
        //console.log(`detail du user ${response}`)
    }

    const handleSubmitToUpdateInformationsClient = async (values, { resetForm }) => {
        console.log(`fermer apres mise à jour bon`)
        setloading(true)
        const response = await userService.updateClientInformation(userUpdateOrDelete.id, values)
        setloading(false)
        //console.log(`fermer le modal de mise à jour ${closeModalToUpdate.current}`)
    
        listAllCustomers(search, currentPage, totalNumberPerPage)
        toast("Le client a été Modifié avec succès")
        closeModalToUpdate.current.click()
        //console.log(`loader mise à jour ${loading}`)

    }

    const handleChangeCurrentPage = (data) => {
        let currentPageu = data.selected;
        setCurrentPage(currentPageu)
        return ;
    
    }



    useEffect(() => {
        listAllCustomers(search, currentPage, totalNumberPerPage)
        //console.log("voi les resultats total ==>, ",totalResult)
    }, [search, currentPage, totalNumberPerPage])
    // useEffect(() => {
    //     listAllCustomers(mySearch.search, myPagination.current)
    // }, [mySearch.search])

    const handleSearchCustomer = (event) => {
        const searchCustomer = event.target.value;
        setSearch(searchCustomer)
        setCurrentPage(0)
    }
    
    const parseDate = (dateString, originalDate) => {
        if (!dateString) return null;
    
        const formats = ['DD-MM-YYYY', 'DD/MM/YYYY'];
        const parsedDate = moment(dateString, formats, true);
    
        return parsedDate.isValid() ? parsedDate.toDate() : originalDate;
      };

    const handleCustomersPerPageChange = (e) => {
        setCurrentPage(0);
        setTotalNumberPerPage(Number(e.target.value));
        return ;
    }
     
    return (
        <>
        <Navbar />
        <div className="g-sidenav-show  bg-gray-100">
            <Sidebar />
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                
                {/* Navbar */}
                {/* <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
                <div className="container-fluid py-1 px-3">
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                        <li className="breadcrumb-item text-sm"><a className="opacity-5 text-dark" href="/#">Pages</a></li>
                        <li className="breadcrumb-item text-sm text-dark active" aria-current="page">Tables</li>
                    </ol>
                    <h6 className="font-weight-bolder mb-0">Tables</h6>
                    </nav>
                    <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                    <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                        <div className="input-group">
                        <span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true"></i></span>
                        <input type="text" className="form-control" placeholder="Type here..." />
                        </div>
                    </div>
                    <ul className="navbar-nav  justify-content-end">
                        <li className="nav-item d-flex align-items-center">
                        <a 
                            className="btn btn-outline-primary btn-sm mb-0 me-3" 
                            href="https://www.creative-tim.com/builder?ref=navbar-soft-ui-dashboard"
                        >
                            Online Builder
                        </a>
                        </li>
                        <li className="nav-item d-flex align-items-center">
                        <a href="/#" className="nav-link text-body font-weight-bold px-0">
                            <i className="fa fa-user me-sm-1"></i>
                            <span className="d-sm-inline d-none">Sign In</span>
                        </a>
                        </li>
                        <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                        <a href="/#" className="nav-link text-body p-0" id="iconNavbarSidenav">
                            <div className="sidenav-toggler-inner">
                            <i className="sidenav-toggler-line"></i>
                            <i className="sidenav-toggler-line"></i>
                            <i className="sidenav-toggler-line"></i>
                            </div>
                        </a>
                        </li>
                        <li className="nav-item px-3 d-flex align-items-center">
                        <a 
                            href="/#" 
                            className="nav-link text-body p-0"
                        >
                            <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
                        </a>
                        </li>
                        <li className="nav-item dropdown pe-2 d-flex align-items-center">
                        <a 
                            href="/#" 
                            className="nav-link text-body p-0" 
                            id="dropdownMenuButton" 
                            data-bs-toggle="dropdown" 
                            aria-expanded="false"
                        >
                            <i className="fa fa-bell cursor-pointer"></i>
                        </a>
                        <ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
                            <li className="mb-2">
                            <a className="dropdown-item border-radius-md" href="/#">
                                <div className="d-flex py-1">
                                <div className="my-auto">
                                    <img 
                                        alt=""
                                        src="../assets/img/team-2.jpg" 
                                        className="avatar avatar-sm  me-3 "
                                    />
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <h6 className="text-sm font-weight-normal mb-1">
                                    <span className="font-weight-bold">New message</span> from Laur
                                    </h6>
                                    <p className="text-xs text-secondary mb-0 ">
                                    <i className="fa fa-clock me-1"></i>
                                    13 minutes ago
                                    </p>
                                </div>
                                </div>
                            </a>
                            </li>
                            <li className="mb-2">
                            <a className="dropdown-item border-radius-md" href="/#">
                                <div className="d-flex py-1">
                                <div className="my-auto">
                                    <img 
                                        alt=""
                                        src="../assets/img/small-logos/logo-spotify.svg" 
                                        className="avatar avatar-sm bg-gradient-dark  me-3 "
                                    />
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <h6 className="text-sm font-weight-normal mb-1">
                                    <span className="font-weight-bold">New album</span> by Travis Scott
                                    </h6>
                                    <p className="text-xs text-secondary mb-0 ">
                                    <i className="fa fa-clock me-1"></i>
                                    1 day
                                    </p>
                                </div>
                                </div>
                            </a>
                            </li>
                            <li>
                            <a className="dropdown-item border-radius-md" href="/#">
                                <div className="d-flex py-1">
                                <div className="avatar avatar-sm bg-gradient-secondary  me-3  my-auto">
                                    <svg width="12px" height="12px" viewBox="0 0 43 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <title>credit-card</title>
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-2169.000000, -745.000000)" fill="#FFFFFF" fillRule="nonzero">
                                        <g transform="translate(1716.000000, 291.000000)">
                                            <g transform="translate(453.000000, 454.000000)">
                                            <path className="color-background" d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z" opacity="0.593633743"></path>
                                            <path className="color-background" d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"></path>
                                            </g>
                                        </g>
                                        </g>
                                    </g>
                                    </svg>
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <h6 className="text-sm font-weight-normal mb-1">
                                    Payment successfully completed
                                    </h6>
                                    <p className="text-xs text-secondary mb-0 ">
                                    <i className="fa fa-clock me-1"></i>
                                    2 days
                                    </p>
                                </div>
                                </div>
                            </a>
                            </li>
                        </ul>
                        </li>
                    </ul>
                    </div>
                </div>
                </nav> */}
                {/* End Navbar */}
                <div class="d-flex justify-content-between namePhoneSearch mb-3">
                    <div class="p-2 bd-highlight">
                        <button 
                            type="button" 
                            class="btn btn-outline-info" 
                            data-bs-toggle="modal" 
                            data-bs-target="#createClientModal"
                        >
                            Créer un Client
                        </button>
                    </div>
                    <div class="p-2 bd-highlighalign-items-centert d-flex align-items-center">
                        <select className="h3 form-select" value={totalNumberPerPage} onChange={handleCustomersPerPageChange}>
                            <option selected>Nombre de clients</option>
                            <option value={5}>5</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>

                        </select>
                    </div>
                    <div class="p-2 bd-highlighalign-items-centert d-flex align-items-center" style={{marginBottom: "8px"}}>
                        <div class="pe-md-3 d-flex align-items-center">
                            <div class="input-group">
                            <span class="input-group-text text-body"><i class="fas fa-search" aria-hidden="true"></i></span>
                            <input 
                                type="text" 
                                //value={search}
                                //value={mySearch.search}
                                class="form-control" 
                                placeholder="Nom ou Numero de Téléphone"
                                onChange={handleSearchCustomer}
                            />
                        </div>
                    </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            
                            <div class="modal fade" id="createClientModal" tabindex="-1" role="dialog" aria-labelledby="createClientModal" aria-hidden="true">
                                <div class="modal-dialog modal-lg" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel">Créer un client</h5>
                                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                        <Formik
                                            initialValues={{ 
                                                firstName: "",
                                                lastName: "",
                                                type: "CLASSIC", 
                                                preferredPrice: "",
                                                gender: "",
                                                maidenName: "",
                                                birthDate: "",
                                                birthDatePlace: "",
                                                profession: "",
                                                country: "",
                                                countryIdentityType: "ID_CARD",
                                                countryIdentityNumber: "",
                                                countryIdentityDeliveryDate: "",
                                                countryIdentityDeliveryPlace: "",
                                                city: "",
                                                region: "",
                                                street: "",
                                                address: "",
                                                phoneNumber: "",
                                                description: ""
                                            }}
                                            onSubmit={handleSubmitToCreateClient}
                                            // onSubmit={handleSubmit}
                                            validationSchema={Yup.object().shape({
                                                firstName: Yup.string(),   
                                                lastName: Yup.string()
                                                    .required("Le nom d'utilisateur est obligé"),   
                                                type: Yup.string()
                                                    .oneOf(["BANNED", "CLASSIC", "VIP"])
                                                    .required("Choisir le type de client"),
                                                preferredPrice: Yup.number().when('type', {
                                                    is: 'VIP',
                                                    then: () => Yup.number().required('Prix préférentiel requis si VIP est sélectionné'),
                                                    otherwise: () => Yup.number().notRequired()
                                                    }),
                                                // email: Yup.string()
                                                //     .email()
                                                //     .required("Veuillez entrer votre adresse Email"),
                                                gender: Yup.string()
                                                    .oneOf(['male', 'female'], "Veuillez sélectionner le sexe")
                                                    .required("Le champ sexe est obligatoire"),
                                                maidenName: Yup.string(),
                                                birthDate: Yup.date(),
                                                birthDatePlace: Yup.string(),
                                                profession: Yup.string()
                                                    .required("Entrer la professeion"),
                                                country: Yup.string()
                                                    .required("Entrer le pays d'origine"),
                                                countryIdentityType: Yup.string()
                                                    .oneOf(["PASSPORT", "ID_CARD", "DRIVER_CARD"], "Veuillez choisir le type d'identité")
                                                    .required("Entrer le type d'identité du pays"),
                                                countryIdentityNumber: Yup.string()
                                                    .required("Entrer le numéro de la CNI"),
                                                countryIdentityDeliveryDate: Yup.date()
                                                    .required("Entrer la date de délivrance"),
                                                countryIdentityDeliveryPlace: Yup.string()
                                                    .required("Entrer le le lieu de délivrance"),
                                                city: Yup.string(),
                                                region: Yup.string(),
                                                street: Yup.string(),
                                                address: Yup.string(),
                                                phoneNumber: Yup.string()
                                                    .required("Entrer le numéro de téléphone"),
                                                description: Yup.string(),
                                                    

            
                                                // password: Yup.string()
                                                //     .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&])[A-Za-z\d@$!%?&]{8,}$/, 
                                                //     "Le mot de passe doit contenir au moins :\n- 8 caractères\n- 1 lettre minuscule\n- 1 lettre majuscule\n- 1 chiffre\n- 1 caractère spécial (@$!%?&)")
                                                //     .required("Le mot de passe est requis"),   
                                                

                                            })}
                                        >
                                            {props => {
                                                const {
                                                    values,
                                                    touched,
                                                    errors,
                                                    dirty,
                                                    setFieldValue,
                                                    isSubmitting,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit: handleSubmitToCreateClient,
                                                    handleReset
                                                } = props;
                                                return (
                                                    <form onSubmit={handleSubmitToCreateClient}>
                                                        <div class="row mb-2">
                                                            <div className="col-2">
                                                                <label for="lastName" class="col-form-label">Nom *</label>
                                                            </div>
                                                            <div className="col-10">
                                                                <input 
                                                                    id="lastName" 
                                                                    name="lastName"
                                                                    value={values.lastName}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text" 
                                                                    className={`form-control ${
                                                                        errors.lastName && touched.lastName
                                                                        ? "text-input error"
                                                                        : "text-input"
                                                                    }`}
                                                                />
                                                            </div>
                                                        </div>
                                                        {errors.lastName && touched.lastName && (
                                                            <div className="input-feedback">{errors.lastName}</div>
                                                        )}
                                                        <div class="row">
                                                            <div class="col-2">
                                                                <label for="username" class="col-form-label">
                                                                    Prenom
                                                                </label>
                                                            </div>
                                                            <div className="col-10">
                                                                <input 
                                                                    id="firstName"
                                                                    name="firstName"
                                                                    value={values.firstName}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text" 
                                                                    className={`form-control ${
                                                                        errors.firstName && touched.firstName
                                                                        ? "text-input error"
                                                                        : "text-input"
                                                                    }`}
                                                                />
                                                            </div>
                                                                
                                                        </div>
                                                        {errors.firstName && touched.firstName && (
                                                            <div className="input-feedback">{errors.firstName}</div>
                                                        )}
                                                        
                                                        <div class="row mt-2 mb-2">
                                                            <div className="col-2">
                                                                <label for="type" class="col-form-label">Type de client *</label>
                                                            </div>
                                                            <div className="col-10 mx-auto">
                                                                <select 
                                                                    id="type"
                                                                    name="type"
                                                                    value={values.type}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className={`form-control ${
                                                                        errors.type && touched.type
                                                                            ? "text-input error"
                                                                            : "text-input"
                                                                    }`}
                                                                    class="form-select" 
                                                                    aria-label="Default select example"
                                                                >
                                                                    <option value="CLASSIC">CLASSIQUE</option>
                                                                    <option value="VIP">VIP</option>
                                                                    <option value="BANNED">BANNI</option>
                                                                </select>
                                                            </div>      
                                                        </div>
                                                        {errors.type && touched.type && (
                                                            <div className="input-feedback">{errors.type}</div>
                                                        )}

                                                        {values.type === 'VIP' && (
                                                            <div class="row mb-2">
                                                                <div className="col-2">
                                                                    <label htmlFor="preferredPrice" className="col-form-label">
                                                                        Prix preferentiel
                                                                    </label>
                                                                </div>
                                                                <div className="col-10">
                                                                    <input 
                                                                        id="preferredPrice" 
                                                                        name="preferredPrice"
                                                                        value={values.preferredPrice}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        type="number" 
                                                                        className={`form-control ${
                                                                            errors.preferredPrice && touched.preferredPrice
                                                                            ? "text-input error"
                                                                            : "text-input"
                                                                        }`}
                                                                    />
                                                                </div>
                                                            </div>

                                                        )}
                                                        {errors.preferredPrice && touched.preferredPrice && (
                                                            <div className="input-feedback">{errors.preferredPrice}</div>
                                                        )} 

                                                        <div class="row">
                                                            <div className="col-2">
                                                                <label for="gender" class="col-form-label">Sexe *</label>
                                                            </div>
                                                            <div className="col-10 mx-auto">
                                                                <select 
                                                                    id="gender"
                                                                    name="gender"
                                                                    value={values.gender}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className={`form-control ${
                                                                        errors.gender && touched.gender
                                                                            ? "text-input error"
                                                                            : "text-input"
                                                                    }`}
                                                                    class="form-select" 
                                                                    aria-label="Default select example"
                                                                >
                                                                    <option value="">Veuillez sélectionner le sexe</option>
                                                                    <option value="male">Masculin</option>
                                                                    <option value="female">Feminin</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {errors.gender && touched.gender && (
                                                            <div className="input-feedback">{errors.gender}</div>
                                                        )}
                                                        {values.gender === 'female' && (
                                                            <div class="row">
                                                                <div className="col-2">
                                                                    <label 
                                                                        htmlFor="maidenName" 
                                                                        class="col-form-label"
                                                                    >
                                                                        Nom de jeune Fille:
                                                                    </label>
                                                                </div>
                                                                <div className="col-10 mt-2 mb-2">
                                                                    <input 
                                                                        value={values.maidenName}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        type="text" 
                                                                        className={`form-control ${
                                                                            errors.maidenName && touched.maidenName
                                                                                ? "text-input error"
                                                                                : "text-input"
                                                                        }`} 
                                                                        id="maidenName" 
                                                                        name="maidenName"
                                                                    /> 
                                                                </div>
                                                            </div>
                                                        )}
                                                        {errors.maidenName && touched.maidenName && (
                                                                <div className="input-feedback">{errors.maidenName}</div>
                                                        )}
                                                        <div class="row">
                                                            <div className="col-2">
                                                                <label 
                                                                    htmlFor="birthDate" 
                                                                    class="col-form-label"
                                                                >
                                                                    Date de naissance :
                                                                </label>
                                                            </div>
                                                            <div className="col-10 mt-2 mb-2">
                                                                <DatePicker
                                                                    selected={values.birthDate}
                                                                    onChange={(birthDate) => setFieldValue('birthDate', birthDate)}
                                                                    onBlur={handleBlur}
                                                                    dateFormat={['dd-MM-yyyy', 'dd/MM/yyyy']}
                                                                    parseDate={parseDate}
                                                                    placeholderText="JJ-MM-AAAA ou JJ/MM/AAAA"
                                                                    //readOnly={true}
                                                                    //showPopperArrow={false}
                                                                    open={false}
                                                                    
                                                                    //locale="fr"
                                                                    showYearDropdown
                                                                    scrollableYearDropdown
                                                                    //minDate={new Date(new Date().getFullYear() - 40, 0, 1)}
                                                                    //maxDate={new Date(new Date().getFullYear(), 11, 31)}
                                                                    //yearDropdownItemNumber={40} 
                                                                    className={`form-control dateWith birthDate ${
                                                                        errors.birthDate && touched.birthDate ? 'text-input error' : 'text-input'
                                                                    }`}
                                                                />
                                                                {/* <DatePicker
                                                                    selected={values.birthDate}
                                                                    onChange={(birthDate) => setFieldValue('birthDate', birthDate)}
                                                                    onBlur={handleBlur}
                                                                    dateFormat="dd MMMM yyyy"
                                                                    locale="fr"
                                                                    className={`form-control dateWith ${
                                                                        errors.birthDate && touched.birthDate ? "text-input error" : "text-input"
                                                                    }`}
                                                                /> */}
                                                                {/* <DatePicker
                                                                    selected={values.birthDate}
                                                                    onChange={(birthDate) => setFieldValue('birthDate', birthDate)}
                                                                    onBlur={handleBlur}
                                                                    showTimeSelect
                                                                    dateFormat="dd MMMM yyyy"
                                                                    locale="fr"
                                                                    className={`form-control dateWith ${
                                                                        errors.birthDate && touched.birthDate ? "text-input error" : "text-input"
                                                                    }`}
                                                                /> */}
                                                                {/* <DatePicker
                                                                    selected={values?.countryIdentityDeliveryDate}
                                                                    onChange={handleChange}
                                                                    dateFormat="dd MMMM yyyy"
                                                                    showTimeInput
                                                                    locale="fr"
                                                                    placeholderText="Sélectionnez une date"
                                                                    className="form-control"
                                                                /> */}
                                                                {/* <input 
                                                                    value={values.countryIdentityDeliveryDate}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="date" 
                                                                    className={`form-control ${
                                                                        errors.countryIdentityDeliveryDate && touched.countryIdentityDeliveryDate
                                                                            ? "text-input error"
                                                                            : "text-input"
                                                                    }`} 
                                                                    id="countryIdentityDeliveryDate" 
                                                                    name="countryIdentityDeliveryDate"
                                                                />  */}
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col-2">
                                                                <label 
                                                                    htmlFor="birthDatePlace" 
                                                                    class="col-form-label"
                                                                >
                                                                    Lieu de naissance:
                                                                </label>
                                                            </div>
                                                            <div className="col-10 mt-2 mb-2">
                                                                <input 
                                                                    value={values.birthDatePlace}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text" 
                                                                    className={`form-control ${
                                                                        errors.birthDatePlace && touched.birthDatePlace
                                                                            ? "text-input error"
                                                                            : "text-input"
                                                                    }`} 
                                                                    id="birthDatePlace" 
                                                                    name="birthDatePlace"
                                                                /> 
                                                            </div>
                                                        </div>
                                                        
                                                        {/* <div class="form-group">
                                                            <label 
                                                                htmlFor="birthdate" 
                                                                class="col-form-label"
                                                            >
                                                                Anniversaire:
                                                            </label>
                                                            <input 
                                                                value={values.}
                                                                type="date" 
                                                                class="form-control" 
                                                                id="birthdate" 
                                                                name="birthdate" 
                                                            />
                                                        </div> */}
                                                        <div class="row">
                                                            <div className="col-2">
                                                                <label for="idCountry" class="col-form-label">
                                                                    Type de pièce d'identité *
                                                                </label>
                                                            </div>
                                                            <div className="col-10 mx-auto">
                                                                <select 
                                                                    id="countryIdentityType"
                                                                    name="countryIdentityType"
                                                                    onChange={handleChange}
                                                                    value={values.countryIdentityType}
                                                                    onBlur={handleBlur}
                                                                    className={`form-control ${
                                                                        errors.countryIdentityType && touched.countryIdentityType
                                                                            ? "text-input error"
                                                                            : "text-input"
                                                                    }`}
                                                                    class="form-select" 
                                                                    aria-label="Default select example"
                                                                >
                                                                    <option value="DRIVER_CARD">PERMIS DE CONDUIRE</option>
                                                                    <option value="PASSPORT">PASSEPORT</option>
                                                                    <option value="ID_CARD">CARTE D'IDENTITÉ</option>
                                                                </select>
                                                            </div>
                                                            
                                                        </div>
                                                        {errors.countryIdentityType && touched.countryIdentityType && (
                                                            <div className="input-feedback">{errors.countryIdentityType}</div>
                                                        )}
                                                        <div class="row">
                                                            <div className="col-3">
                                                                <label 
                                                                    htmlFor="countryIdentityNumber" 
                                                                    className="col-form-label"
                                                                >
                                                                    Numéro Identité *
                                                                </label>
                                                            </div>
                                                            <div className="col-9">
                                                                <input 
                                                                    value={values.countryIdentityNumber}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    id="countryIdentityNumber" 
                                                                    name="countryIdentityNumber" 
                                                                    type="text" 
                                                                    className="form-control"    
                                                                />
                                                            </div>
                                                        </div>
                                                        {errors.countryIdentityNumber && touched.countryIdentityNumber && (
                                                            <div className="input-feedback">{errors.countryIdentityNumber}</div>
                                                        )}
                                                        <div class="row">
                                                            <div className="col-2">
                                                                <label 
                                                                    htmlFor="countryIdentityDeliveryDate" 
                                                                    class="col-form-label"
                                                                >
                                                                    Date de délivrance *:
                                                                </label>
                                                            </div>
                                                            <div className="col-10 mt-2 mb-2">
                                                                <DatePicker
                                                                    selected={values.countryIdentityDeliveryDate}
                                                                    onChange={(countryIdentityDeliveryDate) => setFieldValue('countryIdentityDeliveryDate', countryIdentityDeliveryDate)}
                                                                    onBlur={handleBlur}
                                                                    dateFormat={['dd-MM-yyyy', 'dd/MM/yyyy']}
                                                                    placeholderText="JJ-MM-AAAA ou JJ/MM/AAAA"
                                                                    open={false}
                                                                    //locale="fr"
                                                                    showYearDropdown
                                                                    scrollableYearDropdown
                                                                    //minDate={new Date(new Date().getFullYear() - 40, 0, 1)}
                                                                    //maxDate={new Date(new Date().getFullYear(), 11, 31)}
                                                                    //yearDropdownItemNumber={40} 
                                                                    className={`form-control dateWith ${
                                                                        errors.countryIdentityDeliveryDate && touched.countryIdentityDeliveryDate ? 'text-input error' : 'text-input'
                                                                    }`}
                                                                />
                                                                {/* <DatePicker
                                                                    selected={values.countryIdentityDeliveryDate}
                                                                    onChange={(date) => setFieldValue('countryIdentityDeliveryDate', date)}
                                                                    onBlur={handleBlur}
                                                                    //showTimeSelect
                                                                    dateFormat="dd MMMM yyyy"
                                                                    locale="fr"
                                                                    className={`form-control dateWith ${
                                                                        errors.countryIdentityDeliveryDate && touched.countryIdentityDeliveryDate ? "text-input error" : "text-input"
                                                                    }`}
                                                                /> */}
                                                                {/* <DatePicker
                                                                    selected={values?.countryIdentityDeliveryDate}
                                                                    onChange={handleChange}
                                                                    dateFormat="dd MMMM yyyy"
                                                                    showTimeInput
                                                                    locale="fr"
                                                                    placeholderText="Sélectionnez une date"
                                                                    className="form-control"
                                                                /> */}
                                                                {/* <input 
                                                                    value={values.countryIdentityDeliveryDate}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="date" 
                                                                    className={`form-control ${
                                                                        errors.countryIdentityDeliveryDate && touched.countryIdentityDeliveryDate
                                                                            ? "text-input error"
                                                                            : "text-input"
                                                                    }`} 
                                                                    id="countryIdentityDeliveryDate" 
                                                                    name="countryIdentityDeliveryDate"
                                                                />  */}
                                                            </div>
                                                        </div>
                                                        {errors.countryIdentityDeliveryDate && touched.countryIdentityDeliveryDate && (
                                                            <div className="input-feedback">{errors.countryIdentityDeliveryDate}</div>
                                                        )}
                                                        <div class="row">
                                                            <div className="col-2">
                                                                <label 
                                                                    htmlFor="countryIdentityDeliveryPlace" 
                                                                    class="col-form-label"
                                                                >
                                                                    Lieu de délivrance *:
                                                                </label>
                                                            </div>
                                                            <div className="col-10 mt-2 mb-2">
                                                                <input 
                                                                    value={values.countryIdentityDeliveryPlace}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text" 
                                                                    className={`form-control ${
                                                                        errors.countryIdentityDeliveryPlace && touched.countryIdentityDeliveryPlace
                                                                            ? "text-input error"
                                                                            : "text-input"
                                                                    }`} 
                                                                    id="countryIdentityDeliveryPlace" 
                                                                    name="countryIdentityDeliveryPlace"
                                                                /> 
                                                            </div>
                                                        </div>
                                                        {errors.countryIdentityDeliveryPlace && touched.countryIdentityDeliveryPlace && (
                                                            <div className="input-feedback">{errors.countryIdentityDeliveryPlace}</div>
                                                        )}
                                                        <div class="row">
                                                            <div className="col-2">
                                                                <label 
                                                                    htmlFor="profession" 
                                                                    class="col-form-label"
                                                                >
                                                                    Profession *:
                                                                </label>
                                                            </div>
                                                            <div className="col-10 mt-2 mb-2">
                                                                <input 
                                                                    value={values.profession}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text" 
                                                                    className={`form-control ${
                                                                        errors.profession && touched.profession
                                                                            ? "text-input error"
                                                                            : "text-input"
                                                                    }`} 
                                                                    id="profession" 
                                                                    name="profession"
                                                                /> 
                                                            </div>
                                                        </div>
                                                        {errors.profession && touched.profession && (
                                                            <div className="input-feedback">{errors.profession}</div>
                                                        )}
                                                        <div class="row mt-2 mb-2">
                                                            <div className="col-2">
                                                                <label 
                                                                    htmlFor="country" 
                                                                    class="col-form-label"
                                                                >
                                                                    Pays *:
                                                                </label>
                                                            </div>
                                                            <div className="col-10">
                                                                <input 
                                                                    id="country" 
                                                                    name="country" 
                                                                    value={values.country}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text" 
                                                                    className={`form-control ${
                                                                        errors.country && touched.country
                                                                        ? "text-input error"
                                                                        : "text-input"
                                                                    }`} 
                                                                />
                                                            </div>
                                                        </div>
                                                        {errors.country && touched.country && (
                                                            <div className="input-feedback">{errors.country}</div>
                                                        )}
                                                        {/* <div class="row">
                                                            <div className="col-2">
                                                                <label for="idCountry" class="col-form-label">
                                                                    Identité du pays *
                                                                </label>
                                                            </div>
                                                            <div className="col-10 mx-auto">
                                                                <select 
                                                                    id="countryIdentityType"
                                                                    name="countryIdentityType"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className={`form-control ${
                                                                        errors.countryIdentityType && touched.countryIdentityType
                                                                            ? "text-input error"
                                                                            : "text-input"
                                                                    }`}
                                                                    class="form-select" 
                                                                    aria-label="Default select example"
                                                                >
                                                                    <option value="DRIVER_CARD">PERMIS DE CONDUIRE</option>
                                                                    <option value="PASSPORT">PASSEPORT</option>
                                                                    <option value="ID_CARD">CARTE D'IDENTITÉ</option>
                                                                </select>
                                                            </div>
                                                            
                                                        </div>
                                                        {errors.countryIdentityType && touched.countryIdentityType && (
                                                            <div className="input-feedback">{errors.countryIdentityType}</div>
                                                        )} */}
                                                        
                                                        <div class="row">
                                                            <div className="col-2">
                                                                <label 
                                                                    htmlFor="city" 
                                                                    className="col-form-label"
                                                                >
                                                                    Ville *:
                                                                </label>
                                                            </div>
                                                            <div className="col-10">
                                                                <input 
                                                                    id="city" 
                                                                    name="city" 
                                                                    value={values.city}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text" 
                                                                    className={`form-control ${
                                                                        errors.city && touched.city
                                                                            ? "text-input error"
                                                                            : "text-input"
                                                                    }`}
                                                                />
                                                            </div>
                                                        </div>
                                                        {errors.city && touched.city && (
                                                            <div className="input-feedback">{errors.city}</div>
                                                        )}
                                                        <div class="row">
                                                            <div className="col-2">
                                                                <label for="region" class="col-form-label">Region </label>
                                                            </div>
                                                            <div className="col-10 mx-auto">
                                                                <input 
                                                                    id="region" 
                                                                    name="region" 
                                                                    value={values.region}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text" 
                                                                    className={`form-control ${
                                                                        errors.region && touched.region
                                                                            ? "text-input error"
                                                                            : "text-input"
                                                                    }`}
                                                                />
                                                            </div>
                                                            
                                                        </div>
                                                        {errors.region  && touched.region && (
                                                            <div className="input-feedback">{errors.region}</div>
                                                        )}
                                                        <div class="row">
                                                            <div className="col-2">
                                                                <label 
                                                                    htmlFor="street" 
                                                                    class="col-form-label"
                                                                >
                                                                    Quartier :
                                                                </label>
                                                            </div>
                                                            <div className="col-10">
                                                                <input 
                                                                    id="street" 
                                                                    name="street"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange} 
                                                                    value={values.street}
                                                                    type="text" 
                                                                    class={`form-control ${
                                                                        errors.street && touched.street
                                                                        ? "text-input error"
                                                                        : "text-input"
                                                                    }`} 
                                                                />
                                                            </div>
                                                        </div>
                                                        {errors.street  && touched.street && (
                                                            <div className="input-feedback">{errors.street}</div>
                                                        )}
                                                        <div class="row">
                                                            <div className="col-2">
                                                                <label 
                                                                    htmlFor="address" 
                                                                    className="col-form-label"
                                                                >
                                                                    Adresse :
                                                                </label>
                                                            </div>
                                                            <div className="col-10">
                                                                <input 
                                                                    id="address"
                                                                    name="address"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    class={`form-control ${
                                                                        errors.address && touched.address
                                                                        ? "text-input error"
                                                                        : "text-input"
                                                                    }`} 
                                                                />
                                                            </div>
                                                        </div>
                                                        {errors.address  && touched.address && (
                                                            <div className="input-feedback">{errors.address}</div>
                                                        )}
                                                        {/* <div class="form-group">
                                                            <label for="recipient-name" class="col-form-label">Numéro d'identité du pays:</label>
                                                            <input type="text" class="form-control" id="recipient-name" />
                                                        </div> */}
                                                        <div class="row">
                                                            <div className="col-2">
                                                                <label 
                                                                    htmlFor="phoneNumber" 
                                                                    class="col-form-label"
                                                                >
                                                                    Télephone *:
                                                                </label>
                                                            </div>
                                                            <div className="col-10">
                                                                <input 
                                                                    id="phoneNumber" 
                                                                    name="phoneNumber" 
                                                                    value={values.phoneNumber}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text" 
                                                                    class={`form-control ${
                                                                        errors.phoneNumber && touched.phoneNumber
                                                                        ? "text-input error"
                                                                        : "text-input"
                                                                    }`} 
                                                                />
                                                            </div>
                                                        </div>
                                                        {errors.phoneNumber  && touched.phoneNumber && (
                                                            <div className="input-feedback">{errors.phoneNumber}</div>
                                                        )}
                                                        <div class="row">
                                                            <div className="col-2">
                                                                <label 
                                                                    htmlFor="description" 
                                                                    class="col-form-label"
                                                                >
                                                                    Description:
                                                                </label>
                                                            </div>
                                                            <div className="col-10">
                                                                <textarea
                                                                    type="text"
                                                                    id="description"
                                                                    name="description"
                                                                    value={values.description}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className={`form-control ${
                                                                        errors.description && touched.description
                                                                        ? "text-input error"
                                                                        : "text-input"
                                                                    }`}
                                                                >

                                                                </textarea>
                                                            </div>
                                                            {/* <input type="text" class="form-control" id="recipient-name" /> */}
                                                        </div>
                                                        {errors.description  && touched.description && (
                                                            <div className="input-feedback">{errors.description}</div>
                                                        )}
                                                        {/* <div class="form-group">
                                                            <label for="recipient-name" class="col-form-label">Login:</label>
                                                            <input type="text" class="form-control" id="recipient-name" />
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="recipient-name" class="col-form-label">Password:</label>
                                                            <input type="text" class="form-control" id="recipient-name" />
                                                        </div> */}
                                                        
                                                    {/* <div class="form-group">
                                                        <label for="message-text" class="col-form-label">Message:</label>
                                                        <textarea class="form-control" id="message-text"></textarea>
                                                    </div> */}
                                                    <div class="modal-footer">
                                                        <button 
                                                            type="button" 
                                                            class="btn btn-secondary" 
                                                            ref={closeModalCreateClient}
                                                            data-bs-dismiss="modal"
                                                        >
                                                            Fermer
                                                        </button>
                                                        <button 
                                                            type="submit" 
                                                            class="btn btn-primary"
                                                        >
                                                            Creer un client
                                                        </button>
                                                    </div>    
                                                    </form>
                                                );
                                            }}
                                        </Formik>
                                        </div>
                                        {/* <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                            <button type="button" class="btn btn-primary">Send message</button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-4">
                                {loading &&  (
                                    <div class="d-flex justify-content-center align-item-center">
                                        <div class="spinner-border" role="status">
                                        <   span class="visually-hidden">Loading...</span>
                                        </div>
                                  </div>
                                )}
                                <div className="card-header pb-0">
                                    <h6>Liste des Clients</h6>
                                </div>
                                <div className="card-body px-0 pt-0 pb-2">
                                    <div className="table-responsive p-0">
                                        <table className="table align-items-center mb-0">
                                            <thead>
                                                <tr>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Customer</th>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Adress</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Telephone</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Genre</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type</th>
                                                <th className="text-secondary opacity-7"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Customers.map(customer => (
                                                    <tr>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            {/* <div>
                                                                <img 
                                                                    src="../assets/img/team-2.jpg" 
                                                                    className="avatar avatar-sm me-3" 
                                                                    alt="user1"
                                                                />
                                                            </div> */}
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-sm">{customer.firstName}</h6>
                                                                <p className="text-xs text-secondary mb-0">{customer.lastName}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">{customer?.address}</p>
                                                        <p className="text-xs text-secondary mb-0">{customer?.city}</p>
                                                        <p className="text-xs text-secondary mb-0">{customer.country}</p>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-success phone">{customer.phoneNumber}</span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <span className="text-secondary text-xs font-weight-bold">{customer.gender}</span>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="badge badge-sm bg-gradient-success">{customer?.type}</span>
                                                    </td>
                                                    <td className="align-middle">
                                                    
                                                        {/* <i class="fas fa-edit fa-2x" 
                                                            style={{marginRight: "32px"}} 
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#updateClientModal"
                                                            aria-hidden="true"
                                                            onClick={() => {getUserInformationsDetail(customer.id)}} 
                                                        
                                                        />

                                                        <i 
                                                            style={{marginRight: "32px"}}
                                                            class="fa fa-trash editOrUpdate fa-2x" 
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#staticBackdrop"
                                                            aria-hidden="true"
                                                            onClick={() => {setUserUpdateOrDelete(customer)}}
                                                        >
                                                        </i> */}
                                                        <FontAwesomeIcon 
                                                            icon={faEdit} 
                                                            style={{marginRight: "32px"}}
                                                            size="2x" 
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#updateClientModal" 
                                                            onClick={() => {getUserInformationsDetail(customer.id)}}/>
                                                        <FontAwesomeIcon 
                                                            icon={faTrash} 
                                                            style={{marginRight: "32px"}}
                                                            size="2x" 
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#staticBackdrop"
                                                            onClick={() => {setUserUpdateOrDelete(customer)}}/>
                                                        {/* <button 
                                                            type="button" 
                                                            data-bs-toggle="modal"
                                                            aria-hidden="true"
                                                            class="btn btn-success btn-sm mt-0"
                                                        >
                                                            Reservation
                                                        </button> */}
                                                        {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Open modal for @mdo</button> */}
                                                        
                                                    </td>
                                                        
                                                </tr>
                                                ))}
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            {/* <Pagination 
                                itemRender={itemRender} 
                                current={currentPage}
                                onChange={handleChangeCurrentPage}
                                total={totalResult}
                                pageSize={9}  // Ajoutez cette ligne
                                //showSizeChanger={false}  // Optionnel : pour désactiver le changement de taille de page
                            /> */}
                            {/* <Pagination
                                itemRender={itemRender}
                                current={1}
                                //defaultCurrent={currentPage}
                                //defaultCurrent={myPagination.current}
                                onChange={handleChangeCurrentPage}
                                //onChange={(page, pageSize) => handlePaginationChange(page, pageSize)}
                                total={10}
                                //total={myPagination.total}
                            /> */}
                            <ReactPaginate
                                key={currentPage}
                                initialPage={currentPage}
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                pageCount={totalNumberOfPage}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handleChangeCurrentPage}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeLinkClassName={"active"}
                            />
                        </div>
                    </div>
                {/* <div className="row">
                    <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                        <h6>Projects table</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                        <div className="table-responsive p-0">
                            <table className="table align-items-center justify-content-center mb-0">
                            <thead>
                                <tr>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Project</th>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Budget</th>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Status</th>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">Completion</th>
                                <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>
                                    <div className="d-flex px-2">
                                    <div>
                                        <img 
                                            src="../assets/img/small-logos/logo-spotify.svg"   
                                            className="avatar avatar-sm rounded-circle me-2" 
                                            alt="spotify"
                                        />
                                    </div>
                                    <div className="my-auto">
                                        <h6 className="mb-0 text-sm">Spotify</h6>
                                    </div>
                                    </div>
                                </td>
                                <td>
                                    <p className="text-sm font-weight-bold mb-0">$2,500</p>
                                </td>
                                <td>
                                    <span className="text-xs font-weight-bold">working</span>
                                </td>
                                <td className="align-middle text-center">
                                    <div className="d-flex align-items-center justify-content-center">
                                    <span className="me-2 text-xs font-weight-bold">60%</span>
                                    <div>
                                        <div className="progress">
                                        <div 
                                            className="progress-bar bg-gradient-info" 
                                            role="progressbar" 
                                            aria-valuenow="60" 
                                            aria-valuemin="0" 
                                            aria-valuemax="100" 
                                            style={{width: "60%"}}></div>
                                        </div>
                                    </div>
                                    </div>
                                </td>
                                <td className="align-middle">
                                    <button className="btn btn-link text-secondary mb-0">
                                    <i className="fa fa-ellipsis-v text-xs"></i>
                                    </button>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <div className="d-flex px-2">
                                    <div>
                                        <img 
                                            src="../assets/img/small-logos/logo-invision.svg" 
                                            className="avatar avatar-sm rounded-circle me-2" 
                                            alt="invision"
                                        />
                                    </div>
                                    <div className="my-auto">
                                        <h6 className="mb-0 text-sm">Invision</h6>
                                    </div>
                                    </div>
                                </td>
                                <td>
                                    <p className="text-sm font-weight-bold mb-0">$5,000</p>
                                </td>
                                <td>
                                    <span className="text-xs font-weight-bold">done</span>
                                </td>
                                <td className="align-middle text-center">
                                    <div className="d-flex align-items-center justify-content-center">
                                    <span className="me-2 text-xs font-weight-bold">100%</span>
                                    <div>
                                        <div className="progress">
                                        <div 
                                            className="progress-bar bg-gradient-success" 
                                            role="progressbar" 
                                            aria-valuenow="100" 
                                            aria-valuemin="0" 
                                            aria-valuemax="100" 
                                            style={{width: "100%"}}></div>
                                        </div>
                                    </div>
                                    </div>
                                </td>
                                <td className="align-middle">
                                    <button className="btn btn-link text-secondary mb-0" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-ellipsis-v text-xs"></i>
                                    </button>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <div className="d-flex px-2">
                                    <div>
                                        <img 
                                            src="../assets/img/small-logos/logo-jira.svg" 
                                            className="avatar avatar-sm rounded-circle me-2" 
                                            alt="jira"
                                        />
                                    </div>
                                    <div className="my-auto">
                                        <h6 className="mb-0 text-sm">Jira</h6>
                                    </div>
                                    </div>
                                </td>
                                <td>
                                    <p className="text-sm font-weight-bold mb-0">$3,400</p>
                                </td>
                                <td>
                                    <span className="text-xs font-weight-bold">canceled</span>
                                </td>
                                <td className="align-middle text-center">
                                    <div className="d-flex align-items-center justify-content-center">
                                    <span className="me-2 text-xs font-weight-bold">30%</span>
                                    <div>
                                        <div className="progress">
                                        <div 
                                            className="progress-bar bg-gradient-danger" 
                                            role="progressbar" 
                                            aria-valuenow="30" 
                                            aria-valuemin="0" 
                                            aria-valuemax="30" 
                                            style={{width: "30%"}}></div>
                                        </div>
                                    </div>
                                    </div>
                                </td>
                                <td className="align-middle">
                                    <button className="btn btn-link text-secondary mb-0" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-ellipsis-v text-xs"></i>
                                    </button>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <div className="d-flex px-2">
                                    <div>
                                        <img 
                                            src="../assets/img/small-logos/logo-slack.svg" 
                                            className="avatar avatar-sm rounded-circle me-2" 
                                            alt="slack"
                                        />
                                    </div>
                                    <div className="my-auto">
                                        <h6 className="mb-0 text-sm">Slack</h6>
                                    </div>
                                    </div>
                                </td>
                                <td>
                                    <p className="text-sm font-weight-bold mb-0">$1,000</p>
                                </td>
                                <td>
                                    <span className="text-xs font-weight-bold">canceled</span>
                                </td>
                                <td className="align-middle text-center">
                                    <div className="d-flex align-items-center justify-content-center">
                                    <span className="me-2 text-xs font-weight-bold">0%</span>
                                    <div>
                                        <div className="progress">
                                        <div 
                                            className="progress-bar bg-gradient-success" 
                                            role="progressbar" 
                                            aria-valuenow="0" 
                                            aria-valuemin="0" 
                                            aria-valuemax="0" 
                                            style={{width: "0%"}}
                                        >

                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </td>
                                <td className="align-middle">
                                    <button className="btn btn-link text-secondary mb-0" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-ellipsis-v text-xs"></i>
                                    </button>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <div className="d-flex px-2">
                                    <div>
                                        <img 
                                            src="../assets/img/small-logos/logo-webdev.svg" 
                                            className="avatar avatar-sm rounded-circle me-2" 
                                            alt="webdev"
                                        />
                                    </div>
                                    <div className="my-auto">
                                        <h6 className="mb-0 text-sm">Webdev</h6>
                                    </div>
                                    </div>
                                </td>
                                <td>
                                    <p className="text-sm font-weight-bold mb-0">$14,000</p>
                                </td>
                                <td>
                                    <span className="text-xs font-weight-bold">working</span>
                                </td>
                                <td className="align-middle text-center">
                                    <div className="d-flex align-items-center justify-content-center">
                                    <span className="me-2 text-xs font-weight-bold">80%</span>
                                    <div>
                                        <div className="progress">
                                        <div 
                                            className="progress-bar bg-gradient-info" 
                                            role="progressbar" 
                                            aria-valuenow="80" 
                                            aria-valuemin="0" 
                                            aria-valuemax="80" 
                                            style={{width: "80%"}}
                                        >

                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </td>
                                <td className="align-middle">
                                    <button className="btn btn-link text-secondary mb-0" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-ellipsis-v text-xs"></i>
                                    </button>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <div className="d-flex px-2">
                                    <div>
                                        <img 
                                            src="../assets/img/small-logos/logo-xd.svg" 
                                            className="avatar avatar-sm rounded-circle me-2" 
                                            alt="xd"
                                        />
                                    </div>
                                    <div className="my-auto">
                                        <h6 className="mb-0 text-sm">Adobe XD</h6>
                                    </div>
                                    </div>
                                </td>
                                <td>
                                    <p className="text-sm font-weight-bold mb-0">$2,300</p>
                                </td>
                                <td>
                                    <span className="text-xs font-weight-bold">done</span>
                                </td>
                                <td className="align-middle text-center">
                                    <div className="d-flex align-items-center justify-content-center">
                                    <span className="me-2 text-xs font-weight-bold">100%</span>
                                    <div>
                                        <div className="progress">
                                        <div 
                                            className="progress-bar bg-gradient-success" 
                                            role="progressbar" 
                                            aria-valuenow="100" 
                                            aria-valuemin="0" 
                                            aria-valuemax="100" 
                                            style={{width: "100%"}}
                                        >

                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </td>
                                <td className="align-middle">
                                    <button className="btn btn-link text-secondary mb-0" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-ellipsis-v text-xs"></i>
                                    </button>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                    </div>
                </div> */}
                
                {/* <footer className="footer pt-3  ">
                    <div className="container-fluid">
                    <div className="row align-items-center justify-content-lg-between">
                        <div className="col-lg-6 mb-lg-0 mb-4">
                        <div className="copyright text-center text-sm text-muted text-lg-start">
                            © <script>
                            document.write(new Date().getFullYear())
                            </script>,
                            made with <i className="fa fa-heart"></i> by
                            <a href="https://www.creative-tim.com" className="font-weight-bold">Creative Tim</a>
                            for a better web.
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                            <li className="nav-item">
                            <a href="https://www.creative-tim.com" className="nav-link text-muted" >Creative Tim</a>
                            </li>
                            <li className="nav-item">
                            <a href="https://www.creative-tim.com/presentation" className="nav-link text-muted">About Us</a>
                            </li>
                            <li className="nav-item">
                            <a href="https://www.creative-tim.com/blog" className="nav-link text-muted">Blog</a>
                            </li>
                            <li className="nav-item">
                            <a href="https://www.creative-tim.com/license" className="nav-link pe-0 text-muted">License</a>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </footer> */}
                </div>
            </main>
            <div className="fixed-plugin">
                {/* <a 
                    href="/#"
                    className="fixed-plugin-button text-dark position-fixed px-3 py-2"
                >
                <i className="fa fa-cog py-2"> </i>
                </a> */}
                <div className="card shadow-lg ">
                    <div className="card-header pb-0 pt-3 ">
                        <div className="float-start">
                            <h5 className="mt-3 mb-0">Soft UI Configurator</h5>
                            <p>See our dashboard options.</p>
                        </div>
                        <div className="float-end mt-4">
                            <button className="btn btn-link text-dark p-0 fixed-plugin-close-button">
                                <i className="fa fa-close"></i>
                            </button>
                        </div>
                        {/* End Toggle Button */}
                    </div>
                    <hr className="horizontal dark my-1"/>
                    <div className="card-body pt-sm-3 pt-0">
                        {/*Sidebar Backgrounds */}
                        <div>
                        <h6 className="mb-0">Sidebar Colors</h6>
                        </div>
                        <a href={"javascript"} className="switch-trigger background-color">
                        <div className="badge-colors my-2 text-start">
                            <span className="badge filter bg-gradient-primary active" data-color="primary" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-dark" data-color="dark" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-info" data-color="info" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-success" data-color="success" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-warning" data-color="warning" onclick="sidebarColor(this)"></span>
                            <span className="badge filter bg-gradient-danger" data-color="danger" onclick="sidebarColor(this)"></span>
                        </div>
                        </a>
                        {/*Sidenav Type*/}
                        <div className="mt-3">
                        <h6 className="mb-0">Sidenav Type</h6>
                        <p className="text-sm">Choose between 2 different sidenav types.</p>
                        </div>
                        <div className="d-flex">
                        <button className="btn bg-gradient-primary w-100 px-3 mb-2 active" data-className="bg-transparent" onclick="sidebarType(this)">Transparent</button>
                        {/* <button className="btn bg-gradient-primary w-100 px-3 mb-2 ms-2" data-className="bg-white" onclick="sidebarType(this)">White</button> */}
                        </div>
                        <p className="text-sm d-xl-none d-block mt-2">You can change the sidenav type just on desktop view.</p>
                        {/* Navbar Fixed */}
                        <div className="mt-3">
                        <h6 className="mb-0">Navbar Fixed</h6>
                        </div>
                        <div className="form-check form-switch ps-0">
                        <input 
                            className="form-check-input mt-1 ms-auto" 
                            type="checkbox" 
                            id="navbarFixed" 
                            onclick="navbarFixed(this)"
                        />
                        </div>
                        <hr className="horizontal dark my-sm-4" />
                        <a className="btn bg-gradient-dark w-100" href="https://www.creative-tim.com/product/soft-ui-dashboard">Free Download</a>
                        <a className="btn btn-outline-dark w-100" href="https://www.creative-tim.com/learning-lab/bootstrap/license/soft-ui-dashboard">View documentation</a>
                        <div className="w-100 text-center">
                        {/* <a className="github-button" href="https://github.com/creativetimofficial/soft-ui-dashboard" data-icon="octicon-star" data-size="large" data-show-count="true" aria-label="Star creativetimofficial/soft-ui-dashboard on GitHub">Star</a> */}
                        <h6 className="mt-3">Thank you for sharing!</h6>
                        <a 
                            href="https://twitter.com/intent/tweet?text=Check%20Soft%20UI%20Dashboard%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%23bootstrap5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fsoft-ui-dashboard" 
                            className="btn btn-dark mb-0 me-2"
                        >
                            <i className="fab fa-twitter me-1" aria-hidden="true"></i> Tweet
                        </a>
                        <a 
                            href={"https://www.facebook.com/sharer/sharer.php"} 
                            className="btn btn-dark mb-0 me-2"
                        >
                            <i className="fab fa-facebook-square me-1" aria-hidden="true" />Share
                        </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Supprimer Le client {userUpdateOrDelete?.lastName + ' ' + userUpdateOrDelete?.firstName}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    <div class="modal-body">
                        Etes-vous sur de vouloir supprimer {userUpdateOrDelete?.lastName + ' ' + userUpdateOrDelete?.firstName}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" ref={closeModalToDelete}>Non</button>
                        <button type="button" class="btn btn-primary" onClick={() => customerDelete(userUpdateOrDelete.id)}>Oui</button>
                    </div>
                    </div>
                </div>
            </div>
            
            <div class="modal fade" id="updateClientModal" tabindex="-1" role="dialog" aria-labelledby="updateClientModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Modifier un client</h5>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{ 
                                firstName: userUpdateOrDelete?.firstName ,
                                lastName: userUpdateOrDelete?.lastName,
                                type: userUpdateOrDelete?.type, 
                                preferredPrice: userUpdateOrDelete?.preferredPrice,
                                gender: userUpdateOrDelete?.gender,
                                maidenName: userUpdateOrDelete?.maidenName,
                                birthDate: userUpdateOrDelete?.birthDate,
                                birthDatePlace: userUpdateOrDelete?.birthDatePlace,
                                profession: userUpdateOrDelete?.profession,
                                country: userUpdateOrDelete?.country,
                                countryIdentityType: userUpdateOrDelete?.countryIdentityType,
                                countryIdentityDeliveryDate: userUpdateOrDelete?.countryIdentityDeliveryDate,
                                countryIdentityDeliveryPlace: userUpdateOrDelete?.countryIdentityDeliveryPlace,
                                countryIdentityNumber: userUpdateOrDelete?.countryIdentityNumber,
                                city: userUpdateOrDelete?.city,
                                region: userUpdateOrDelete?.region,
                                street: userUpdateOrDelete?.street,
                                address: userUpdateOrDelete?.address,
                                phoneNumber: userUpdateOrDelete?.phoneNumber,
                                description: userUpdateOrDelete?.description
                            }}
                            onSubmit={handleSubmitToUpdateInformationsClient}
                            // onSubmit={handleSubmit}
                            validationSchema={Yup.object().shape({
                                firstName: Yup.string()
                                    .required("Le prenom d'utilisateur est obligé"),   
                                lastName: Yup.string()
                                    .required("Le nom d'utilisateur est obligé"),   
                                type: Yup.string()
                                    .oneOf(["BANNED", "CLASSIC", "VIP"])
                                    .required("Choisir le type de client"),
                                preferredPrice: Yup.number().when('type', {
                                    is: 'VIP',
                                    then: () => Yup.number().required('Prix préférentiel requis si VIP est sélectionné'),
                                    otherwise: () => Yup.number().notRequired()
                                }),
                                birthDate: Yup.date(),
                                // email: Yup.string()
                                //     .email()
                                //     .required("Veuillez entrer votre adresse Email"),
                                gender: Yup.string()
                                    .oneOf(['male', 'female'], "Veuillez sélectionner le sexe")
                                    .required("Le champ sexe est obligatoire"),
                                maidenName: Yup.string(),
                                profession: Yup.string()
                                    .required("Entrer la professeion"),
                                country: Yup.string()
                                    .required("Entrer le pays d'origine"),
                                countryIdentityType: Yup.string()
                                    .oneOf(["PASSPORT", "ID_CARD", "DRIVER_CARD"], "Veuillez choisir le type d'identité")
                                    .required("Entrer le type d'identité du pays"),
                                countryIdentityNumber: Yup.string()
                                    .required("Entrer le numéro de la CNI"),
                                countryIdentityDeliveryDate: Yup.date()
                                    .required("Entrer la date de délivrance"),
                                countryIdentityDeliveryPlace: Yup.string()
                                    .required("Entrer le le lieu de délivrance"),
                                city: Yup.string(),
                                region: Yup.string(),
                                street: Yup.string(),
                                address: Yup.string(),
                                phoneNumber: Yup.string()
                                    .required("Entrer le numéro de téléphone"),
                                description: Yup.string()
                                // password: Yup.string()
                                //     .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&])[A-Za-z\d@$!%?&]{8,}$/, 
                                //     "Le mot de passe doit contenir au moins :\n- 8 caractères\n- 1 lettre minuscule\n- 1 lettre majuscule\n- 1 chiffre\n- 1 caractère spécial (@$!%?&)")
                                //     .required("Le mot de passe est requis"),   
                                

                            })}
                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    dirty,
                                    isSubmitting,
                                    handleChange,
                                    setFieldValue,
                                    handleBlur,
                                    handleSubmit: handleSubmitToUpdateInformationsClient,
                                    handleReset
                                } = props;
                                return (
                                    <form onSubmit={handleSubmitToUpdateInformationsClient}>
                                        <div class="row mb-2">
                                            <div className="col-2">
                                                <label for="lastName" class="col-form-label">Nom *</label>
                                            </div>
                                            <div className="col-10">
                                                <input 
                                                    id="lastName" 
                                                    name="lastName"
                                                    value={values.lastName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="text" 
                                                    className={`form-control ${
                                                        errors.lastName && touched.lastName
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.lastName && touched.lastName && (
                                            <div className="input-feedback">{errors.lastName}</div>
                                        )}
                                        <div class="row">
                                            <div class="col-2">
                                                <label for="username" class="col-form-label">
                                                    Prenom
                                                </label>
                                            </div>
                                            <div className="col-10">
                                                <input 
                                                    id="firstName"
                                                    name="firstName"
                                                    value={values.firstName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="text" 
                                                    className={`form-control ${
                                                        errors.firstName && touched.firstName
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                />
                                            </div>
                                                
                                        </div>
                                        {errors.firstName && touched.firstName && (
                                            <div className="input-feedback">{errors.firstName}</div>
                                        )}
                                        
                                        <div class="row mt-2 mb-2">
                                            <div className="col-2">
                                                <label for="type" class="col-form-label">Type de client</label>
                                            </div>
                                            <div className="col-10 mx-auto">
                                                <select 
                                                    id="type"
                                                    name="type"
                                                    value={values?.type}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.type && touched.type
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }`}
                                                    class="form-select" 
                                                    aria-label="Default select example">
                                                    <option value="CLASSIC">CLASSIQUE</option>
                                                    <option value="VIP">VIP</option>
                                                    <option value="BANNED">BANNI</option>
                                                </select>
                                            </div>      
                                        </div>
                                        {errors.type && touched.type && (
                                            <div className="input-feedback">{errors.type}</div>
                                        )}
                                        {values.type === 'VIP' && (
                                            <div class="row mb-2">
                                                <div className="col-2">
                                                    <label htmlFor="preferredPrice" className="col-form-label">
                                                        Prix preferentiel
                                                    </label>
                                                </div>
                                                <div className="col-10">
                                                    <input 
                                                        id="preferredPrice" 
                                                        name="preferredPrice"
                                                        value={values.preferredPrice}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        type="number" 
                                                        className={`form-control ${
                                                            errors.preferredPrice && touched.preferredPrice
                                                            ? "text-input error"
                                                            : "text-input"
                                                        }`}
                                                    />
                                                </div>
                                            </div>

                                        )}
                                        {errors.preferredPrice && touched.preferredPrice && (
                                            <div className="input-feedback">{errors.preferredPrice}</div>
                                        )} 

                                        <div class="row">
                                            <div className="col-2">
                                                <label for="gender" class="col-form-label">Sexe</label>
                                            </div>
                                            <div className="col-10 mx-auto">
                                                <select 
                                                    id="gender"
                                                    name="gender"
                                                    onChange={handleChange}
                                                    value={values?.gender}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.gender && touched.gender
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }`}
                                                    class="form-select" 
                                                    aria-label="Default select example"
                                                >
                                                    <option value="male">Masculin</option>
                                                    <option value="female">Feminin</option>
                                                </select>
                                            </div>
                                        </div>
                                        {errors.gender && touched.gender && (
                                            <div className="input-feedback">{errors.gender}</div>
                                        )}
                                        {values.gender === 'female' && (
                                            <div class="row">
                                                <div className="col-2">
                                                    <label 
                                                        htmlFor="maidenName" 
                                                        class="col-form-label"
                                                    >
                                                        Nom de jeune Fille:
                                                    </label>
                                                </div>
                                                <div className="col-10 mt-2 mb-2">
                                                    <input 
                                                        value={values.maidenName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        type="text" 
                                                        className={`form-control ${
                                                            errors.maidenName && touched.maidenName
                                                                ? "text-input error"
                                                                : "text-input"
                                                        }`} 
                                                        id="maidenName" 
                                                        name="maidenName"
                                                    /> 
                                                </div>
                                            </div>
                                        )}
                                        <div class="row mb-2">
                                            <div className="col-2">
                                                <label for="birthDate" class="col-form-label">Date de naissance</label>
                                            </div>
                                            <div className="col-10">
                                                <DatePicker
                                                    selected={values.birthDate}
                                                    // onChange={(birthDate) => {
                                                    //     const formattedDate = format(birthDate, 'dd-MM-yyyy', { locale: fr });

                                                    //     console.log("voir ma date formaté ===>> ", formattedDate)
                                                    //     setFieldValue('birthDate', formattedDate);
                                                    //   }}
                                                    onChange={(birthDate) => setFieldValue('birthDate', birthDate)}
                                                    onBlur={handleBlur}
                                                    dateFormat={['dd-MM-yyyy', 'dd/MM/yyyy']}
                                                    placeholderText="JJ-MM-AAAA ou JJ/MM/AAAA"
                                                    open={false}
                                                    //locale="fr"
                                                    showYearDropdown
                                                    // minDate={new Date(new Date().getFullYear() - 40, 0, 1)}
                                                    // maxDate={new Date(new Date().getFullYear(), 11, 31)}
                                                    // yearDropdownItemNumber={40}
                                                    //showTimeSelect
                                                    //dateFormat="Pp"
                                                    className={`form-control dateWith ${
                                                        errors.birthDate && touched.birthDate ? "text-input error" : "text-input"
                                                    }`}
                                                />
                                                {/* <input 
                                                    id="birthDate" 
                                                    name="birthDate"
                                                    value={values.}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="text" 
                                                    className={`form-control ${
                                                        errors.lastName && touched.lastName
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                /> */}
                                            </div>
                                        </div>
                                        {/* <div class="form-group">
                                            <label 
                                                htmlFor="birthdate" 
                                                class="col-form-label"
                                            >
                                                Anniversaire:
                                            </label>
                                            <input 
                                                value={values.}
                                                type="date" 
                                                class="form-control" 
                                                id="birthdate" 
                                                name="birthdate" 
                                            />
                                        </div> */}
                                        <div class="row">
                                            <div className="col-2">
                                                <label 
                                                    htmlFor="profession" 
                                                    class="col-form-label"
                                                >
                                                    Profession:
                                                </label>
                                            </div>
                                            <div className="col-10 mt-2 mb-2">
                                                <input 
                                                    value={values?.profession}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="text" 
                                                    className={`form-control ${
                                                        errors.profession && touched.profession
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }`} 
                                                    id="profession" 
                                                    name="profession"
                                                /> 
                                            </div>
                                        </div>
                                        {errors.profession && touched.profession && (
                                            <div className="input-feedback">{errors.profession}</div>
                                        )}
                                        <div class="row mt-2 mb-2">
                                            <div className="col-2">
                                                <label 
                                                    htmlFor="country" 
                                                    class="col-form-label"
                                                >
                                                    Pays:
                                                </label>
                                            </div>
                                            <div className="col-10">
                                                <input 
                                                    id="country" 
                                                    name="country" 
                                                    onChange={handleChange}
                                                    value={values?.country}
                                                    onBlur={handleBlur}
                                                    type="text" 
                                                    className={`form-control ${
                                                        errors.country && touched.country
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`} 
                                                />
                                            </div>
                                        </div>
                                        {errors.country && touched.country && (
                                            <div className="input-feedback">{errors.country}</div>
                                        )}
                                        <div class="row">
                                            <div className="col-2">
                                                <label for="idCountry" class="col-form-label">
                                                    Identité du pays
                                                </label>
                                            </div>
                                            <div className="col-10 mx-auto">
                                                <select 
                                                    id="countryIdentityType"
                                                    name="countryIdentityType"
                                                    value={values?.countryIdentityType}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.countryIdentityType && touched.countryIdentityType
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }`}
                                                    class="form-select" 
                                                    aria-label="Default select example"
                                                >
                                                    <option value="DRIVER_CARD">PERMIS DE CONDUIRE</option>
                                                    <option value="PASSPORT">PASSEPORT</option>
                                                    <option value="ID_CARD">CARTE D'IDENTITÉ</option>
                                                </select>
                                            </div>
                                            
                                        </div>
                                        {errors.countryIdentityType && touched.countryIdentityType && (
                                            <div className="input-feedback">{errors.countryIdentityType}</div>
                                        )}
                                        <div class="row">
                                            <div className="col-3">
                                                <label 
                                                    htmlFor="countryIdentityNumber" 
                                                    className="col-form-label"
                                                >
                                                    Numéro Identité
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <input 
                                                    onChange={handleChange}
                                                    value={values?.countryIdentityNumber}
                                                    onBlur={handleBlur}
                                                    id="countryIdentityNumber" 
                                                    name="countryIdentityNumber" 
                                                    type="text" 
                                                    className="form-control"    
                                                />
                                            </div>
                                        </div>
                                        {errors.countryIdentityNumber && touched.countryIdentityNumber && (
                                            <div className="input-feedback">{errors.countryIdentityNumber}</div>
                                        )}
                                        <div class="row">
                                            <div className="col-2">
                                                <label 
                                                    htmlFor="countryIdentityDeliveryDate" 
                                                    class="col-form-label"
                                                >
                                                    Date de délivrance *:
                                                </label>
                                            </div>
                                            <div className="col-10 mt-2 mb-2">
                                                <DatePicker
                                                    selected={values.countryIdentityDeliveryDate}
                                                    onChange={(countryIdentityDeliveryDate) => setFieldValue('countryIdentityDeliveryDate', countryIdentityDeliveryDate)}
                                                    onBlur={handleBlur}
                                                    dateFormat={['dd-MM-yyyy', 'dd/MM/yyyy']}
                                                    placeholderText="JJ-MM-AAAA ou JJ/MM/AAAA"
                                                    open={false}
                                                    //locale="fr"
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    //minDate={new Date(new Date().getFullYear() - 40, 0, 1)}
                                                    //maxDate={new Date(new Date().getFullYear(), 11, 31)}
                                                    //yearDropdownItemNumber={40} Nombre d'années à afficher dans le menu déroulant
                                                    className={`form-control dateWith ${
                                                        errors.countryIdentityDeliveryDate && touched.countryIdentityDeliveryDate ? 'text-input error' : 'text-input'
                                                    }`}
                                                />
                                                {/* <DatePicker
                                                    selected={values.countryIdentityDeliveryDate}
                                                    onChange={(date) => setFieldValue('countryIdentityDeliveryDate', date)}
                                                    onBlur={handleBlur}
                                                    //showTimeSelect
                                                    dateFormat="dd MMMM yyyy"
                                                    locale="fr"
                                                    className={`form-control dateWith ${
                                                        errors.countryIdentityDeliveryDate && touched.countryIdentityDeliveryDate ? "text-input error" : "text-input"
                                                    }`}
                                                /> */}
                                                {/* <DatePicker
                                                    selected={values?.countryIdentityDeliveryDate}
                                                    onChange={handleChange}
                                                    dateFormat="dd MMMM yyyy"
                                                    showTimeInput
                                                    locale="fr"
                                                    placeholderText="Sélectionnez une date"
                                                    className="form-control"
                                                /> */}
                                                {/* <input 
                                                    value={values.countryIdentityDeliveryDate}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="date" 
                                                    className={`form-control ${
                                                        errors.countryIdentityDeliveryDate && touched.countryIdentityDeliveryDate
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }`} 
                                                    id="countryIdentityDeliveryDate" 
                                                    name="countryIdentityDeliveryDate"
                                                />  */}
                                            </div>
                                        </div>
                                        {errors.countryIdentityDeliveryDate && touched.countryIdentityDeliveryDate && (
                                            <div className="input-feedback">{errors.countryIdentityDeliveryDate}</div>
                                        )}
                                        <div class="row">
                                            <div className="col-2">
                                                <label 
                                                    htmlFor="countryIdentityDeliveryPlace" 
                                                    class="col-form-label"
                                                >
                                                    Lieu de délivrance *:
                                                </label>
                                            </div>
                                            <div className="col-10 mt-2 mb-2">
                                                <input 
                                                    value={values.countryIdentityDeliveryPlace}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="text" 
                                                    className={`form-control ${
                                                        errors.countryIdentityDeliveryPlace && touched.countryIdentityDeliveryPlace
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }`} 
                                                    id="countryIdentityDeliveryPlace" 
                                                    name="countryIdentityDeliveryPlace"
                                                /> 
                                            </div>
                                        </div>
                                        {errors.countryIdentityDeliveryPlace && touched.countryIdentityDeliveryPlace && (
                                            <div className="input-feedback">{errors.countryIdentityDeliveryPlace}</div>
                                        )}
                                        <div class="row">
                                            <div className="col-2">
                                                <label 
                                                    htmlFor="city" 
                                                    className="col-form-label"
                                                >
                                                    Ville:
                                                </label>
                                            </div>
                                            <div className="col-10">
                                                <input 
                                                    id="city" 
                                                    name="city" 
                                                    value={values?.city}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="text" 
                                                    className={`form-control ${
                                                        errors.city && touched.city
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        {errors.city && touched.city && (
                                            <div className="input-feedback">{errors.city}</div>
                                        )}
                                        <div class="row">
                                            <div className="col-2">
                                                <label for="region" class="col-form-label">Region</label>
                                            </div>
                                            <div className="col-10 mx-auto">
                                                <input 
                                                    id="region" 
                                                    name="region" 
                                                    value={values?.region}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="text" 
                                                    className={`form-control ${
                                                        errors.region && touched.region
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }`}
                                                />
                                            </div>
                                            
                                        </div>
                                        {errors.region  && touched.region && (
                                            <div className="input-feedback">{errors.region}</div>
                                        )}
                                        <div class="row">
                                            <div className="col-2">
                                                <label 
                                                    htmlFor="street" 
                                                    class="col-form-label"
                                                >
                                                    Quartier:
                                                </label>
                                            </div>
                                            <div className="col-10">
                                                <input 
                                                    id="street" 
                                                    name="street"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} 
                                                    value={values?.street}
                                                    type="text" 
                                                    class={`form-control ${
                                                        errors.street && touched.street
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`} 
                                                />
                                            </div>
                                        </div>
                                        {errors.street  && touched.street && (
                                            <div className="input-feedback">{errors.street}</div>
                                        )}
                                        <div class="row">
                                            <div className="col-2">
                                                <label 
                                                    htmlFor="address" 
                                                    className="col-form-label"
                                                >
                                                    Adresse:
                                                </label>
                                            </div>
                                            <div className="col-10">
                                                <input 
                                                    id="address"
                                                    name="address"
                                                    value={values?.address}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    class={`form-control ${
                                                        errors.address && touched.address
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`} 
                                                />
                                            </div>
                                        </div>
                                        {errors.address  && touched.address && (
                                            <div className="input-feedback">{errors.address}</div>
                                        )}
                                        {/* <div class="form-group">
                                            <label for="recipient-name" class="col-form-label">Numéro d'identité du pays:</label>
                                            <input type="text" class="form-control" id="recipient-name" />
                                        </div> */}
                                        <div class="row">
                                            <div className="col-2">
                                                <label 
                                                    htmlFor="phoneNumber" 
                                                    class="col-form-label"
                                                >
                                                    Telephone:
                                                </label>
                                            </div>
                                            <div className="col-10">
                                                <input 
                                                    id="phoneNumber" 
                                                    name="phoneNumber" 
                                                    value={values?.phoneNumber}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="text" 
                                                    class={`form-control ${
                                                        errors.phoneNumber && touched.phoneNumber
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`} 
                                                />
                                            </div>
                                        </div>
                                        {errors.phoneNumber  && touched.phoneNumber && (
                                            <div className="input-feedback">{errors.phoneNumber}</div>
                                        )}
                                        <div class="row">
                                            <div className="col-2">
                                                <label 
                                                    htmlFor="description" 
                                                    class="col-form-label"
                                                >
                                                    Description:
                                                </label>
                                            </div>
                                            <div className="col-10">
                                                <textarea
                                                    type="text"
                                                    id="description"
                                                    name="description"
                                                    value={values?.description}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${
                                                        errors.description && touched.description
                                                        ? "text-input error"
                                                        : "text-input"
                                                    }`}
                                                >

                                                </textarea>
                                            </div>
                                            {/* <input type="text" class="form-control" id="recipient-name" /> */}
                                        </div>
                                        {errors.description  && touched.description && (
                                            <div className="input-feedback">{errors.description}</div>
                                        )}
                                        {/* <div class="form-group">
                                            <label for="recipient-name" class="col-form-label">Login:</label>
                                            <input type="text" class="form-control" id="recipient-name" />
                                        </div>
                                        <div class="form-group">
                                            <label for="recipient-name" class="col-form-label">Password:</label>
                                            <input type="text" class="form-control" id="recipient-name" />
                                        </div> */}
                                        
                                    {/* <div class="form-group">
                                        <label for="message-text" class="col-form-label">Message:</label>
                                        <textarea class="form-control" id="message-text"></textarea>
                                    </div> */}
                                    <div class="modal-footer">
                                        <button 
                                            type="button" 
                                            class="btn btn-secondary" 
                                            ref={closeModalToUpdate}
                                            data-bs-dismiss="modal"
                                        >
                                            Fermer
                                        </button>
                                        <button 
                                            type="submit" 
                                            class="btn btn-primary"
                                        >
                                           Modifier
                                        </button>
                                    </div>    
                                </form>
                                );
                            }}
                        </Formik>
                        </div>
                        {/* <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Send message</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Customer;
