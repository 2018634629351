import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, PDFDownloadLink, pdf} from '@react-pdf/renderer';
import moment from 'moment';
import 'moment/locale/fr';

//Styles for the PDF document
const styles = StyleSheet.create({
    // page: {
    //     padding: 30,
    //     fontFamily: 'Helvetica'
    // },
    logo: {
        width: 100,
        height: 90,
        alignSelf: 'center',
        marginBottom: 10,
    },
    info: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 2,

    },
    page: {
        flexDirection: 'column',
        padding: 30
    },
    // section: {
    //     marginBottom: 20,
    //     flexDirection: 'row',
    //     alignItems: 'center'
    // },
    field: {
        fontSize: 14,
        marginVertical: 5,
        marginTop: 5
    },
    titleFooter: {
        fontSize: 17,
        textAlign: 'center',
        marginBottom: 10
    },
    tableContainer: {
        marginLeft: 7,
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "14.28%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 10
    }
})

//moment.locale('fr');

// const formatDate = (dateString) => {
//     return moment(dateString, 'DD-MM-YYYY HH').format('D MMMM YYYY [à] HH[h]');
// };

const formatDate = (dateString) => {
    moment.locale('fr');
    return moment(dateString).format("D MMMM YYYY à HH[h]");
  };

  const formatDateWihoutHour = (dateString) => {
    moment.locale('fr');
    return moment(dateString).format("D MMMM YYYY");
  };

//console.log('Voir la date formaté ==>', formatDate("2024-07-28T17:00:00.000Z"))
export const PoliceFiche = ({ reservation }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            {/* <Text style={styles.title}>{process.env.REACT_APP_HOTEL_NAME}</Text> */}
            <View style={{marginBottom: "18px"}}>
                <Image style={styles.logo} src="/images.png"/>
                <Text style={styles.info}>Tel: (+237) {process.env.REACT_APP_PHONE}</Text>
                <Text style={styles.info}>Email: {process.env.REACT_APP_EMAIL}</Text>
                <Text style={styles.info}>{process.env.REACT_APP_CITY}</Text>
            </View>
            
            
            <View style={{marginBottom: "18px"}}>
                <Text style={styles.field} >Nom _____{reservation?.customer?.lastName}</Text>
                <Text style={styles.field}>Nom de jeune fille___{reservation?.customer.maidenName}____ </Text>
                <Text style={styles.field}>Prénoms________________ {reservation?.customer.firstName______}</Text>
                <Text style={styles.field}>Date de naissance:_____{formatDateWihoutHour(reservation?.customer.birthDate)}_____</Text>
                <Text style={styles.field}>A______________________{reservation?.customer?.birthDatePlace}_____</Text>
                <Text style={styles.field}>Nationnalité___________</Text>
                <Text style={styles.field}>Pays de résidence____</Text>
                <Text style={styles.field}>Adresse______________{reservation?.customer.address}______</Text>
                <Text style={styles.field}>Profession___________{reservation?.customer.profession}_____</Text>
                <Text style={styles.field}>Venant de ____{reservation?.customerComingFrom}______</Text>
                <Text style={styles.field}>Se rendant a ____{reservation?.customerGoingTo}_______</Text>
                <Text style={styles.field}>Nombre de personne_______M:______ F:______</Text>
                    {/* <View style={styles.tableContainer}>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>M</Text> 
                                </View>
                                <View style={styles.tableCol}>
                                <Text style={styles.tableCell}></Text> 
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>F</Text> 
                                </View>
                                <View style={styles.tableCol}>
                                <Text style={styles.tableCell}></Text> 
                                </View>
                            </View>
                        </View>
                    </View> */}
                <Text style={styles.field}>Passport ou carte d'identité ____{reservation?.customer.countryIdentityType == "ID_CARD" ? "CARTE D'IDENTITE" : reservation?.customer.countryIdentityType == "PASSPORT" ? "PASSEPORT" : "PERMIS DE CONDUIRE"}</Text>
                <Text style={styles.field}>Délivré le __________{formatDateWihoutHour(reservation?.customer.countryIdentityDeliveryDate)}______A _______{reservation?.customer.countryIdentityDeliveryPlace}________</Text>
                <Text style={styles.field}>Mode de Transport .________________________</Text>
            </View>
            <View>
                <View style={{marginBottom: "10px"}}>
                    <Text style={styles.info}>Date de départ ___________{formatDate(reservation?.endAt)}______________</Text>
                </View>
                <Text style={styles.info}>Signature du passager _________________</Text>
                <Text style={styles.titleFooter}>A remplir par le receptionnaire</Text>
            </View>
            
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Chambre N</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Date D'arrivée</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Date de départ</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Nuités</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Arrange (RBHBFH)</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Prix</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Receptionnaire</Text>
                    </View>
                </View>

                <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{reservation?.room.number}.</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{formatDate(reservation.startAt)}</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{formatDate(reservation.endAt)}</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{reservation.numberOfDays}</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}></Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{reservation.actualPrice}</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{reservation?.user.lastName}</Text>
                    </View>
                </View>
            </View>
        </Page>
    </Document>
)